<script setup lang="ts">
import { OdsColorScheme, type OdsColorSchemeType } from '@onliz/design-system-vue';

import type { ImageElementProps } from '../ImageElement.vue';

type SectionTextDuoProps = {
  title?: string;
  image?: ImageElementProps;
  scheme?: OdsColorSchemeType;
};

withDefaults(defineProps<SectionTextDuoProps>(), {
  title: undefined,
  image: undefined,
  scheme: 'dark',
});

const slots = useSlots();

const isDuo = computed<boolean>(() => {
  return slots.left !== undefined && slots.right !== undefined;
});
</script>

<template>
  <OdsColorScheme :scheme class="section-two-columns-text">
    <div class="section-two-columns-text__inner">
      <div v-if="$slots.title || title" class="section-two-columns-text__title">
        <slot name="title">{{ title }}</slot>
      </div>

      <div class="section-two-columns-text__content" :class="{ 'section-two-columns-text__content--duo': isDuo }">
        <slot name="left"></slot>

        <span v-if="isDuo" class="section-two-columns-text__separator"></span>

        <slot name="right"></slot>
      </div>
    </div>

    <div v-if="$slots.image || image" class="section-two-columns-text__image">
      <slot name="image">
        <ImageElement v-if="image" v-bind="image" />
      </slot>
    </div>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

$section-two-columns-text-inner-max-width: 800px;
$section-two-columns-text-breakpoint-sm: 480px;
$section-two-columns-text-breakpoint-md: 800px;
$section-two-columns-text-breakpoint-lg: 1200px;

.section-two-columns-text {
  --section-two-columns-text-inner-spacing: var(--spacing--m);

  background-image: url('~/assets/images/brand-mark.svg');
  background-size: 80%;
  background-position: center var(--section-two-columns-text-inner-spacing);
  background-repeat: no-repeat;
  border-radius: var(--radius--l);
  display: grid;
  grid-template-areas: 'layer';
  place-items: start center;
  overflow: hidden;

  @media screen and (min-width: $section-two-columns-text-breakpoint-sm) {
    align-items: center;
    background-position: center center;
  }

  @media screen and (min-width: $section-two-columns-text-breakpoint-md) {
    --section-two-columns-text-inner-spacing: var(--spacing--l);
  }

  &__inner {
    margin: 0 auto;
    max-width: $section-two-columns-text-inner-max-width;
    grid-area: layer;
    z-index: 1;
    padding: var(--section-two-columns-text-inner-spacing);
  }

  &__title {
    @include ods.h3;

    text-align: center;

    & + * {
      margin: var(--section-two-columns-text-inner-spacing) 0 0 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $section-two-columns-text-breakpoint-sm) {
      flex-direction: row;
    }

    &--duo {
      gap: var(--section-two-columns-text-inner-spacing);
    }

    & > * {
      flex: 1;
      text-align: center;

      @media screen and (min-width: $section-two-columns-text-breakpoint-sm) {
        &:first-child {
          text-align: right;
        }

        &:last-child {
          text-align: left;
        }
      }
    }
  }

  &__separator {
    height: 1px;
    flex: 0 0 1px;
    background-color: currentcolor;

    @media screen and (min-width: $section-two-columns-text-breakpoint-sm) {
      height: auto;
      width: 1px;
    }
  }

  &__image {
    width: ods.px-to-em(225px);

    img {
      display: block;
      width: 100%;
    }

    @media screen and (min-width: $section-two-columns-text-breakpoint-lg) {
      grid-area: layer;
      place-self: end end;
    }
  }
}
</style>
