import {
  createStorage as _createStorage,
  type CreateStorageOptions,
  type Storage,
  type StorageValue,
  type TransactionOptions,
} from 'unstorage';

import type { MaybePromise } from '~/lib/type-utils';

export interface AugmentedStorage<T extends StorageValue = StorageValue>
  extends Omit<Storage<T>, 'getItem' | 'getItemRaw'> {
  getItemOrSet: <U extends T>(
    key: string,
    callback: () => MaybePromise<U>,
    setOptions?: TransactionOptions,
    getOptions?: TransactionOptions,
  ) => Promise<U>;
  // Override getItem() and getItemRaw() output (replace null with undefined)
  getItem: <U extends T>(key: string, options?: TransactionOptions) => Promise<U | undefined>;
  getItemRaw: <U extends T>(key: string, options?: TransactionOptions) => Promise<U | undefined>;
}

export function createStorage<T extends StorageValue>(options: CreateStorageOptions = {}): AugmentedStorage<T> {
  const storage = _createStorage(options);

  return {
    ...storage,
    async getItem<U extends T>(...args: Parameters<(typeof storage)['getItem']>): Promise<U | undefined> {
      return (await storage.getItem<U>(...args)) ?? undefined;
    },
    async getItemRaw<U extends T>(...args: Parameters<(typeof storage)['getItemRaw']>) {
      return (await storage.getItemRaw<U>(...args)) ?? undefined;
    },
    async getItemOrSet<U extends T>(
      key: string,
      callback: () => MaybePromise<U>,
      setOptions?: TransactionOptions,
      getOptions?: TransactionOptions,
    ): Promise<U> {
      let value: U | undefined = (await this.getItem<U>(key, getOptions)) ?? undefined;

      if (value === undefined) {
        value = await callback();

        await this.setItem<U>(key, value, setOptions);
      }

      return value;
    },
  };
}

export const MS_1_SEC = 1000;
export const CACHE_TTL_S_1_MIN = 60;
export const CACHE_TTL_S_15_MIN = 15 * CACHE_TTL_S_1_MIN;
export const CACHE_TTL_S_1_HOUR = 60 * CACHE_TTL_S_1_MIN;
export const CACHE_TTL_S_12_HOURS = 12 * CACHE_TTL_S_1_MIN;

export const CACHE_TTL_MS_15_MIN = CACHE_TTL_S_15_MIN * MS_1_SEC;
export const CACHE_TTL_MS_1_HOUR = CACHE_TTL_S_1_HOUR * MS_1_SEC;
export const CACHE_TTL_MS_12_HOURS = CACHE_TTL_S_1_HOUR * MS_1_SEC;

export class CacheError extends Error {}

export class CacheKeyError extends CacheError {}

export class AssembleCacheKeyError extends CacheKeyError {}

export class InvalidCacheKeyError extends CacheKeyError {}

export class CacheKeyPartError extends CacheKeyError {}

export class InvalidTtlError extends CacheError {}

export const CACHE_KEY_SEPARATOR = ':';

export function isValidCacheKey(input: string): boolean {
  return input.split(CACHE_KEY_SEPARATOR).every((part) => part.trim().length > 0);
}

export function assertValidCacheKey(input: string): void {
  if (!isValidCacheKey(input)) {
    throw new InvalidCacheKeyError(`Invalid cache key (${JSON.stringify(input)})`);
  }
}

export function isValidTtl(ttl: number): boolean {
  return Number.isInteger(ttl) && ttl >= 0;
}

export function assertValidTtl(ttl: number): void {
  if (!isValidTtl(ttl)) {
    throw new InvalidTtlError(`Invalid TTL (${JSON.stringify(ttl)})`);
  }
}

export function assertValidCacheKeyPart(input: string) {
  if (input.trim().length === 0) {
    throw new CacheKeyPartError(`Empty cache key part (${JSON.stringify(input)})`);
  }

  if (input.includes(CACHE_KEY_SEPARATOR)) {
    throw new CacheKeyPartError(
      `Invalid cache key part (${JSON.stringify(input)}). It cannot contain the cache key separator "${CACHE_KEY_SEPARATOR}"`,
    );
  }
}

export function isValidCacheKeyPart(input: string): boolean {
  try {
    assertValidCacheKeyPart(input);

    return true;
  } catch (error) {
    if (error instanceof CacheKeyPartError) {
      return false;
    }

    throw error;
  }
}

export function assembleCacheKey(...parts: string[]): string {
  if (parts.length === 0) {
    throw new AssembleCacheKeyError('No parts to assemble');
  }

  try {
    for (const part of parts) {
      assertValidCacheKeyPart(part);
    }
  } catch (error) {
    if (error instanceof CacheKeyPartError) {
      throw new AssembleCacheKeyError(
        `Failed to assemble cache key. One of the provided parts is invalid (${JSON.stringify(parts)})`,
        { cause: error },
      );
    }

    throw error;
  }

  return parts.join(CACHE_KEY_SEPARATOR);
}

export function getProductTag(productId: string) {
  return assembleCacheKey('product', productId);
}

export function getProductsTag(productIds: string[]) {
  return productIds.map((productId) => assembleCacheKey('product', productId));
}

export function getPageTag(pageName: string) {
  return assembleCacheKey('page', pageName);
}

export const HOMEPAGE_PAGE_TAG = getPageTag('homepage');
export const NOT_FOUND_PAGE_TAG = getPageTag('not-found');
export const NAVIGATION_TAG = 'navigation';
export const GLOBAL_TAG = 'global';
