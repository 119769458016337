import { ApiCatalogError, getCatalogBaseUrl } from '~/utils/api/catalog';
import {
  assertValidProductId,
  type CatalogSearchResult,
  mapSearchResultToProduct,
  type Product,
  type ProductId,
} from '~/utils/api/catalog/products/utils';

export class ApiCatalogProductError extends ApiCatalogError {}

export interface CatalogSearchRequestPayload {
  type: 'PRODUCT';
  facetFilters: [string[]];
  page: number;
  pageSize: number;
}

interface CatalogSearchFacet {
  label: string;
  code: string;
  values: Record<
    string,
    | {
        count: number;
        code: string;
        label: string;
      }
    | {
        count: number;
        value: string;
        label: string;
      }
  >;
  isNumeric?: boolean;
}

export interface CatalogSearchResponse {
  results: CatalogSearchResult[];
  facets: CatalogSearchFacet[];
  pagination: {
    count: number;
    nbPages: number;
    page: number;
    perPage: number;
  };
}

export async function findProductById(...ids: ProductId[]): Promise<Product[]> {
  try {
    for (const id of ids) {
      assertValidProductId(id);
    }

    const payload: CatalogSearchRequestPayload = {
      type: 'PRODUCT',
      facetFilters: [ids.map((id) => `id:${id}`)],
      page: 1,
      pageSize: ids.length,
    };

    const { results } = await $fetch<CatalogSearchResponse>('/catalog-search', {
      method: 'POST',
      baseURL: getCatalogBaseUrl(),
      body: payload,
    });

    return results.map((result) => mapSearchResultToProduct(result));
  } catch (error) {
    throw new ApiCatalogProductError('Failed to get products', { cause: error });
  }
}
