<script setup lang="ts">
import type { LogoListProps } from '~/components/LogoList.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockBrandListFragment } from '~/types/graphql';

type CmsBlockBrandListProps = {
  data: BlockBrandListFragment;
};

const props = defineProps<CmsBlockBrandListProps>();

type BrandList = NonNullable<LogoListProps['logos']>;

const { getAssetPath } = useCmsAsset();

const brands = computed<BrandList>(() => {
  if (props.data.brands?.data.length) {
    return props.data.brands.data.reduce<BrandList>((acc, brand) => {
      if (isNotEmpty(brand.attributes) && brand.attributes.logo) {
        const source = getAssetPath(brand.attributes.logo);

        if (source) {
          acc.push({
            title: brand.attributes?.name,
            alt: brand.attributes?.name,
            to: brand.attributes?.slug,
            src: source,
          });
        }
      }

      return acc;
    }, []);
  }

  return [];
});
</script>

<template>
  <LayoutSection class="cms-block-brand-list" scheme="tertiary">
    <SectionSimpleLayout layout="1of2-1of2">
      <template v-if="data.title" #title>
        <CmsFieldTitle class="cms-block-brand-list__title" :data="data.title" />
      </template>

      <template #default>
        <LogoList v-if="brands.length > 0" :logos="brands" />
      </template>
    </SectionSimpleLayout>
  </LayoutSection>
</template>

<style lang="scss">
.cms-block-brand-list {
  &__title {
    max-width: 500px;
  }
}
</style>
