<script setup lang="ts">
import { isIconName, type OdsIconName } from '@onliz/design-system-vue';

import { ImageSizeMap } from '#imports';
import type { FieldCardFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldCardFragment;
}>();

const iconName = computed<OdsIconName | undefined>(() => {
  if (props.data.icon && isIconName(props.data.icon)) {
    return props.data.icon;
  }
});
</script>

<template>
  <CardListItem v-bind="{ iconName }" class="card-list-item">
    <template v-if="data.image?.data?.attributes?.hash" #image>
      <CmsFieldImage
        :data="data.image"
        title-class-name="card-list-item__image"
        :width="ImageSizeMap.cardImage.width"
        :height="ImageSizeMap.cardImage.height"
      />
    </template>

    <template #default>
      <CmsFieldParagraph v-if="data.paragraph" title-class-name="card-list-item__title" :data="data.paragraph" />
    </template>

    <template v-if="data.link?.label" #cta>
      <CmsFieldLink :data="data.link" variant="outlined" />
    </template>
  </CardListItem>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.card-list-item {
  &,
  &__inner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
  }

  &__image {
    border-radius: 100%;
    overflow: hidden;
  }

  &__title {
    @include ods.h5;

    margin: 0 0 var(--spacing--xs) 0;
    display: block;
  }
}
</style>
