<script lang="ts" setup>
import { type OdsColorSchemeType, OdsSwitch } from '@onliz/design-system-vue';

import type { BlockComparisonTableFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockComparisonTableFragment;
}>();

const switchValue = ref(true);

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'primary';
});

const switchLeft = () => {
  switchValue.value = false;
};

const switchRight = () => {
  switchValue.value = true;
};
</script>

<template>
  <LayoutSection>
    <div class="cms-block-comparison-table">
      <SectionCardBoard class="cms-block-comparison-table__card" :scheme>
        <template v-if="data.mainTitle" #title>
          <CmsFieldTitle v-if="data.mainTitle" class="cms-block-comparison-table__title" :data="data.mainTitle" />
        </template>

        <template v-if="data.description" #description>
          <div class="cms-block-comparison-table__description">
            {{ data.description }}
          </div>

          <div class="cms-block-comparison-table__toggle">
            <button
              type="button"
              class="cms-block-comparison-table__label"
              :class="{ 'cms-block-comparison-table__label--active': !switchValue }"
              @click="switchLeft"
            >
              {{ data.leftLabel }}
            </button>

            <OdsSwitch v-model="switchValue" />

            <button
              type="button"
              class="cms-block-comparison-table__label"
              :class="{ 'cms-block-comparison-table__label--active': switchValue }"
              @click="switchRight"
            >
              {{ data.rightLabel }}
            </button>
          </div>
        </template>

        <template #default>
          <div class="cms-block-comparison-table__content">
            <TransitionSlideUp>
              <CmsFieldCardList v-if="switchValue" class="cms-block-comparison-table__item" :data="data.rightContent" />

              <CmsFieldCardList v-else class="cms-block-comparison-table__item" :data="data.leftContent" />
            </TransitionSlideUp>
          </div>
        </template>
      </SectionCardBoard>

      <CmsFieldImageBanner
        v-if="data.banner"
        class="cms-block-comparison-table__banner"
        :data="data.banner"
        :width="360"
        :height="480"
      />
    </div>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-comparison-table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--m);

  @media screen and (min-width: ods.$breakpoint-xl) {
    flex-direction: row;
  }

  &__banner {
    @media screen and (min-width: ods.$breakpoint-xl) {
      width: 360px;
    }
  }

  &__card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__content {
    justify-self: center;
    flex: 1;
    width: 100%;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing--s);
    padding: var(--spacing--m) 0;
  }

  &__label {
    font-size: ods.px-to-em(18px);
    color: var(--default--color-disabled);
    cursor: pointer;
    border: none;
    background: none;

    &--active {
      font-weight: 700;
      color: var(--scheme--color-emphasis);
    }
  }
}
</style>
