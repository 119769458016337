<script setup lang="ts">
import { computed } from 'vue';

import type { ImageElementProps } from '~/components/ImageElement.vue';
import useCmsAsset from '~/composables/useCmsAsset';
import type { FieldImageFragment } from '~/types/graphql';
import { scale } from '~/utils/MathUtils';
import { toNumber } from '~/utils/NumberUtils';

const props = defineProps<
  {
    data: FieldImageFragment;
    rounded?: boolean;
  } & Omit<Partial<ImageElementProps>, 'src'>
>();

const boundProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height, data, alt, ...otherProps } = props;

  return otherProps;
});

const originalWidth = computed<number>(() => props.data.data?.attributes?.width ?? 600);
const originalHeight = computed<number>(() => props.data.data?.attributes?.height ?? 400);

const { getAssetPath } = useCmsAsset();

const source = computed<string | undefined>(() => {
  return getAssetPath(props.data);
});

const alternativeText = computed<string>(() => {
  return props.data.data?.attributes?.alternativeText ?? '';
});

const computedWidth = computed<number>(() => {
  if (props.width !== undefined) {
    return toNumber(props.width);
  } else if (props.height !== undefined) {
    return scale(originalWidth.value, originalHeight.value, toNumber(props.height), true);
  }

  return originalWidth.value;
});

const computedHeight = computed<number>(() => {
  if (props.height !== undefined) {
    return toNumber(props.height);
  } else if (props.width !== undefined) {
    return scale(originalHeight.value, originalWidth.value, toNumber(props.width), true);
  }

  return originalHeight.value;
});
</script>

<!-- eslint-disable vue/no-root-v-if -->
<template>
  <ImageElement
    v-if="source"
    class="cms-field-image"
    :class="{ 'cms-field-image--rounded': rounded }"
    :src="source"
    :alt="alternativeText"
    :width="computedWidth"
    :height="computedHeight"
    v-bind="boundProps"
  />
</template>
<!-- eslint-enable vue/no-root-v-if -->

<style lang="scss">
.cms-field-image {
  &--rounded {
    border-radius: var(--radius--l);
  }
}
</style>
