
import * as azureMediaStorageRuntime$GQe9Psc6QN from '/agent/_work/13/s/app/providers/azureMediaStorageProvider.ts'
import * as ipxRuntime$lQwubmlgBg from '/agent/_work/13/s/app/node_modules/.pnpm/@nuxt+image@1.4.0_@azure+identity@4.4.1_ioredis@5.3.2_rollup@4.13.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 560,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1200,
    "xxl": 1344,
    "2xl": 1536,
    "xxxs": 320,
    "xxs": 360,
    "s": 768,
    "m": 800,
    "l": 992,
    "xxxl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['azureMediaStorage']: { provider: azureMediaStorageRuntime$GQe9Psc6QN, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$lQwubmlgBg, defaults: {} }
}
        