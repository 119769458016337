<i18n lang="json" locale="fr-FR">
{
  "rating": "{rating}/{maxRatingCount}"
}
</i18n>

<script lang="ts" setup>
import { OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

withDefaults(
  defineProps<{
    rating?: number;
    minified?: boolean;
  }>(),
  {
    rating: 5,
  },
);

const maxRatingCount = 5;

const { t } = useI18n();
</script>

<template>
  <div v-if="minified" class="review-rating review-rating--minified">
    <OdsIcon class="review-rating__star review-rating__star--plain" name="star" />

    <span class="review-rating__number">{{ t('rating', { rating, maxRatingCount }) }}</span>
  </div>

  <ul v-else class="review-rating review-rating--list">
    <li v-for="i in maxRatingCount" :key="i" class="review-rating__item">
      <OdsIcon class="review-rating__star" :class="{ 'review-rating__star--plain': i <= rating }" name="star" />
    </li>
  </ul>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.review-rating {
  display: flex;
  gap: var(--spacing--xs);
  background: none;

  &__star {
    font-size: ods.px-to-rem(24px);
    fill: var(--scheme--color-emphasis);
    opacity: 0.4;

    &--plain {
      opacity: 1;
    }
  }

  &__number {
    color: var(--scheme--card-highlighted-color);
  }
}
</style>
