import type { OdsIconName } from '@onliz/design-system-vue';

export type ProductId = string;

export const ProductStatusEnum = {
  active: 'status_a',
  moderation: 'status_m',
  hidden: 'status_c',
  disabled: 'status_d',
  refused: 'status_r',
} as const;

export type ProductStatus = (typeof ProductStatusEnum)[keyof typeof ProductStatusEnum];

/**
 * @see See `API-Catalog`
 */
export interface CatalogSearchResult {
  productId: string;
  name: string;
  /** Code EAN */
  code?: string | null | undefined;
  status: ProductStatus;
  state?: string | null | undefined;
  slug: string;
  brand: string;
  supplierName: string;
  companyId: string;
  image: string | null;
  imageRelativePath: string | null;
  price: number | null;
  expressDelivery: boolean;
  deliveryTime: string;
  basePrice?: number | null | undefined;
  review: number;
  categories: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  image_description_1?: string;
}

export const ProductOptionTypeEnum = {
  /* eslint-disable @typescript-eslint/naming-convention*/
  option_type_imaterial: 'imaterial',
  option_type_material: 'material',
  option_type_installation: 'installation',
  option_type_training: 'training',
  option_type_warranty: 'warranty',
  option_type_maintenance: 'maintenance',
  option_type_insurance: 'insurance',
  option_type_commissioning: 'commissioning',
  option_type_supplying: 'supplying',
  option_type_software: 'software',
  /* eslint-enable @typescript-eslint/naming-convention*/
} as const;

export type ProductOptionType = keyof typeof ProductOptionTypeEnum;
export type ProductOptionTypeValue = (typeof ProductOptionTypeEnum)[keyof typeof ProductOptionTypeEnum];

export const productOptionsIcons: Record<ProductOptionTypeValue, OdsIconName> = {
  imaterial: 'check-circle',
  material: 'check-circle',
  installation: 'construction',
  training: 'auto-stories',
  warranty: 'check-shield',
  maintenance: 'construction',
  insurance: 'check-shield',
  commissioning: 'construction',
  supplying: 'package',
  software: 'sync-saved',
} as const;

export const getProductOptionIcon = (type: keyof typeof ProductOptionTypeEnum) => {
  return productOptionsIcons[ProductOptionTypeEnum[type]];
};

export interface ProductImage {
  src: string;
  alt?: string;
}

export const productStateList = ['state_new', 'state_used'] as const;

export type ProductState = (typeof productStateList)[number];

export const productDeliveryTimeList = [
  'delivery_time_2_5',
  'delivery_time_5_10',
  'delivery_time_10_15',
  'delivery_time_15_30',
  'delivery_time_30_60',
  'delivery_time_60',
] as const;

export type ProductDeliveryTime = (typeof productDeliveryTimeList)[number];

export interface Product {
  id: ProductId;
  name: string;
  slug: string;
  brand: string;
  deliveryTime: ProductDeliveryTime;
  supplierName: string;
  image?: ProductImage;
  imageRelativePath?: string;
  price?: number;
  state?: ProductState;
  hidden?: boolean;
  mainCategory?: string;
}

export function mapSearchResultToProduct({
  productId: id,
  name,
  slug,
  brand,
  deliveryTime,
  image: imgSource,
  imageRelativePath,
  supplierName,
  price,
  state,
  image_description_1: imgAlt,
  status,
  categories,
}: CatalogSearchResult): Product {
  return {
    id,
    name,
    slug,
    brand,
    supplierName,
    deliveryTime: deliveryTime as ProductDeliveryTime,
    imageRelativePath: imageRelativePath ?? undefined,
    image: imgSource
      ? {
          src: imgSource,
          alt: imgAlt,
        }
      : undefined,
    price: price ?? undefined,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    state: (state as ProductState) ?? undefined,
    hidden: status === ProductStatusEnum.hidden,
    mainCategory: categories.at(-1),
  };
}

export class InvalidProductIdError extends Error {}

export function isValidProductId(productId: string): boolean {
  return productId.trim() !== '';
}

export function assertValidProductId(productId: string) {
  if (!isValidProductId(productId)) {
    throw new InvalidProductIdError(`The provided product ID is invalid (${JSON.stringify(productId)})`);
  }
}
