export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ContactMainDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  HomepageMainDynamicZoneInput: { input: any; output: any };
  I18NLocaleCode: { input: any; output: any };
  JSON: { input: any; output: any };
  LandingPageMainDynamicZoneInput: { input: any; output: any };
  Page404MainDynamicZoneInput: { input: any; output: any };
  PageMainDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Brand = {
  __typename?: 'Brand';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  logo: UploadFileEntityResponse;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  attributes?: Maybe<Brand>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BrandEntityResponse = {
  __typename?: 'BrandEntityResponse';
  data?: Maybe<BrandEntity>;
};

export type BrandEntityResponseCollection = {
  __typename?: 'BrandEntityResponseCollection';
  data: Array<BrandEntity>;
  meta: ResponseCollectionMeta;
};

export type BrandFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BrandFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BrandFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BrandFiltersInput>>>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BrandInput = {
  logo?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type BrandRelationResponseCollection = {
  __typename?: 'BrandRelationResponseCollection';
  data: Array<BrandEntity>;
};

export type Category = {
  __typename?: 'Category';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  slug: Scalars['String']['output'];
  startFromPrice: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  attributes?: Maybe<Category>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  slug?: InputMaybe<StringFilterInput>;
  startFromPrice?: InputMaybe<FloatFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startFromPrice?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryRelationResponseCollection = {
  __typename?: 'CategoryRelationResponseCollection';
  data: Array<CategoryEntity>;
};

export type ComponentBlock3StepsCard = {
  __typename?: 'ComponentBlock3StepsCard';
  cta?: Maybe<ComponentFieldLink>;
  id: Scalars['ID']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<ComponentFieldStep>>>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlock3StepsCardStepsArgs = {
  filters?: InputMaybe<ComponentFieldStepFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockAccordionList = {
  __typename?: 'ComponentBlockAccordionList';
  id: Scalars['ID']['output'];
  items: Array<Maybe<ComponentFieldAccordion>>;
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockAccordionListItemsArgs = {
  filters?: InputMaybe<ComponentFieldAccordionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockAvisVerifies = {
  __typename?: 'ComponentBlockAvisVerifies';
  count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockBlogPosts = {
  __typename?: 'ComponentBlockBlogPosts';
  cta?: Maybe<ComponentFieldLink>;
  ctaTitle?: Maybe<ComponentFieldTitle>;
  id: Scalars['ID']['output'];
  posts?: Maybe<PostRelationResponseCollection>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockBlogPostsPostsArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockBrandList = {
  __typename?: 'ComponentBlockBrandList';
  brands?: Maybe<BrandRelationResponseCollection>;
  id: Scalars['ID']['output'];
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockBrandListBrandsArgs = {
  filters?: InputMaybe<BrandFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockCardBoard = {
  __typename?: 'ComponentBlockCardBoard';
  cardList?: Maybe<ComponentFieldCardList>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockCategoryList = {
  __typename?: 'ComponentBlockCategoryList';
  categories?: Maybe<CategoryRelationResponseCollection>;
  cta?: Maybe<ComponentFieldLink>;
  id: Scalars['ID']['output'];
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockCategoryListCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockComparisonTable = {
  __typename?: 'ComponentBlockComparisonTable';
  banner?: Maybe<ComponentFieldImageBanner>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  left: ComponentFieldCardList;
  leftLabel: Scalars['String']['output'];
  right: ComponentFieldCardList;
  rightLabel: Scalars['String']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockContactUs = {
  __typename?: 'ComponentBlockContactUs';
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaTitle?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<ComponentFieldTitle>;
  withCTA?: Maybe<Scalars['Boolean']['output']>;
};

export type ComponentBlockCta = {
  __typename?: 'ComponentBlockCta';
  cta: ComponentFieldLink;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockFeaturedProductList = {
  __typename?: 'ComponentBlockFeaturedProductList';
  id: Scalars['ID']['output'];
  products?: Maybe<ProductRelationResponseCollection>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockFeaturedProductListProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockHero = {
  __typename?: 'ComponentBlockHero';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  tags?: Maybe<Array<Maybe<ComponentFieldTag>>>;
  title: ComponentFieldTitle;
};

export type ComponentBlockHeroTagsArgs = {
  filters?: InputMaybe<ComponentFieldTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockLandingHero = {
  __typename?: 'ComponentBlockLandingHero';
  actionBanner?: Maybe<ComponentFieldActionBanner>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  tags?: Maybe<Array<Maybe<ComponentFieldTag>>>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockLandingHeroTagsArgs = {
  filters?: InputMaybe<ComponentFieldTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockLandingHeroFiltersInput = {
  actionBanner?: InputMaybe<ComponentFieldActionBannerFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentBlockLandingHeroFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBlockLandingHeroFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBlockLandingHeroFiltersInput>>>;
  tags?: InputMaybe<ComponentFieldTagFiltersInput>;
  title?: InputMaybe<ComponentFieldTitleFiltersInput>;
};

export type ComponentBlockLandingHeroInput = {
  actionBanner?: InputMaybe<ComponentFieldActionBannerInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<InputMaybe<ComponentFieldTagInput>>>;
  title?: InputMaybe<ComponentFieldTitleInput>;
};

export type ComponentBlockProductList = {
  __typename?: 'ComponentBlockProductList';
  cta?: Maybe<ComponentFieldLink>;
  id: Scalars['ID']['output'];
  products: Scalars['String']['output'];
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockPublications = {
  __typename?: 'ComponentBlockPublications';
  id: Scalars['ID']['output'];
  publications?: Maybe<PressPublicationRelationResponseCollection>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockPublicationsPublicationsArgs = {
  filters?: InputMaybe<PressPublicationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockQAndA = {
  __typename?: 'ComponentBlockQAndA';
  id: Scalars['ID']['output'];
  questions?: Maybe<QAndARelationResponseCollection>;
};

export type ComponentBlockQAndAQuestionsArgs = {
  filters?: InputMaybe<QAndAFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockReviewList = {
  __typename?: 'ComponentBlockReviewList';
  id: Scalars['ID']['output'];
  reviews?: Maybe<ReviewRelationResponseCollection>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockReviewListReviewsArgs = {
  filters?: InputMaybe<ReviewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockText = {
  __typename?: 'ComponentBlockText';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  text: Scalars['String']['output'];
};

export type ComponentBlockTutorial = {
  __typename?: 'ComponentBlockTutorial';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  link?: Maybe<ComponentFieldLink>;
  paragraphs?: Maybe<Array<Maybe<ComponentFieldParagraph>>>;
  scheme?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<ComponentFieldParagraph>>>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentBlockTutorialParagraphsArgs = {
  filters?: InputMaybe<ComponentFieldParagraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockTutorialStepsArgs = {
  filters?: InputMaybe<ComponentFieldParagraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentBlockTwoColumnsText = {
  __typename?: 'ComponentBlockTwoColumnsText';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  left?: Maybe<Scalars['String']['output']>;
  right?: Maybe<Scalars['String']['output']>;
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentFieldAccordion = {
  __typename?: 'ComponentFieldAccordion';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  open?: Maybe<Scalars['Boolean']['output']>;
  title: ComponentFieldTitle;
};

export type ComponentFieldAccordionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldAccordionFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFieldAccordionFiltersInput>;
  open?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldAccordionFiltersInput>>>;
  title?: InputMaybe<ComponentFieldTitleFiltersInput>;
};

export type ComponentFieldActionBanner = {
  __typename?: 'ComponentFieldActionBanner';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<ComponentFieldLink>;
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFieldActionBannerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldActionBannerFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentFieldLinkFiltersInput>;
  not?: InputMaybe<ComponentFieldActionBannerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldActionBannerFiltersInput>>>;
  scheme?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentFieldActionBannerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<ComponentFieldLinkInput>;
  scheme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFieldCard = {
  __typename?: 'ComponentFieldCard';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  link?: Maybe<ComponentFieldLink>;
  paragraph?: Maybe<ComponentFieldParagraph>;
};

export type ComponentFieldCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldCardFiltersInput>>>;
  icon?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentFieldLinkFiltersInput>;
  not?: InputMaybe<ComponentFieldCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldCardFiltersInput>>>;
  paragraph?: InputMaybe<ComponentFieldParagraphFiltersInput>;
};

export type ComponentFieldCardList = {
  __typename?: 'ComponentFieldCardList';
  cards?: Maybe<Array<Maybe<ComponentFieldCard>>>;
  id: Scalars['ID']['output'];
  scrollOnMobile?: Maybe<Scalars['Boolean']['output']>;
};

export type ComponentFieldCardListCardsArgs = {
  filters?: InputMaybe<ComponentFieldCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFieldImageBanner = {
  __typename?: 'ComponentFieldImageBanner';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  link?: Maybe<ComponentFieldLink>;
  scheme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentFieldLink = {
  __typename?: 'ComponentFieldLink';
  external?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internal?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  newtab?: Maybe<Scalars['Boolean']['output']>;
  page?: Maybe<PageEntityResponse>;
};

export type ComponentFieldLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldLinkFiltersInput>>>;
  external?: InputMaybe<StringFilterInput>;
  internal?: InputMaybe<StringFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  newtab?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentFieldLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldLinkFiltersInput>>>;
  page?: InputMaybe<PageFiltersInput>;
};

export type ComponentFieldLinkInput = {
  external?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  internal?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  newtab?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentFieldLinkList = {
  __typename?: 'ComponentFieldLinkList';
  id: Scalars['ID']['output'];
  links?: Maybe<Array<Maybe<ComponentFieldLink>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFieldLinkListLinksArgs = {
  filters?: InputMaybe<ComponentFieldLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFieldLinkListFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldLinkListFiltersInput>>>;
  links?: InputMaybe<ComponentFieldLinkFiltersInput>;
  not?: InputMaybe<ComponentFieldLinkListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldLinkListFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentFieldLinkListInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  links?: InputMaybe<Array<InputMaybe<ComponentFieldLinkInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFieldParagraph = {
  __typename?: 'ComponentFieldParagraph';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentFieldParagraphFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldParagraphFiltersInput>>>;
  not?: InputMaybe<ComponentFieldParagraphFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldParagraphFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<ComponentFieldTitleFiltersInput>;
};

export type ComponentFieldStep = {
  __typename?: 'ComponentFieldStep';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<ComponentFieldTitle>;
};

export type ComponentFieldStepFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldStepFiltersInput>>>;
  not?: InputMaybe<ComponentFieldStepFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldStepFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<ComponentFieldTitleFiltersInput>;
};

export type ComponentFieldTag = {
  __typename?: 'ComponentFieldTag';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ComponentFieldTagFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldTagFiltersInput>>>;
  icon?: InputMaybe<StringFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFieldTagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldTagFiltersInput>>>;
};

export type ComponentFieldTagInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFieldTitle = {
  __typename?: 'ComponentFieldTitle';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tag: Enum_Componentfieldtitle_Tag;
};

export type ComponentFieldTitleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFieldTitleFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFieldTitleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFieldTitleFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
};

export type ComponentFieldTitleInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Enum_Componentfieldtitle_Tag>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  metaViewport?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: InputMaybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  main?: Maybe<Array<Maybe<ContactMainDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo: ComponentSharedSeo;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactEntity = {
  __typename?: 'ContactEntity';
  attributes?: Maybe<Contact>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContactEntityResponse = {
  __typename?: 'ContactEntityResponse';
  data?: Maybe<ContactEntity>;
};

export type ContactInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  main?: InputMaybe<Array<Scalars['ContactMainDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ContactMainDynamicZone =
  | ComponentBlockAvisVerifies
  | ComponentBlockCardBoard
  | ComponentBlockCta
  | ComponentBlockQAndA
  | Error;

export type ContentReleasesRelease = {
  __typename?: 'ContentReleasesRelease';
  actions?: Maybe<ContentReleasesReleaseActionRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  releasedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentReleasesReleaseAction = {
  __typename?: 'ContentReleasesReleaseAction';
  contentType: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entry?: Maybe<GenericMorph>;
  locale?: Maybe<Scalars['String']['output']>;
  release?: Maybe<ContentReleasesReleaseEntityResponse>;
  type: Enum_Contentreleasesreleaseaction_Type;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContentReleasesReleaseActionEntity = {
  __typename?: 'ContentReleasesReleaseActionEntity';
  attributes?: Maybe<ContentReleasesReleaseAction>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContentReleasesReleaseActionEntityResponse = {
  __typename?: 'ContentReleasesReleaseActionEntityResponse';
  data?: Maybe<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseActionEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionEntityResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseActionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  release?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseActionInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  release?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Enum_Contentreleasesreleaseaction_Type>;
};

export type ContentReleasesReleaseActionRelationResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionRelationResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseEntity = {
  __typename?: 'ContentReleasesReleaseEntity';
  attributes?: Maybe<ContentReleasesRelease>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContentReleasesReleaseEntityResponse = {
  __typename?: 'ContentReleasesReleaseEntityResponse';
  data?: Maybe<ContentReleasesReleaseEntity>;
};

export type ContentReleasesReleaseEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseEntityResponseCollection';
  data: Array<ContentReleasesReleaseEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseFiltersInput = {
  actions?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  releasedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  releasedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentfieldtitle_Tag {
  B = 'b',
  Em = 'em',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Span = 'span',
  Strong = 'strong',
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Contentreleasesreleaseaction_Type {
  Publish = 'publish',
  Unpublish = 'unpublish',
}

export enum Enum_Product_Tag {
  Discount = 'discount',
  Eco = 'eco',
  FastDelivery = 'fast_delivery',
  Favorite = 'favorite',
  FrenchMade = 'french_made',
  LoanDuration = 'loan_duration',
  Refurbished = 'refurbished',
}

export enum Enum_Review_Stars {
  Five = 'Five',
  Four = 'Four',
  One = 'One',
  Three = 'Three',
  Two = 'Two',
}

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph =
  | Brand
  | Category
  | ComponentBlock3StepsCard
  | ComponentBlockAccordionList
  | ComponentBlockAvisVerifies
  | ComponentBlockBlogPosts
  | ComponentBlockBrandList
  | ComponentBlockCardBoard
  | ComponentBlockCategoryList
  | ComponentBlockComparisonTable
  | ComponentBlockContactUs
  | ComponentBlockCta
  | ComponentBlockFeaturedProductList
  | ComponentBlockHero
  | ComponentBlockLandingHero
  | ComponentBlockProductList
  | ComponentBlockPublications
  | ComponentBlockQAndA
  | ComponentBlockReviewList
  | ComponentBlockText
  | ComponentBlockTutorial
  | ComponentBlockTwoColumnsText
  | ComponentFieldAccordion
  | ComponentFieldActionBanner
  | ComponentFieldCard
  | ComponentFieldCardList
  | ComponentFieldImageBanner
  | ComponentFieldLink
  | ComponentFieldLinkList
  | ComponentFieldParagraph
  | ComponentFieldStep
  | ComponentFieldTag
  | ComponentFieldTitle
  | ComponentSharedMetaSocial
  | ComponentSharedSeo
  | Contact
  | ContentReleasesRelease
  | ContentReleasesReleaseAction
  | Global
  | Homepage
  | I18NLocale
  | LandingPage
  | Page
  | Page404
  | Post
  | PressPublication
  | Product
  | QAndA
  | Review
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser;

export type Global = {
  __typename?: 'Global';
  Prefooter?: Maybe<Array<Maybe<ComponentFieldLinkList>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GlobalPrefooterArgs = {
  filters?: InputMaybe<ComponentFieldLinkListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GlobalEntity = {
  __typename?: 'GlobalEntity';
  attributes?: Maybe<Global>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GlobalEntityResponse = {
  __typename?: 'GlobalEntityResponse';
  data?: Maybe<GlobalEntity>;
};

export type GlobalInput = {
  Prefooter?: InputMaybe<Array<InputMaybe<ComponentFieldLinkListInput>>>;
};

export type Homepage = {
  __typename?: 'Homepage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero: ComponentBlockLandingHero;
  main?: Maybe<Array<Maybe<HomepageMainDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo: ComponentSharedSeo;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HomepageEntity = {
  __typename?: 'HomepageEntity';
  attributes?: Maybe<Homepage>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HomepageEntityResponse = {
  __typename?: 'HomepageEntityResponse';
  data?: Maybe<HomepageEntity>;
};

export type HomepageInput = {
  hero?: InputMaybe<ComponentBlockLandingHeroInput>;
  main?: InputMaybe<Array<Scalars['HomepageMainDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HomepageMainDynamicZone =
  | ComponentBlock3StepsCard
  | ComponentBlockAccordionList
  | ComponentBlockAvisVerifies
  | ComponentBlockBlogPosts
  | ComponentBlockBrandList
  | ComponentBlockCardBoard
  | ComponentBlockCategoryList
  | ComponentBlockComparisonTable
  | ComponentBlockContactUs
  | ComponentBlockCta
  | ComponentBlockFeaturedProductList
  | ComponentBlockProductList
  | ComponentBlockPublications
  | ComponentBlockQAndA
  | ComponentBlockReviewList
  | ComponentBlockText
  | ComponentBlockTutorial
  | ComponentBlockTwoColumnsText
  | Error;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type LandingPage = {
  __typename?: 'LandingPage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cta?: Maybe<ComponentFieldLink>;
  hero: ComponentBlockLandingHero;
  main: Array<Maybe<LandingPageMainDynamicZone>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo: ComponentSharedSeo;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LandingPageEntity = {
  __typename?: 'LandingPageEntity';
  attributes?: Maybe<LandingPage>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LandingPageEntityResponse = {
  __typename?: 'LandingPageEntityResponse';
  data?: Maybe<LandingPageEntity>;
};

export type LandingPageEntityResponseCollection = {
  __typename?: 'LandingPageEntityResponseCollection';
  data: Array<LandingPageEntity>;
  meta: ResponseCollectionMeta;
};

export type LandingPageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LandingPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  cta?: InputMaybe<ComponentFieldLinkFiltersInput>;
  hero?: InputMaybe<ComponentBlockLandingHeroFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<LandingPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LandingPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LandingPageInput = {
  cta?: InputMaybe<ComponentFieldLinkInput>;
  hero?: InputMaybe<ComponentBlockLandingHeroInput>;
  main?: InputMaybe<Array<Scalars['LandingPageMainDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LandingPageMainDynamicZone =
  | ComponentBlock3StepsCard
  | ComponentBlockAccordionList
  | ComponentBlockAvisVerifies
  | ComponentBlockBlogPosts
  | ComponentBlockBrandList
  | ComponentBlockCardBoard
  | ComponentBlockCategoryList
  | ComponentBlockComparisonTable
  | ComponentBlockContactUs
  | ComponentBlockCta
  | ComponentBlockFeaturedProductList
  | ComponentBlockLandingHero
  | ComponentBlockProductList
  | ComponentBlockPublications
  | ComponentBlockQAndA
  | ComponentBlockReviewList
  | ComponentBlockText
  | ComponentBlockTutorial
  | ComponentBlockTwoColumnsText
  | Error;

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createBrand?: Maybe<BrandEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  createContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  createContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  createLandingPage?: Maybe<LandingPageEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  createPost?: Maybe<PostEntityResponse>;
  createPressPublication?: Maybe<PressPublicationEntityResponse>;
  createProduct?: Maybe<ProductEntityResponse>;
  createQAndA?: Maybe<QAndAEntityResponse>;
  createReview?: Maybe<ReviewEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteBrand?: Maybe<BrandEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  deleteContact?: Maybe<ContactEntityResponse>;
  deleteContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  deleteContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  deleteGlobal?: Maybe<GlobalEntityResponse>;
  deleteHomepage?: Maybe<HomepageEntityResponse>;
  deleteLandingPage?: Maybe<LandingPageEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  deletePage404?: Maybe<Page404EntityResponse>;
  deletePost?: Maybe<PostEntityResponse>;
  deletePressPublication?: Maybe<PressPublicationEntityResponse>;
  deleteProduct?: Maybe<ProductEntityResponse>;
  deleteQAndA?: Maybe<QAndAEntityResponse>;
  deleteReview?: Maybe<ReviewEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateBrand?: Maybe<BrandEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  updateContact?: Maybe<ContactEntityResponse>;
  updateContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  updateContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGlobal?: Maybe<GlobalEntityResponse>;
  updateHomepage?: Maybe<HomepageEntityResponse>;
  updateLandingPage?: Maybe<LandingPageEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  updatePage404?: Maybe<Page404EntityResponse>;
  updatePost?: Maybe<PostEntityResponse>;
  updatePressPublication?: Maybe<PressPublicationEntityResponse>;
  updateProduct?: Maybe<ProductEntityResponse>;
  updateQAndA?: Maybe<QAndAEntityResponse>;
  updateReview?: Maybe<ReviewEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateBrandArgs = {
  data: BrandInput;
};

export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};

export type MutationCreateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
};

export type MutationCreateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
};

export type MutationCreateLandingPageArgs = {
  data: LandingPageInput;
};

export type MutationCreatePageArgs = {
  data: PageInput;
};

export type MutationCreatePostArgs = {
  data: PostInput;
};

export type MutationCreatePressPublicationArgs = {
  data: PressPublicationInput;
};

export type MutationCreateProductArgs = {
  data: ProductInput;
};

export type MutationCreateQAndAArgs = {
  data: QAndAInput;
};

export type MutationCreateReviewArgs = {
  data: ReviewInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteBrandArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteContentReleasesReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteContentReleasesReleaseActionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLandingPageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePostArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePressPublicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteQAndAArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReviewArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationUpdateBrandArgs = {
  data: BrandInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateContactArgs = {
  data: ContactInput;
};

export type MutationUpdateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
};

export type MutationUpdateHomepageArgs = {
  data: HomepageInput;
};

export type MutationUpdateLandingPageArgs = {
  data: LandingPageInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdatePageArgs = {
  data: PageInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdatePage404Args = {
  data: Page404Input;
};

export type MutationUpdatePostArgs = {
  data: PostInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdatePressPublicationArgs = {
  data: PressPublicationInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateProductArgs = {
  data: ProductInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateQAndAArgs = {
  data: QAndAInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateReviewArgs = {
  data: ReviewInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  externalPath?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  master?: Maybe<Scalars['Int']['output']>;
  menuAttached: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  parent?: Maybe<NavigationItem>;
  path?: Maybe<Scalars['String']['output']>;
  related?: Maybe<NavigationItemRelatedData>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uiRouterKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type NavigationItemRelated = {
  __typename?: 'NavigationItemRelated';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NavigationItemRelatedData = {
  __typename?: 'NavigationItemRelatedData';
  attributes?: Maybe<NavigationItemRelated>;
  id: Scalars['Int']['output'];
};

export enum NavigationRenderType {
  Flat = 'FLAT',
  Rfr = 'RFR',
  Tree = 'TREE',
}

export type Page = {
  __typename?: 'Page';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  main: Array<Maybe<PageMainDynamicZone>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo: ComponentSharedSeo;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Page404 = {
  __typename?: 'Page404';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cta?: Maybe<ComponentFieldLink>;
  main?: Maybe<Array<Maybe<Page404MainDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Page404Entity = {
  __typename?: 'Page404Entity';
  attributes?: Maybe<Page404>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Page404EntityResponse = {
  __typename?: 'Page404EntityResponse';
  data?: Maybe<Page404Entity>;
};

export type Page404Input = {
  cta?: InputMaybe<ComponentFieldLinkInput>;
  main?: InputMaybe<Array<Scalars['Page404MainDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Page404MainDynamicZone =
  | ComponentBlock3StepsCard
  | ComponentBlockAccordionList
  | ComponentBlockAvisVerifies
  | ComponentBlockBlogPosts
  | ComponentBlockBrandList
  | ComponentBlockCardBoard
  | ComponentBlockCategoryList
  | ComponentBlockComparisonTable
  | ComponentBlockContactUs
  | ComponentBlockCta
  | ComponentBlockFeaturedProductList
  | ComponentBlockHero
  | ComponentBlockLandingHero
  | ComponentBlockProductList
  | ComponentBlockPublications
  | ComponentBlockQAndA
  | ComponentBlockReviewList
  | ComponentBlockText
  | ComponentBlockTutorial
  | ComponentBlockTwoColumnsText
  | Error;

export type PageEntity = {
  __typename?: 'PageEntity';
  attributes?: Maybe<Page>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse';
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection';
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export type PageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<PageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PageInput = {
  main?: InputMaybe<Array<Scalars['PageMainDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PageMainDynamicZone =
  | ComponentBlock3StepsCard
  | ComponentBlockAccordionList
  | ComponentBlockAvisVerifies
  | ComponentBlockBlogPosts
  | ComponentBlockBrandList
  | ComponentBlockCardBoard
  | ComponentBlockCategoryList
  | ComponentBlockContactUs
  | ComponentBlockCta
  | ComponentBlockFeaturedProductList
  | ComponentBlockLandingHero
  | ComponentBlockProductList
  | ComponentBlockPublications
  | ComponentBlockQAndA
  | ComponentBlockReviewList
  | ComponentBlockText
  | ComponentBlockTutorial
  | ComponentBlockTwoColumnsText
  | Error;

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type Post = {
  __typename?: 'Post';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  introduction: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PostEntity = {
  __typename?: 'PostEntity';
  attributes?: Maybe<Post>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PostEntityResponse = {
  __typename?: 'PostEntityResponse';
  data?: Maybe<PostEntity>;
};

export type PostEntityResponseCollection = {
  __typename?: 'PostEntityResponseCollection';
  data: Array<PostEntity>;
  meta: ResponseCollectionMeta;
};

export type PostFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  introduction?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PostFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  redirectUrl?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PostInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  introduction?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PostRelationResponseCollection = {
  __typename?: 'PostRelationResponseCollection';
  data: Array<PostEntity>;
};

export type PressPublication = {
  __typename?: 'PressPublication';
  article?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  link: Scalars['String']['output'];
  logo: UploadFileEntityResponse;
  mediaName: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PressPublicationEntity = {
  __typename?: 'PressPublicationEntity';
  attributes?: Maybe<PressPublication>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PressPublicationEntityResponse = {
  __typename?: 'PressPublicationEntityResponse';
  data?: Maybe<PressPublicationEntity>;
};

export type PressPublicationEntityResponseCollection = {
  __typename?: 'PressPublicationEntityResponseCollection';
  data: Array<PressPublicationEntity>;
  meta: ResponseCollectionMeta;
};

export type PressPublicationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PressPublicationFiltersInput>>>;
  article?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  mediaName?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PressPublicationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PressPublicationFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PressPublicationInput = {
  article?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  mediaName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PressPublicationRelationResponseCollection = {
  __typename?: 'PressPublicationRelationResponseCollection';
  data: Array<PressPublicationEntity>;
};

export type Product = {
  __typename?: 'Product';
  characteristics?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  product: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  tag?: Maybe<Enum_Product_Tag>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  attributes?: Maybe<Product>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProductEntityResponse = {
  __typename?: 'ProductEntityResponse';
  data?: Maybe<ProductEntity>;
};

export type ProductEntityResponseCollection = {
  __typename?: 'ProductEntityResponseCollection';
  data: Array<ProductEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  characteristics?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ProductFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  product?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  tag?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductInput = {
  characteristics?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  tag?: InputMaybe<Enum_Product_Tag>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ProductRelationResponseCollection = {
  __typename?: 'ProductRelationResponseCollection';
  data: Array<ProductEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type QAndA = {
  __typename?: 'QAndA';
  answer: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  question: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type QAndAEntity = {
  __typename?: 'QAndAEntity';
  attributes?: Maybe<QAndA>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type QAndAEntityResponse = {
  __typename?: 'QAndAEntityResponse';
  data?: Maybe<QAndAEntity>;
};

export type QAndAEntityResponseCollection = {
  __typename?: 'QAndAEntityResponseCollection';
  data: Array<QAndAEntity>;
  meta: ResponseCollectionMeta;
};

export type QAndAFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<QAndAFiltersInput>>>;
  answer?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<QAndAFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<QAndAFiltersInput>>>;
  question?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type QAndAInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type QAndARelationResponseCollection = {
  __typename?: 'QAndARelationResponseCollection';
  data: Array<QAndAEntity>;
};

export type Query = {
  __typename?: 'Query';
  brand?: Maybe<BrandEntityResponse>;
  brands?: Maybe<BrandEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  contact?: Maybe<ContactEntityResponse>;
  contentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  contentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  contentReleasesReleaseActions?: Maybe<ContentReleasesReleaseActionEntityResponseCollection>;
  contentReleasesReleases?: Maybe<ContentReleasesReleaseEntityResponseCollection>;
  global?: Maybe<GlobalEntityResponse>;
  homepage?: Maybe<HomepageEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  landingPage?: Maybe<LandingPageEntityResponse>;
  landingPages?: Maybe<LandingPageEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  page?: Maybe<PageEntityResponse>;
  page404?: Maybe<Page404EntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  post?: Maybe<PostEntityResponse>;
  posts?: Maybe<PostEntityResponseCollection>;
  pressPublication?: Maybe<PressPublicationEntityResponse>;
  pressPublications?: Maybe<PressPublicationEntityResponseCollection>;
  product?: Maybe<ProductEntityResponse>;
  products?: Maybe<ProductEntityResponseCollection>;
  qAndA?: Maybe<QAndAEntityResponse>;
  qAndAs?: Maybe<QAndAEntityResponseCollection>;
  renderNavigation: Array<Maybe<NavigationItem>>;
  renderNavigationChild: Array<Maybe<NavigationItem>>;
  review?: Maybe<ReviewEntityResponse>;
  reviews?: Maybe<ReviewEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};

export type QueryBrandArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBrandsArgs = {
  filters?: InputMaybe<BrandFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContactArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryContentReleasesReleaseArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentReleasesReleaseActionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryContentReleasesReleasesArgs = {
  filters?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryHomepageArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryLandingPageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryLandingPagesArgs = {
  filters?: InputMaybe<LandingPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPage404Args = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPostsArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryPressPublicationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPressPublicationsArgs = {
  filters?: InputMaybe<PressPublicationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryQAndAArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryQAndAsArgs = {
  filters?: InputMaybe<QAndAFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryRenderNavigationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  menuOnly?: InputMaybe<Scalars['Boolean']['input']>;
  navigationIdOrSlug: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NavigationRenderType>;
};

export type QueryRenderNavigationChildArgs = {
  childUiKey: Scalars['String']['input'];
  id: Scalars['String']['input'];
  menuOnly?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<NavigationRenderType>;
};

export type QueryReviewArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReviewsArgs = {
  filters?: InputMaybe<ReviewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type Review = {
  __typename?: 'Review';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  stars?: Maybe<Enum_Review_Stars>;
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  attributes?: Maybe<Review>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ReviewEntityResponse = {
  __typename?: 'ReviewEntityResponse';
  data?: Maybe<ReviewEntity>;
};

export type ReviewEntityResponseCollection = {
  __typename?: 'ReviewEntityResponseCollection';
  data: Array<ReviewEntity>;
  meta: ResponseCollectionMeta;
};

export type ReviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReviewFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ReviewFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReviewFiltersInput>>>;
  position?: InputMaybe<StringFilterInput>;
  stars?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReviewInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  stars?: InputMaybe<Enum_Review_Stars>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewRelationResponseCollection = {
  __typename?: 'ReviewRelationResponseCollection';
  data: Array<ReviewEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Get404PageQueryVariables = Exact<{
  publicationState?: PublicationState;
}>;

export type Get404PageQuery = {
  __typename?: 'Query';
  page404?: {
    __typename?: 'Page404EntityResponse';
    data?: {
      __typename?: 'Page404Entity';
      attributes?: {
        __typename?: 'Page404';
        title?: string | null;
        cta?: {
          __typename?: 'ComponentFieldLink';
          external?: string | null;
          internal?: string | null;
          label?: string | null;
          newtab?: boolean | null;
          page?: {
            __typename?: 'PageEntityResponse';
            data?: {
              __typename?: 'PageEntity';
              attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
            } | null;
          } | null;
        } | null;
        main?: Array<
          | {
              __typename?: 'ComponentBlock3StepsCard';
              scheme?: string | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              stepList?: Array<{
                __typename?: 'ComponentFieldStep';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockAccordionList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              items: Array<{
                __typename?: 'ComponentFieldAccordion';
                id: string;
                content: string;
                open?: boolean | null;
                title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null>;
            }
          | {
              __typename?: 'ComponentBlockAvisVerifies';
              count?: number | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBlogPosts';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCtaTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              posts?: {
                __typename?: 'PostRelationResponseCollection';
                data: Array<{
                  __typename?: 'PostEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Post';
                    title: string;
                    slug?: string | null;
                    introduction: string;
                    redirectUrl: string;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBrandList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              brands?: {
                __typename?: 'BrandRelationResponseCollection';
                data: Array<{
                  __typename?: 'BrandEntity';
                  attributes?: {
                    __typename?: 'Brand';
                    name: string;
                    slug: string;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCardBoard';
              scheme?: string | null;
              description?: string | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cardList?: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCategoryList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              categories?: {
                __typename?: 'CategoryRelationResponseCollection';
                data: Array<{
                  __typename?: 'CategoryEntity';
                  attributes?: {
                    __typename?: 'Category';
                    title: string;
                    slug: string;
                    startFromPrice: number;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockComparisonTable';
              description?: string | null;
              scheme?: string | null;
              leftLabel: string;
              rightLabel: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              leftContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              rightContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              banner?: {
                __typename?: 'ComponentFieldImageBanner';
                description?: string | null;
                scheme?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                link?: {
                  __typename?: 'ComponentFieldLink';
                  external?: string | null;
                  internal?: string | null;
                  label?: string | null;
                  newtab?: boolean | null;
                  page?: {
                    __typename?: 'PageEntityResponse';
                    data?: {
                      __typename?: 'PageEntity';
                      attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                    } | null;
                  } | null;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockContactUs';
              description?: string | null;
              withCTA?: boolean | null;
              ctaTitle?: string | null;
              ctaLabel?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCta';
              description?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cta: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              };
            }
          | {
              __typename?: 'ComponentBlockFeaturedProductList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              products?: {
                __typename?: 'ProductRelationResponseCollection';
                data: Array<{
                  __typename?: 'ProductEntity';
                  attributes?: {
                    __typename?: 'Product';
                    title?: string | null;
                    tag?: Enum_Product_Tag | null;
                    product: string;
                    characteristics?: string | null;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | { __typename?: 'ComponentBlockHero' }
          | { __typename?: 'ComponentBlockLandingHero' }
          | {
              __typename?: 'ComponentBlockProductList';
              productIds: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockPublications';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              publications?: {
                __typename?: 'PressPublicationRelationResponseCollection';
                data: Array<{
                  __typename?: 'PressPublicationEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'PressPublication';
                    title?: string | null;
                    mediaName: string;
                    link: string;
                    article?: string | null;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockQAndA';
              questions?: {
                __typename?: 'QAndARelationResponseCollection';
                data: Array<{
                  __typename?: 'QAndAEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockReviewList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              reviews?: {
                __typename?: 'ReviewRelationResponseCollection';
                data: Array<{
                  __typename?: 'ReviewEntity';
                  attributes?: {
                    __typename?: 'Review';
                    text: string;
                    name?: string | null;
                    position?: string | null;
                    stars?: Enum_Review_Stars | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockText';
              text: string;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTutorial';
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              paragraphs?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              steps?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              link?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTwoColumnsText';
              left?: string | null;
              right?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type Block3StepsCardFragment = {
  __typename?: 'ComponentBlock3StepsCard';
  scheme?: string | null;
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  stepList?: Array<{
    __typename?: 'ComponentFieldStep';
    id: string;
    text?: string | null;
    title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          hash: string;
          ext?: string | null;
          mime: string;
          width?: number | null;
          height?: number | null;
          alternativeText?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  mainCta?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
};

export type BlockAccordionListFragment = {
  __typename?: 'ComponentBlockAccordionList';
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  items: Array<{
    __typename?: 'ComponentFieldAccordion';
    id: string;
    content: string;
    open?: boolean | null;
    title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          hash: string;
          ext?: string | null;
          mime: string;
          width?: number | null;
          height?: number | null;
          alternativeText?: string | null;
        } | null;
      } | null;
    } | null;
  } | null>;
};

export type BlockAvisVerifiesFragment = {
  __typename?: 'ComponentBlockAvisVerifies';
  count?: number | null;
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
};

export type BlockBlogPostsFragment = {
  __typename?: 'ComponentBlockBlogPosts';
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  asideCtaTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  asideCta?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
  posts?: {
    __typename?: 'PostRelationResponseCollection';
    data: Array<{
      __typename?: 'PostEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Post';
        title: string;
        slug?: string | null;
        introduction: string;
        redirectUrl: string;
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type BlockBrandListFragment = {
  __typename?: 'ComponentBlockBrandList';
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  brands?: {
    __typename?: 'BrandRelationResponseCollection';
    data: Array<{
      __typename?: 'BrandEntity';
      attributes?: {
        __typename?: 'Brand';
        name: string;
        slug: string;
        logo: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        };
      } | null;
    }>;
  } | null;
};

export type BlockCardBoardFragment = {
  __typename?: 'ComponentBlockCardBoard';
  scheme?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  cardList?: {
    __typename?: 'ComponentFieldCardList';
    scrollOnMobile?: boolean | null;
    cards?: Array<{
      __typename?: 'ComponentFieldCard';
      icon?: string | null;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          attributes?: {
            __typename?: 'UploadFile';
            hash: string;
            ext?: string | null;
            mime: string;
            width?: number | null;
            height?: number | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      } | null;
      paragraph?: {
        __typename?: 'ComponentFieldParagraph';
        id: string;
        text?: string | null;
        title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
      } | null;
      link?: {
        __typename?: 'ComponentFieldLink';
        external?: string | null;
        internal?: string | null;
        label?: string | null;
        newtab?: boolean | null;
        page?: {
          __typename?: 'PageEntityResponse';
          data?: {
            __typename?: 'PageEntity';
            attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type BlockCategoryListFragment = {
  __typename?: 'ComponentBlockCategoryList';
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  mainCta?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
  categories?: {
    __typename?: 'CategoryRelationResponseCollection';
    data: Array<{
      __typename?: 'CategoryEntity';
      attributes?: {
        __typename?: 'Category';
        title: string;
        slug: string;
        startFromPrice: number;
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type BlockComparisonTableFragment = {
  __typename?: 'ComponentBlockComparisonTable';
  description?: string | null;
  scheme?: string | null;
  leftLabel: string;
  rightLabel: string;
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  leftContent: {
    __typename?: 'ComponentFieldCardList';
    scrollOnMobile?: boolean | null;
    cards?: Array<{
      __typename?: 'ComponentFieldCard';
      icon?: string | null;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          attributes?: {
            __typename?: 'UploadFile';
            hash: string;
            ext?: string | null;
            mime: string;
            width?: number | null;
            height?: number | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      } | null;
      paragraph?: {
        __typename?: 'ComponentFieldParagraph';
        id: string;
        text?: string | null;
        title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
      } | null;
      link?: {
        __typename?: 'ComponentFieldLink';
        external?: string | null;
        internal?: string | null;
        label?: string | null;
        newtab?: boolean | null;
        page?: {
          __typename?: 'PageEntityResponse';
          data?: {
            __typename?: 'PageEntity';
            attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
  rightContent: {
    __typename?: 'ComponentFieldCardList';
    scrollOnMobile?: boolean | null;
    cards?: Array<{
      __typename?: 'ComponentFieldCard';
      icon?: string | null;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          attributes?: {
            __typename?: 'UploadFile';
            hash: string;
            ext?: string | null;
            mime: string;
            width?: number | null;
            height?: number | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      } | null;
      paragraph?: {
        __typename?: 'ComponentFieldParagraph';
        id: string;
        text?: string | null;
        title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
      } | null;
      link?: {
        __typename?: 'ComponentFieldLink';
        external?: string | null;
        internal?: string | null;
        label?: string | null;
        newtab?: boolean | null;
        page?: {
          __typename?: 'PageEntityResponse';
          data?: {
            __typename?: 'PageEntity';
            attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
  banner?: {
    __typename?: 'ComponentFieldImageBanner';
    description?: string | null;
    scheme?: string | null;
    title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
    link?: {
      __typename?: 'ComponentFieldLink';
      external?: string | null;
      internal?: string | null;
      label?: string | null;
      newtab?: boolean | null;
      page?: {
        __typename?: 'PageEntityResponse';
        data?: {
          __typename?: 'PageEntity';
          attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
        } | null;
      } | null;
    } | null;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          hash: string;
          ext?: string | null;
          mime: string;
          width?: number | null;
          height?: number | null;
          alternativeText?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BlockContactUsFragment = {
  __typename?: 'ComponentBlockContactUs';
  description?: string | null;
  withCTA?: boolean | null;
  ctaTitle?: string | null;
  ctaLabel?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BlockCtaFragment = {
  __typename?: 'ComponentBlockCta';
  description?: string | null;
  scheme?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  cta: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  };
};

export type BlockFeaturedProductListFragment = {
  __typename?: 'ComponentBlockFeaturedProductList';
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  products?: {
    __typename?: 'ProductRelationResponseCollection';
    data: Array<{
      __typename?: 'ProductEntity';
      attributes?: {
        __typename?: 'Product';
        title?: string | null;
        tag?: Enum_Product_Tag | null;
        product: string;
        characteristics?: string | null;
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type BlockHeroFragment = {
  __typename?: 'ComponentBlockHero';
  description?: string | null;
  title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
  tags?: Array<{ __typename?: 'ComponentFieldTag'; label: string; icon?: string | null } | null> | null;
};

export type BlockLandingHeroFragment = {
  __typename?: 'ComponentBlockLandingHero';
  description?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  tags?: Array<{ __typename?: 'ComponentFieldTag'; label: string; icon?: string | null } | null> | null;
  actionBanner?: {
    __typename?: 'ComponentFieldActionBanner';
    title?: string | null;
    description?: string | null;
    scheme?: string | null;
    link?: {
      __typename?: 'ComponentFieldLink';
      external?: string | null;
      internal?: string | null;
      label?: string | null;
      newtab?: boolean | null;
      page?: {
        __typename?: 'PageEntityResponse';
        data?: {
          __typename?: 'PageEntity';
          attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BlockProductListFragment = {
  __typename?: 'ComponentBlockProductList';
  productIds: string;
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  mainCta?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
};

export type BlockPublicationsFragment = {
  __typename?: 'ComponentBlockPublications';
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  publications?: {
    __typename?: 'PressPublicationRelationResponseCollection';
    data: Array<{
      __typename?: 'PressPublicationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'PressPublication';
        title?: string | null;
        mediaName: string;
        link: string;
        article?: string | null;
        logo: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        };
      } | null;
    }>;
  } | null;
};

export type BlockQAndAFragment = {
  __typename?: 'ComponentBlockQAndA';
  questions?: {
    __typename?: 'QAndARelationResponseCollection';
    data: Array<{
      __typename?: 'QAndAEntity';
      id?: string | null;
      attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
    }>;
  } | null;
};

export type BlockReviewListFragment = {
  __typename?: 'ComponentBlockReviewList';
  mainTitle?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  reviews?: {
    __typename?: 'ReviewRelationResponseCollection';
    data: Array<{
      __typename?: 'ReviewEntity';
      attributes?: {
        __typename?: 'Review';
        text: string;
        name?: string | null;
        position?: string | null;
        stars?: Enum_Review_Stars | null;
      } | null;
    }>;
  } | null;
};

export type BlockTextFragment = {
  __typename?: 'ComponentBlockText';
  text: string;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BlockTutorialFragment = {
  __typename?: 'ComponentBlockTutorial';
  scheme?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  paragraphs?: Array<{
    __typename?: 'ComponentFieldParagraph';
    id: string;
    text?: string | null;
    title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  } | null> | null;
  steps?: Array<{
    __typename?: 'ComponentFieldParagraph';
    id: string;
    text?: string | null;
    title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  } | null> | null;
  link?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BlockTwoColumnsTextFragment = {
  __typename?: 'ComponentBlockTwoColumnsText';
  left?: string | null;
  right?: string | null;
  scheme?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetContactPagesQueryVariables = Exact<{
  publicationState?: PublicationState;
}>;

export type GetContactPagesQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'ContactEntityResponse';
    data?: {
      __typename?: 'ContactEntity';
      attributes?: {
        __typename?: 'Contact';
        title: string;
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
        main?: Array<
          | {
              __typename?: 'ComponentBlockAvisVerifies';
              count?: number | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCardBoard';
              scheme?: string | null;
              description?: string | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cardList?: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCta';
              description?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cta: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              };
            }
          | {
              __typename?: 'ComponentBlockQAndA';
              questions?: {
                __typename?: 'QAndARelationResponseCollection';
                data: Array<{
                  __typename?: 'QAndAEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
                }>;
              } | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo: {
          __typename?: 'ComponentSharedSeo';
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          canonicalURL?: string | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                attributes?: {
                  __typename?: 'UploadFile';
                  hash: string;
                  ext?: string | null;
                  mime: string;
                  width?: number | null;
                  height?: number | null;
                  alternativeText?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type GetFeaturedProductListQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type GetFeaturedProductListQuery = {
  __typename?: 'Query';
  products?: {
    __typename?: 'ProductEntityResponseCollection';
    data: Array<{
      __typename?: 'ProductEntity';
      attributes?: {
        __typename?: 'Product';
        title?: string | null;
        tag?: Enum_Product_Tag | null;
        product: string;
        characteristics?: string | null;
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              hash: string;
              ext?: string | null;
              mime: string;
              width?: number | null;
              height?: number | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type FieldAccordionFragment = {
  __typename?: 'ComponentFieldAccordion';
  id: string;
  content: string;
  open?: boolean | null;
  title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type FieldActionBannerFragment = {
  __typename?: 'ComponentFieldActionBanner';
  title?: string | null;
  description?: string | null;
  scheme?: string | null;
  link?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
};

export type FieldCardListFragment = {
  __typename?: 'ComponentFieldCardList';
  scrollOnMobile?: boolean | null;
  cards?: Array<{
    __typename?: 'ComponentFieldCard';
    icon?: string | null;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          hash: string;
          ext?: string | null;
          mime: string;
          width?: number | null;
          height?: number | null;
          alternativeText?: string | null;
        } | null;
      } | null;
    } | null;
    paragraph?: {
      __typename?: 'ComponentFieldParagraph';
      id: string;
      text?: string | null;
      title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
    } | null;
    link?: {
      __typename?: 'ComponentFieldLink';
      external?: string | null;
      internal?: string | null;
      label?: string | null;
      newtab?: boolean | null;
      page?: {
        __typename?: 'PageEntityResponse';
        data?: {
          __typename?: 'PageEntity';
          attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FieldCardFragment = {
  __typename?: 'ComponentFieldCard';
  icon?: string | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
  paragraph?: {
    __typename?: 'ComponentFieldParagraph';
    id: string;
    text?: string | null;
    title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  } | null;
  link?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
};

export type FieldImageBannerFragment = {
  __typename?: 'ComponentFieldImageBanner';
  description?: string | null;
  scheme?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  link?: {
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type FieldImageFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    attributes?: {
      __typename?: 'UploadFile';
      hash: string;
      ext?: string | null;
      mime: string;
      width?: number | null;
      height?: number | null;
      alternativeText?: string | null;
    } | null;
  } | null;
};

export type FieldLinkListFragment = {
  __typename?: 'ComponentFieldLinkList';
  title?: string | null;
  links?: Array<{
    __typename?: 'ComponentFieldLink';
    external?: string | null;
    internal?: string | null;
    label?: string | null;
    newtab?: boolean | null;
    page?: {
      __typename?: 'PageEntityResponse';
      data?: {
        __typename?: 'PageEntity';
        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FieldLinkFragment = {
  __typename?: 'ComponentFieldLink';
  external?: string | null;
  internal?: string | null;
  label?: string | null;
  newtab?: boolean | null;
  page?: {
    __typename?: 'PageEntityResponse';
    data?: {
      __typename?: 'PageEntity';
      attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
    } | null;
  } | null;
};

export type FieldParagraphFragment = {
  __typename?: 'ComponentFieldParagraph';
  id: string;
  text?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
};

export type FieldStepFragment = {
  __typename?: 'ComponentFieldStep';
  id: string;
  text?: string | null;
  title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type FieldTagFragment = { __typename?: 'ComponentFieldTag'; label: string; icon?: string | null };

export type FieldTitleFragment = {
  __typename?: 'ComponentFieldTitle';
  tag: Enum_Componentfieldtitle_Tag;
  content: string;
};

export type GetGlobalSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGlobalSettingsQuery = {
  __typename?: 'Query';
  global?: {
    __typename?: 'GlobalEntityResponse';
    data?: {
      __typename?: 'GlobalEntity';
      attributes?: {
        __typename?: 'Global';
        Prefooter?: Array<{
          __typename?: 'ComponentFieldLinkList';
          title?: string | null;
          links?: Array<{
            __typename?: 'ComponentFieldLink';
            external?: string | null;
            internal?: string | null;
            label?: string | null;
            newtab?: boolean | null;
            page?: {
              __typename?: 'PageEntityResponse';
              data?: {
                __typename?: 'PageEntity';
                attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetHomepagesQueryVariables = Exact<{
  publicationState?: PublicationState;
}>;

export type GetHomepagesQuery = {
  __typename?: 'Query';
  homepage?: {
    __typename?: 'HomepageEntityResponse';
    data?: {
      __typename?: 'HomepageEntity';
      attributes?: {
        __typename?: 'Homepage';
        hero: {
          __typename?: 'ComponentBlockLandingHero';
          description?: string | null;
          title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
          tags?: Array<{ __typename?: 'ComponentFieldTag'; label: string; icon?: string | null } | null> | null;
          actionBanner?: {
            __typename?: 'ComponentFieldActionBanner';
            title?: string | null;
            description?: string | null;
            scheme?: string | null;
            link?: {
              __typename?: 'ComponentFieldLink';
              external?: string | null;
              internal?: string | null;
              label?: string | null;
              newtab?: boolean | null;
              page?: {
                __typename?: 'PageEntityResponse';
                data?: {
                  __typename?: 'PageEntity';
                  attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
        };
        main?: Array<
          | {
              __typename?: 'ComponentBlock3StepsCard';
              scheme?: string | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              stepList?: Array<{
                __typename?: 'ComponentFieldStep';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockAccordionList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              items: Array<{
                __typename?: 'ComponentFieldAccordion';
                id: string;
                content: string;
                open?: boolean | null;
                title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null>;
            }
          | {
              __typename?: 'ComponentBlockAvisVerifies';
              count?: number | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBlogPosts';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCtaTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              posts?: {
                __typename?: 'PostRelationResponseCollection';
                data: Array<{
                  __typename?: 'PostEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Post';
                    title: string;
                    slug?: string | null;
                    introduction: string;
                    redirectUrl: string;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBrandList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              brands?: {
                __typename?: 'BrandRelationResponseCollection';
                data: Array<{
                  __typename?: 'BrandEntity';
                  attributes?: {
                    __typename?: 'Brand';
                    name: string;
                    slug: string;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCardBoard';
              scheme?: string | null;
              description?: string | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cardList?: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCategoryList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              categories?: {
                __typename?: 'CategoryRelationResponseCollection';
                data: Array<{
                  __typename?: 'CategoryEntity';
                  attributes?: {
                    __typename?: 'Category';
                    title: string;
                    slug: string;
                    startFromPrice: number;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockComparisonTable';
              description?: string | null;
              scheme?: string | null;
              leftLabel: string;
              rightLabel: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              leftContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              rightContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              banner?: {
                __typename?: 'ComponentFieldImageBanner';
                description?: string | null;
                scheme?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                link?: {
                  __typename?: 'ComponentFieldLink';
                  external?: string | null;
                  internal?: string | null;
                  label?: string | null;
                  newtab?: boolean | null;
                  page?: {
                    __typename?: 'PageEntityResponse';
                    data?: {
                      __typename?: 'PageEntity';
                      attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                    } | null;
                  } | null;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockContactUs';
              description?: string | null;
              withCTA?: boolean | null;
              ctaTitle?: string | null;
              ctaLabel?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCta';
              description?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cta: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              };
            }
          | {
              __typename?: 'ComponentBlockFeaturedProductList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              products?: {
                __typename?: 'ProductRelationResponseCollection';
                data: Array<{
                  __typename?: 'ProductEntity';
                  attributes?: {
                    __typename?: 'Product';
                    title?: string | null;
                    tag?: Enum_Product_Tag | null;
                    product: string;
                    characteristics?: string | null;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockProductList';
              productIds: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockPublications';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              publications?: {
                __typename?: 'PressPublicationRelationResponseCollection';
                data: Array<{
                  __typename?: 'PressPublicationEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'PressPublication';
                    title?: string | null;
                    mediaName: string;
                    link: string;
                    article?: string | null;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockQAndA';
              questions?: {
                __typename?: 'QAndARelationResponseCollection';
                data: Array<{
                  __typename?: 'QAndAEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockReviewList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              reviews?: {
                __typename?: 'ReviewRelationResponseCollection';
                data: Array<{
                  __typename?: 'ReviewEntity';
                  attributes?: {
                    __typename?: 'Review';
                    text: string;
                    name?: string | null;
                    position?: string | null;
                    stars?: Enum_Review_Stars | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockText';
              text: string;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTutorial';
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              paragraphs?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              steps?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              link?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTwoColumnsText';
              left?: string | null;
              right?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo: {
          __typename?: 'ComponentSharedSeo';
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          canonicalURL?: string | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                attributes?: {
                  __typename?: 'UploadFile';
                  hash: string;
                  ext?: string | null;
                  mime: string;
                  width?: number | null;
                  height?: number | null;
                  alternativeText?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type GetLandingPagesQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  publicationState?: PublicationState;
}>;

export type GetLandingPagesQuery = {
  __typename?: 'Query';
  landingPages?: {
    __typename?: 'LandingPageEntityResponseCollection';
    data: Array<{
      __typename?: 'LandingPageEntity';
      attributes?: {
        __typename?: 'LandingPage';
        title: string;
        slug: string;
        cta?: {
          __typename?: 'ComponentFieldLink';
          external?: string | null;
          internal?: string | null;
          label?: string | null;
          newtab?: boolean | null;
          page?: {
            __typename?: 'PageEntityResponse';
            data?: {
              __typename?: 'PageEntity';
              attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
            } | null;
          } | null;
        } | null;
        hero: {
          __typename?: 'ComponentBlockLandingHero';
          description?: string | null;
          title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
          tags?: Array<{ __typename?: 'ComponentFieldTag'; label: string; icon?: string | null } | null> | null;
          actionBanner?: {
            __typename?: 'ComponentFieldActionBanner';
            title?: string | null;
            description?: string | null;
            scheme?: string | null;
            link?: {
              __typename?: 'ComponentFieldLink';
              external?: string | null;
              internal?: string | null;
              label?: string | null;
              newtab?: boolean | null;
              page?: {
                __typename?: 'PageEntityResponse';
                data?: {
                  __typename?: 'PageEntity';
                  attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
        };
        main: Array<
          | {
              __typename?: 'ComponentBlock3StepsCard';
              scheme?: string | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              stepList?: Array<{
                __typename?: 'ComponentFieldStep';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockAccordionList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              items: Array<{
                __typename?: 'ComponentFieldAccordion';
                id: string;
                content: string;
                open?: boolean | null;
                title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null>;
            }
          | {
              __typename?: 'ComponentBlockAvisVerifies';
              count?: number | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBlogPosts';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCtaTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              posts?: {
                __typename?: 'PostRelationResponseCollection';
                data: Array<{
                  __typename?: 'PostEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Post';
                    title: string;
                    slug?: string | null;
                    introduction: string;
                    redirectUrl: string;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBrandList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              brands?: {
                __typename?: 'BrandRelationResponseCollection';
                data: Array<{
                  __typename?: 'BrandEntity';
                  attributes?: {
                    __typename?: 'Brand';
                    name: string;
                    slug: string;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCardBoard';
              scheme?: string | null;
              description?: string | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cardList?: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCategoryList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              categories?: {
                __typename?: 'CategoryRelationResponseCollection';
                data: Array<{
                  __typename?: 'CategoryEntity';
                  attributes?: {
                    __typename?: 'Category';
                    title: string;
                    slug: string;
                    startFromPrice: number;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockComparisonTable';
              description?: string | null;
              scheme?: string | null;
              leftLabel: string;
              rightLabel: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              leftContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              rightContent: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              };
              banner?: {
                __typename?: 'ComponentFieldImageBanner';
                description?: string | null;
                scheme?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                link?: {
                  __typename?: 'ComponentFieldLink';
                  external?: string | null;
                  internal?: string | null;
                  label?: string | null;
                  newtab?: boolean | null;
                  page?: {
                    __typename?: 'PageEntityResponse';
                    data?: {
                      __typename?: 'PageEntity';
                      attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                    } | null;
                  } | null;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockContactUs';
              description?: string | null;
              withCTA?: boolean | null;
              ctaTitle?: string | null;
              ctaLabel?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCta';
              description?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cta: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              };
            }
          | {
              __typename?: 'ComponentBlockFeaturedProductList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              products?: {
                __typename?: 'ProductRelationResponseCollection';
                data: Array<{
                  __typename?: 'ProductEntity';
                  attributes?: {
                    __typename?: 'Product';
                    title?: string | null;
                    tag?: Enum_Product_Tag | null;
                    product: string;
                    characteristics?: string | null;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | { __typename?: 'ComponentBlockLandingHero' }
          | {
              __typename?: 'ComponentBlockProductList';
              productIds: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockPublications';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              publications?: {
                __typename?: 'PressPublicationRelationResponseCollection';
                data: Array<{
                  __typename?: 'PressPublicationEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'PressPublication';
                    title?: string | null;
                    mediaName: string;
                    link: string;
                    article?: string | null;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockQAndA';
              questions?: {
                __typename?: 'QAndARelationResponseCollection';
                data: Array<{
                  __typename?: 'QAndAEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockReviewList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              reviews?: {
                __typename?: 'ReviewRelationResponseCollection';
                data: Array<{
                  __typename?: 'ReviewEntity';
                  attributes?: {
                    __typename?: 'Review';
                    text: string;
                    name?: string | null;
                    position?: string | null;
                    stars?: Enum_Review_Stars | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockText';
              text: string;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTutorial';
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              paragraphs?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              steps?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              link?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTwoColumnsText';
              left?: string | null;
              right?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Error' }
          | null
        >;
        seo: {
          __typename?: 'ComponentSharedSeo';
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          canonicalURL?: string | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                attributes?: {
                  __typename?: 'UploadFile';
                  hash: string;
                  ext?: string | null;
                  mime: string;
                  width?: number | null;
                  height?: number | null;
                  alternativeText?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetNavigationQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetNavigationQuery = {
  __typename?: 'Query';
  renderNavigation: Array<{
    __typename?: 'NavigationItem';
    id: number;
    path?: string | null;
    title: string;
    externalPath?: string | null;
    order: number;
    icon?: string | null;
    parent?: { __typename?: 'NavigationItem'; id: number; path?: string | null } | null;
  } | null>;
};

export type GetPagesQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  publicationState?: PublicationState;
}>;

export type GetPagesQuery = {
  __typename?: 'Query';
  pages?: {
    __typename?: 'PageEntityResponseCollection';
    data: Array<{
      __typename?: 'PageEntity';
      attributes?: {
        __typename?: 'Page';
        title: string;
        slug: string;
        main: Array<
          | {
              __typename?: 'ComponentBlock3StepsCard';
              scheme?: string | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              stepList?: Array<{
                __typename?: 'ComponentFieldStep';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockAccordionList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              items: Array<{
                __typename?: 'ComponentFieldAccordion';
                id: string;
                content: string;
                open?: boolean | null;
                title: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string };
                image?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      hash: string;
                      ext?: string | null;
                      mime: string;
                      width?: number | null;
                      height?: number | null;
                      alternativeText?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null>;
            }
          | {
              __typename?: 'ComponentBlockAvisVerifies';
              count?: number | null;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBlogPosts';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCtaTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              asideCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              posts?: {
                __typename?: 'PostRelationResponseCollection';
                data: Array<{
                  __typename?: 'PostEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Post';
                    title: string;
                    slug?: string | null;
                    introduction: string;
                    redirectUrl: string;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockBrandList';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              brands?: {
                __typename?: 'BrandRelationResponseCollection';
                data: Array<{
                  __typename?: 'BrandEntity';
                  attributes?: {
                    __typename?: 'Brand';
                    name: string;
                    slug: string;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCardBoard';
              scheme?: string | null;
              description?: string | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cardList?: {
                __typename?: 'ComponentFieldCardList';
                scrollOnMobile?: boolean | null;
                cards?: Array<{
                  __typename?: 'ComponentFieldCard';
                  icon?: string | null;
                  image?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      attributes?: {
                        __typename?: 'UploadFile';
                        hash: string;
                        ext?: string | null;
                        mime: string;
                        width?: number | null;
                        height?: number | null;
                        alternativeText?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  paragraph?: {
                    __typename?: 'ComponentFieldParagraph';
                    id: string;
                    text?: string | null;
                    title?: {
                      __typename?: 'ComponentFieldTitle';
                      tag: Enum_Componentfieldtitle_Tag;
                      content: string;
                    } | null;
                  } | null;
                  link?: {
                    __typename?: 'ComponentFieldLink';
                    external?: string | null;
                    internal?: string | null;
                    label?: string | null;
                    newtab?: boolean | null;
                    page?: {
                      __typename?: 'PageEntityResponse';
                      data?: {
                        __typename?: 'PageEntity';
                        attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCategoryList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              categories?: {
                __typename?: 'CategoryRelationResponseCollection';
                data: Array<{
                  __typename?: 'CategoryEntity';
                  attributes?: {
                    __typename?: 'Category';
                    title: string;
                    slug: string;
                    startFromPrice: number;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockContactUs';
              description?: string | null;
              withCTA?: boolean | null;
              ctaTitle?: string | null;
              ctaLabel?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockCta';
              description?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              cta: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              };
            }
          | {
              __typename?: 'ComponentBlockFeaturedProductList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              products?: {
                __typename?: 'ProductRelationResponseCollection';
                data: Array<{
                  __typename?: 'ProductEntity';
                  attributes?: {
                    __typename?: 'Product';
                    title?: string | null;
                    tag?: Enum_Product_Tag | null;
                    product: string;
                    characteristics?: string | null;
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockLandingHero';
              description?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              tags?: Array<{ __typename?: 'ComponentFieldTag'; label: string; icon?: string | null } | null> | null;
              actionBanner?: {
                __typename?: 'ComponentFieldActionBanner';
                title?: string | null;
                description?: string | null;
                scheme?: string | null;
                link?: {
                  __typename?: 'ComponentFieldLink';
                  external?: string | null;
                  internal?: string | null;
                  label?: string | null;
                  newtab?: boolean | null;
                  page?: {
                    __typename?: 'PageEntityResponse';
                    data?: {
                      __typename?: 'PageEntity';
                      attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockProductList';
              productIds: string;
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              mainCta?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockPublications';
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              publications?: {
                __typename?: 'PressPublicationRelationResponseCollection';
                data: Array<{
                  __typename?: 'PressPublicationEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'PressPublication';
                    title?: string | null;
                    mediaName: string;
                    link: string;
                    article?: string | null;
                    logo: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          hash: string;
                          ext?: string | null;
                          mime: string;
                          width?: number | null;
                          height?: number | null;
                          alternativeText?: string | null;
                        } | null;
                      } | null;
                    };
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockQAndA';
              questions?: {
                __typename?: 'QAndARelationResponseCollection';
                data: Array<{
                  __typename?: 'QAndAEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'QAndA'; question: string; answer: string } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockReviewList';
              mainTitle?: {
                __typename?: 'ComponentFieldTitle';
                tag: Enum_Componentfieldtitle_Tag;
                content: string;
              } | null;
              reviews?: {
                __typename?: 'ReviewRelationResponseCollection';
                data: Array<{
                  __typename?: 'ReviewEntity';
                  attributes?: {
                    __typename?: 'Review';
                    text: string;
                    name?: string | null;
                    position?: string | null;
                    stars?: Enum_Review_Stars | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockText';
              text: string;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTutorial';
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              paragraphs?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              steps?: Array<{
                __typename?: 'ComponentFieldParagraph';
                id: string;
                text?: string | null;
                title?: {
                  __typename?: 'ComponentFieldTitle';
                  tag: Enum_Componentfieldtitle_Tag;
                  content: string;
                } | null;
              } | null> | null;
              link?: {
                __typename?: 'ComponentFieldLink';
                external?: string | null;
                internal?: string | null;
                label?: string | null;
                newtab?: boolean | null;
                page?: {
                  __typename?: 'PageEntityResponse';
                  data?: {
                    __typename?: 'PageEntity';
                    attributes?: { __typename?: 'Page'; title: string; slug: string } | null;
                  } | null;
                } | null;
              } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentBlockTwoColumnsText';
              left?: string | null;
              right?: string | null;
              scheme?: string | null;
              title?: { __typename?: 'ComponentFieldTitle'; tag: Enum_Componentfieldtitle_Tag; content: string } | null;
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    hash: string;
                    ext?: string | null;
                    mime: string;
                    width?: number | null;
                    height?: number | null;
                    alternativeText?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'Error' }
          | null
        >;
        seo: {
          __typename?: 'ComponentSharedSeo';
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          canonicalURL?: string | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              attributes?: {
                __typename?: 'UploadFile';
                hash: string;
                ext?: string | null;
                mime: string;
                width?: number | null;
                height?: number | null;
                alternativeText?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                attributes?: {
                  __typename?: 'UploadFile';
                  hash: string;
                  ext?: string | null;
                  mime: string;
                  width?: number | null;
                  height?: number | null;
                  alternativeText?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    }>;
  } | null;
};

export type SharedSeoFragment = {
  __typename?: 'ComponentSharedSeo';
  metaTitle: string;
  metaDescription: string;
  keywords?: string | null;
  metaRobots?: string | null;
  structuredData?: any | null;
  canonicalURL?: string | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      attributes?: {
        __typename?: 'UploadFile';
        hash: string;
        ext?: string | null;
        mime: string;
        width?: number | null;
        height?: number | null;
        alternativeText?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    title: string;
    description: string;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        attributes?: {
          __typename?: 'UploadFile';
          hash: string;
          ext?: string | null;
          mime: string;
          width?: number | null;
          height?: number | null;
          alternativeText?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};
