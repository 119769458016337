<script setup lang="ts">
import { type Component, computed } from 'vue';

import {
  type CmsDynamicZoneBlock,
  CmsDynamicZoneBlockMap,
  type CmsDynamicZoneBlockValid,
  filterValidBlocks,
} from '~/utils/CmsDynamicZoneUtils';

const props = withDefaults(
  defineProps<{
    blocks?: CmsDynamicZoneBlock[];
  }>(),
  { blocks: () => [] },
);

const blockList = computed<CmsDynamicZoneBlockValid[]>(() => filterValidBlocks(props.blocks));

const componentMap = computed<{ component: Component }[]>(() => {
  return blockList.value.map((block) => {
    if (block.__typename === undefined) {
      throw new Error('Unknown block typename');
    }

    return { component: CmsDynamicZoneBlockMap[block.__typename] };
  });
});
</script>

<template>
  <Component
    :is="componentMap[index].component"
    v-for="(block, index) in blockList"
    :id="`${block.__typename}-${index}`"
    :key="`${block.__typename}-${index}`"
    :data="block"
  />
</template>
