<script setup lang="ts">
import type { FieldTitleFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldTitleFragment;
}>();

const tag = computed(() => {
  return props.data.tag ?? 'span';
});
</script>

<template>
  <MarkdownContent v-bind="{ tag }" inline :content="data.content ?? ''" />
</template>
