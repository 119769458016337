<i18n lang="json" locale="fr-FR">
{
  "qAndATitle": "Questions fréquemment posées"
}
</i18n>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import type { BlockQAndAFragment } from '~/types/graphql';

defineProps<{
  data: BlockQAndAFragment;
}>();

const { t } = useI18n();
</script>

<template>
  <LayoutSection class="cms-block-q-and-a">
    <SectionSimpleLayout>
      <template #title>
        <div class="cms-block-q-and-a__title">{{ t('qAndATitle') }}</div>
      </template>

      <template #default>
        <ol v-if="data.questions?.data" class="cms-block-q-and-a__list">
          <li v-for="(question, i) in data.questions.data" :key="question.id ?? i" class="cms-block-q-and-a__item">
            <AccordionItem animated>
              <template #title>
                {{ question.attributes?.question }}
              </template>

              <template #default>
                <MarkdownContent v-if="question.attributes?.answer" :content="question.attributes.answer" />
              </template>
            </AccordionItem>
          </li>
        </ol>
      </template>
    </SectionSimpleLayout>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-q-and-a {
  &__title {
    @include ods.h2;

    margin: 0;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    & + & {
      margin-top: var(--spacing--m);
    }
  }
}
</style>
