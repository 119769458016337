import destr from 'destr';

import { base64Decode } from '~/modules/auth/runtime/utils/stringUtils';

export interface AuthTokenClaims {
  /* eslint-disable @typescript-eslint/naming-convention */
  /**
   * Expiration time
   *
   * Time after which the JWT expires
   */
  exp: number;
  /**
   * Not Before time
   *
   * Time before which the JWT must not be accepted for processing
   */
  nbf: number;
  ver: string;
  /**
   * Issuer
   *
   * Issuer of the JWT
   */
  iss: string;
  /**
   * Subject
   *
   * Subject of the JWT (the user)
   */
  sub: string;
  /**
   * Audience
   *
   * Recipient for which the JWT is intended
   */
  aud: string;
  acr: string;
  /**
   * Issued at time
   *
   * Time at which the JWT was issued; can be used to determine age of the JWT
   */
  iat: number;
  auth_time: number;
  oid: string;
  email: string;
  name: string;
  given_name?: string;
  family_name?: string;
  extension_organizationId: string;
  extension_phone?: string;
  extension_role?: string;
  extension_birthday?: string;
  extension_environment?: string;
  tid: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const TOKEN_COOKIE_NAME = 'token';

/**
 * @param exp Expiration time in seconds
 *
 * @return MaxAge in seconds
 */
export function getMaxAgeFromTokenExp(exp: number): number {
  const expirationInMs = exp * 1000;
  const expirationDate = new Date(expirationInMs);

  return Math.round((expirationDate.getTime() - Date.now()) / 1000);
}

export function parseJwt<T>(token: string): T {
  const [, encodedPayload] = token.split('.');
  const decodedPayload = base64Decode(encodedPayload);

  return destr<T>(decodedPayload);
}
