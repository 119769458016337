import { type FetchOptions } from 'ofetch';

import type { CookieRef } from '#app';
import { AuthModuleError, AuthModuleUnauthorizedApiRequestError } from '~/modules/auth/Error';

export class AuthModuleMissingCookieRequestError extends AuthModuleError {}
export class AuthModuleMissingAnonymousSessionRequestError extends AuthModuleError {}

export const headerAnonymousSession = 'session-id';

export function setRequest(options: FetchOptions, headerKey: string, headerValue: string) {
  const headers = (options.headers ||= {});

  if (Array.isArray(headers)) {
    headers.push([headerKey, headerValue]);
  } else if (headers instanceof Headers) {
    headers.set(headerKey, headerValue);
  } else {
    headers[headerKey] = headerValue;
  }
}

function setAuthenticationHeader(options: FetchOptions, headerValue: string | undefined) {
  if (headerValue) {
    setRequest(options, 'Authorization', `Bearer ${headerValue}`);

    return true;
  }

  return false;
}

export function setSessionId(options: FetchOptions, anonymousSessionId: ComputedRef<string | undefined>) {
  if (anonymousSessionId.value) {
    setRequest(options, headerAnonymousSession, anonymousSessionId.value);

    return true;
  }

  return false;
}

export function createCustomFetch(
  jwt: CookieRef<string | undefined>,
  {
    isJwtRequired = false,
    isAnonymousSessionRequiredIfJwtIsEmpty = false,
    anonymousSessionId,
  }: {
    isJwtRequired?: boolean;
    isAnonymousSessionRequiredIfJwtIsEmpty?: boolean;
    anonymousSessionId?: ComputedRef<string | undefined>;
  } = {},
) {
  return $fetch.create({
    onRequest({ options }) {
      const isJwtAdded = setAuthenticationHeader(options, jwt.value);
      let isAnonymousSessionAdded = false;

      if (isJwtRequired && !isJwtAdded) {
        throw new AuthModuleMissingCookieRequestError('Need jwt to fetch with auth');
      }

      if (anonymousSessionId?.value) {
        isAnonymousSessionAdded = setSessionId(options, anonymousSessionId);
      }

      if (isAnonymousSessionRequiredIfJwtIsEmpty && !isJwtAdded && !isAnonymousSessionAdded) {
        throw new AuthModuleMissingCookieRequestError('Need either JWT or anonymous session to proceed');
      }
    },

    onResponseError({ response }) {
      if (response.status === 401) {
        throw new AuthModuleUnauthorizedApiRequestError('Authentication failed');
      }
    },
  });
}

export default defineNuxtPlugin(() => {
  const { jwt } = useAuth() as { jwt: CookieRef<string> };
  const anonymousSessionId = useAnonymousSessionId();

  return {
    provide: {
      api: {
        authenticatedFetch: createCustomFetch(jwt, { isJwtRequired: true }),
        authenticatedOrAnonymousFetch: createCustomFetch(jwt, {
          anonymousSessionId,
          isAnonymousSessionRequiredIfJwtIsEmpty: true,
        }),
        maybeAuthenticatedFetch: createCustomFetch(jwt),
      },
    },
  };
});
