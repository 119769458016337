import { createI18n } from 'vue-i18n';

import frFR from '../locales/fr-FR.json';

type MessageSchema = typeof frFR;

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n<[MessageSchema], 'fr-FR'>({
    legacy: false,
    globalInjection: true,
    locale: 'fr-FR',
    messages: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fr-FR': frFR,
    },
  });

  vueApp.use(i18n);
});
