import { type Component } from 'vue';

import CmsBlock3StepsCard from '~/components/cms/block/CmsBlock3StepsCard.vue';
import CmsBlockAccordionList from '~/components/cms/block/CmsBlockAccordionList.vue';
import CmsBlockAvisVerifies from '~/components/cms/block/CmsBlockAvisVerifies.vue';
import CmsBlockBlogPosts from '~/components/cms/block/CmsBlockBlogPosts.vue';
import CmsBlockBrandList from '~/components/cms/block/CmsBlockBrandList.vue';
import CmsBlockCardBoard from '~/components/cms/block/CmsBlockCardBoard.vue';
import CmsBlockCategoryListComponent from '~/components/cms/block/CmsBlockCategoryList.vue';
import CmsBlockComparisonTable from '~/components/cms/block/CmsBlockComparisonTable.vue';
import CmsBlockContactUs from '~/components/cms/block/CmsBlockContactUs.vue';
import CmsBlockCta from '~/components/cms/block/CmsBlockCta.vue';
import CmsBlockFeaturedProductList from '~/components/cms/block/CmsBlockFeaturedProductList.vue';
import CmsBlockHeroComponent from '~/components/cms/block/CmsBlockHero.vue';
import CmsBlockLandingHero from '~/components/cms/block/CmsBlockLandingHero.vue';
import CmsBlockProductList from '~/components/cms/block/CmsBlockProductList.vue';
import CmsBlockPublications from '~/components/cms/block/CmsBlockPublications.vue';
import CmsBlockQAndA from '~/components/cms/block/CmsBlockQAndA.vue';
import CmsBlockReviewList from '~/components/cms/block/CmsBlockReviewList.vue';
import CmsBlockTextComponent from '~/components/cms/block/CmsBlockText.vue';
import CmsBlockTutorialComponent from '~/components/cms/block/CmsBlockTutorial.vue';
import CmsBlockTwoColumnsText from '~/components/cms/block/CmsBlockTwoColumnsText.vue';
import type {
  Block3StepsCardFragment,
  BlockAccordionListFragment,
  BlockAvisVerifiesFragment,
  BlockBlogPostsFragment,
  BlockBrandListFragment,
  BlockCardBoardFragment,
  BlockCategoryListFragment,
  BlockComparisonTableFragment,
  BlockContactUsFragment,
  BlockCtaFragment,
  BlockFeaturedProductListFragment,
  BlockHeroFragment,
  BlockLandingHeroFragment,
  BlockProductListFragment,
  BlockPublicationsFragment,
  BlockQAndAFragment,
  BlockReviewListFragment,
  BlockTextFragment,
  BlockTutorialFragment,
  BlockTwoColumnsTextFragment,
  Error as GraphqlError,
} from '~/types/graphql';

export type CmsDynamicZoneBlock =
  | BlockHeroFragment
  | BlockTextFragment
  | BlockTutorialFragment
  | BlockCategoryListFragment
  | BlockBrandListFragment
  | BlockCardBoardFragment
  | BlockPublicationsFragment
  | BlockCtaFragment
  | BlockTwoColumnsTextFragment
  | BlockBlogPostsFragment
  | BlockQAndAFragment
  | Block3StepsCardFragment
  | BlockReviewListFragment
  | BlockContactUsFragment
  | BlockProductListFragment
  | BlockAccordionListFragment
  | BlockAvisVerifiesFragment
  | BlockLandingHeroFragment
  | BlockComparisonTableFragment
  | BlockFeaturedProductListFragment;

export type CmsDynamicZoneBlockValid = Exclude<CmsDynamicZoneBlock, GraphqlError>;
export type CmsDynamicZoneBlockName = NonNullable<CmsDynamicZoneBlockValid['__typename']>;

export const CmsDynamicZoneBlockMap: Record<CmsDynamicZoneBlockName, Component> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  ComponentBlockBrandList: CmsBlockBrandList as Component,
  ComponentBlockCardBoard: CmsBlockCardBoard as Component,
  ComponentBlockCategoryList: CmsBlockCategoryListComponent as Component,
  ComponentBlockHero: CmsBlockHeroComponent as Component,
  ComponentBlockPublications: CmsBlockPublications as Component,
  ComponentBlockText: CmsBlockTextComponent as Component,
  ComponentBlockTutorial: CmsBlockTutorialComponent as Component,
  ComponentBlockTwoColumnsText: CmsBlockTwoColumnsText as Component,
  ComponentBlockCta: CmsBlockCta as Component,
  ComponentBlockQAndA: CmsBlockQAndA as Component,
  ComponentBlock3StepsCard: CmsBlock3StepsCard as Component,
  ComponentBlockBlogPosts: CmsBlockBlogPosts as Component,
  ComponentBlockContactUs: CmsBlockContactUs as Component,
  ComponentBlockReviewList: CmsBlockReviewList as Component,
  ComponentBlockProductList: CmsBlockProductList as Component,
  ComponentBlockFeaturedProductList: CmsBlockFeaturedProductList as Component,
  ComponentBlockAccordionList: CmsBlockAccordionList as Component,
  ComponentBlockAvisVerifies: CmsBlockAvisVerifies as Component,
  ComponentBlockLandingHero: CmsBlockLandingHero as Component,
  ComponentBlockComparisonTable: CmsBlockComparisonTable as Component,
  /* eslint-enable @typescript-eslint/naming-convention */
} as const;

export function isValidBlockList(block: CmsDynamicZoneBlock): block is CmsDynamicZoneBlockValid {
  return block.__typename !== undefined;
}

export function filterValidBlocks(blocks: CmsDynamicZoneBlock[]): CmsDynamicZoneBlockValid[] {
  return blocks.filter((block): block is CmsDynamicZoneBlockValid => isValidBlockList(block));
}
