<i18n lang="json" locale="fr-FR">
{
  "express": "@:{'global.product.deliveryTime.express'}",
  "default": "@:{'global.product.deliveryTime.default'}",
  "refurbished": "@:{'global.product.state.used'}"
}
</i18n>

<script lang="ts" setup>
import { OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import type { Product } from '~/utils/api/catalog/products/utils';

const props = defineProps<Pick<Product, 'deliveryTime' | 'state' | 'hidden'>>();

const { t } = useI18n();

const isRefurbished = computed<boolean>(() => {
  return props.state === 'state_used';
});

const isFastDelivery = computed<boolean>(() => {
  return props.deliveryTime === 'delivery_time_5_10' || props.deliveryTime === 'delivery_time_2_5';
});

const deliveryTimeLabel = computed<string>(() => {
  return props.deliveryTime === 'delivery_time_2_5' ? t('express', { max: 5 }) : t('default', { min: 5, max: 10 });
});
</script>

<template>
  <span class="product-labelling">
    <OdsIcon v-if="hidden" name="visibility-off" />

    <IconLabel v-if="isRefurbished" :label="t('refurbished')" icon="refurbished" scheme="primary" />

    <IconLabel v-else-if="isFastDelivery" :label="deliveryTimeLabel" icon="local-shipping" scheme="primary" />
  </span>
</template>

<style lang="scss">
.product-labelling {
  display: flex;
  align-items: center;
  gap: var(--spacing--xs);
}
</style>
