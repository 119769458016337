export class MathError extends Error {}

export class ScaleError extends MathError {}

export function scale(value: number, from: number, to: number, shouldRoundValue = false): number {
  if (!Number.isFinite(value)) {
    throw new ScaleError('Failed to scale value. Invalid value');
  }

  if (!Number.isFinite(from) || from === 0) {
    throw new ScaleError('Failed to scale value. Invalid from value.');
  }

  if (!Number.isFinite(to)) {
    throw new ScaleError('Failed to scale value. Invalid to value');
  }

  const newValue = (value * to) / from;

  if (shouldRoundValue) {
    return Math.round(newValue);
  }

  return newValue;
}
