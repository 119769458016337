<i18n lang="json" locale="fr-FR">
{
  "stepsTitle": "Louez votre matériel {0}",
  "stepsNumber": "en {number} étapes"
}
</i18n>

<script setup lang="ts">
import { type OdsColorSchemeType, OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import type { LayoutGridProps } from '~/components/layout/LayoutGrid.vue';
import type { SectionTutorialStepperProps } from '~/components/section/SectionTutorialStepper.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockTutorialFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockTutorialFragment;
}>();

type StepList = NonNullable<SectionTutorialStepperProps['steps']>;
type StepListItem = StepList[number];

const steps = computed<StepList>(() => {
  if (!props.data.steps?.length) {
    return [];
  }

  return props.data.steps?.reduce<StepList>((acc, step) => {
    if (isNotEmpty(step)) {
      let title: StepListItem['title'];
      let text: StepListItem['text'];

      if (step.title?.content) {
        title = {
          tag: step.title.tag ?? 'div',
          content: step.title.content,
        };
      }

      if (step.text) {
        text = step.text;
      }

      acc.push({ title, text });
    }

    return acc;
  }, []);
});

const { t } = useI18n();

const hasSteps = computed<boolean>(() => {
  return steps.value.length > 0;
});

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'secondary';
});

const layout = computed<LayoutGridProps['layout']>(() => {
  return hasSteps.value ? '2of3-1of3' : '1of1';
});
</script>

<template>
  <LayoutSection>
    <LayoutGrid class="cms-block-tutorial" :layout>
      <SectionTutorialCta :scheme>
        <template v-if="data?.title" #title>
          <CmsFieldTitle class="cms-block-tutorial__title" :data="data.title" />
        </template>

        <template #default>
          <!-- TODO: Create <CollapsibleContainer /> for mobile  -->
          <div class="cms-block-tutorial__row">
            <div
              v-for="(paragraph, index) in data?.paragraphs"
              :key="`cms-block-tutorial-col--${index}`"
              class="cms-block-tutorial__col"
            >
              <CmsFieldParagraph v-if="paragraph" :data="paragraph" />
            </div>
          </div>
        </template>

        <template v-if="data?.image?.data" #image>
          <CmsFieldImage class="cms-block-tutorial__image" :data="data?.image" :width="180" />
        </template>

        <template v-if="data.link" #cta>
          <CmsFieldLink :data="data.link">
            <template #append>
              <OdsIcon name="arrow-forward" />
            </template>
          </CmsFieldLink>
        </template>
      </SectionTutorialCta>

      <SectionTutorialStepper v-if="hasSteps" :steps="steps">
        <template #title>
          <i18n-t keypath="stepsTitle">
            <strong>{{ t('stepsNumber', { number: steps.length }) }}</strong>
          </i18n-t>
        </template>
      </SectionTutorialStepper>
    </LayoutGrid>
  </LayoutSection>
</template>

<style lang="scss">
$cms-block-tutorial-breakpoint: 700px;

.cms-block-tutorial {
  &__row {
    flex-grow: 1;
    display: flex;
    gap: var(--spacing--m);
    flex-direction: column;

    @container card (width > #{$cms-block-tutorial-breakpoint}) {
      gap: var(--spacing--l);
      flex-direction: row;
    }
  }

  &__image {
    width: 100%;
  }

  &__content,
  &__col {
    flex: 1;
  }
}
</style>
