<script lang="ts" setup>
import type { HtmlTagName } from '@onliz/design-system-vue';

import type { ProductCardProps } from '~/components/ProductCard.vue';
import { getProductTag } from '~/lib/cache-utils';
import type { BlockProductListFragment } from '~/types/graphql';
import { findProductById } from '~/utils/api/catalog/products';
import type { ProductId } from '~/utils/api/catalog/products/utils';

const props = withDefaults(
  defineProps<{
    id: string;
    data: BlockProductListFragment;
    productTitleTag?: HtmlTagName;
  }>(),
  {
    productTitleTag: 'h3',
  },
);

const ids = computed<ProductId[]>(() => {
  return props.data.productIds?.split(',') ?? [];
});

useRouteCache((helper) => {
  helper.addTags(ids.value.map((productId) => getProductTag(productId)));
});

const { data: catalogProducts } = await useAsyncData(`findProductById:${ids.value.join('+')}`, () =>
  findProductById(...ids.value),
);

const products = computed<ProductCardProps[]>(() => {
  return (
    catalogProducts.value?.map((product) => ({
      ...product,
      tag: props.productTitleTag,
    })) ?? []
  );
});

const { trackEvent } = useTracking();
const onProductClick = (product: ProductCardProps) => {
  trackEvent('click_selected_equipment', product.slug);
};
</script>

<template>
  <ProductList
    :id
    :products="products"
    :title="data.mainTitle?.content"
    :title-tag="data.mainTitle?.tag"
    :cta="data.mainCta ?? undefined"
    :on-product-card-click="onProductClick"
  />
</template>
