<script setup lang="ts">
import { OdsCard, OdsClickableElement } from '@onliz/design-system-vue';

import { isNotEmpty } from '~/lib/strapi-utils';
import { type BlockPublicationsFragment } from '~/types/graphql';
import type { LinkTarget } from '~/utils/MainNavUtils';

type CmsBlockPublicationsProps = {
  data: BlockPublicationsFragment;
};

const props = defineProps<CmsBlockPublicationsProps>();

type PublicationList = {
  id: string;
  src: string;
  to: string;
  title?: string;
  alt: string;
  article?: string;

  target: LinkTarget;
}[];

const { getAssetPath } = useCmsAsset();

const publications = computed<PublicationList>(() => {
  if (props.data.publications?.data.length) {
    return props.data.publications.data.reduce<PublicationList>((acc, item) => {
      if (isNotEmpty(item.attributes) && item.attributes.logo && item.id) {
        const source = getAssetPath(item.attributes.logo);

        if (source) {
          acc.push({
            id: item.id,
            title: item.attributes.title ?? undefined,
            alt: item.attributes.mediaName,
            to: item.attributes.link,
            target: '_blank',
            src: source,
            article: item.attributes.article ?? undefined,
          });
        }
      }

      return acc;
    }, []);
  }

  return [];
});

const logoSize = ImageSizeMap.logo;
</script>

<template>
  <LayoutSection>
    <template v-if="publications.length > 0" #default>
      <div class="cms-block-publications">
        <CmsFieldTitle v-if="data.title" class="cms-block-publications__title" :data="data.title" />

        <ul class="cms-block-publications__list">
          <li
            v-for="{ to, title, target, alt, src, article, id } in publications"
            :key="id"
            class="cms-block-publications__item"
          >
            <OdsClickableElement class="cms-block-publications__link" v-bind="{ title, to, target }">
              <OdsCard with-shadow class="cms-block-publications__odscard">
                <div v-if="src" class="cms-block-publications__logo">
                  <ImageElement
                    class="cms-block-publications__logo-el"
                    fit="contain"
                    :src
                    :alt
                    :width="logoSize.width"
                    :height="logoSize.height"
                  />
                </div>

                <img v-else class="cms-block-publications__logo" v-bind="{ src, alt }" />

                <div v-if="article" class="cms-block-publications__article">
                  <MarkdownContent :content="article" class="cms-block-publications__text" />
                </div>
              </OdsCard>
            </OdsClickableElement>
          </li>
        </ul>
      </div>
    </template>
  </LayoutSection>
</template>

<style lang="scss">
@use '~/assets/scss/function-utils' as function;
@use '~/assets/scss/mixins-utils' as mixins;
@use '@onliz/design-system-scss' as ods;

.cms-block-publications {
  container: cms-block-publications-container / inline-size;

  &__title {
    @include ods.h2;

    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__list {
    gap: var(--spacing--m);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 0 auto;
    align-items: center;

    @container cms-block-publications-container (width >= #{ods.$breakpoint-s}) {
      flex-direction: row;
      align-items: stretch;
    }
  }

  &__item {
    flex: 1;
    max-width: ods.px-to-em(450px);

    @container cms-block-publications-container (width >= #{ods.$breakpoint-s}) {
      max-width: ods.px-to-em(290px);
    }
  }

  &__link {
    display: block;
    height: 100%;
  }

  &__odscard {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
    align-items: center;

    @include mixins.card-hover-transition;
  }

  &__logo {
    width: 100%;
    max-width: ods.px-to-em(145px);
    aspect-ratio: 2.7;
  }

  &__logo-el {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__article {
    text-align: center;
  }

  &__text {
    p {
      display: inline;
    }

    &::before {
      content: '“';
    }

    &::after {
      content: '”';
    }
  }
}
</style>
