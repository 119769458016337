<script setup lang="ts">
import 'swiper/css';

import { type HtmlTagName, OdsCard, OdsIconButton } from '@onliz/design-system-vue';

import { SwiperNavigation, SwiperScrollbar } from '#imports';

export type SectionTutorialStepperProps = {
  title?: string;
  steps?: { title?: { tag?: HtmlTagName; content: string }; text?: string }[];
};

withDefaults(defineProps<SectionTutorialStepperProps>(), {
  title: undefined,
  steps: () => [],
});

const isSliderReady = ref(false);

const onSwiperInit = () => {
  isSliderReady.value = true;
};
</script>

<template>
  <OdsCard class="tutorial-stepper" :class="{ 'tutorial-stepper--ready': isSliderReady }" with-shadow>
    <div v-if="$slots.title || title" class="tutorial-stepper__title">
      <slot name="title">{{ title }}</slot>
    </div>

    <div v-if="steps.length > 0" class="tutorial-stepper__body">
      <div class="tutorial-stepper__wrapper">
        <Swiper
          :modules="[SwiperNavigation, SwiperScrollbar]"
          :slides-per-view="1"
          :navigation="{
            nextEl: '.tutorial-stepper__navigation--next',
            prevEl: '.tutorial-stepper__navigation--prev',
          }"
          class="tutorial-stepper__list"
          @init="onSwiperInit"
        >
          <SwiperSlide v-for="(step, index) in steps" :key="index">
            <div class="tutorial-stepper__item">
              <div class="tutorial-stepper__number"></div>

              <div class="tutorial-stepper__wrapper">
                <MarkdownContent
                  v-if="step.title?.content"
                  :tag="step.title.tag"
                  :content="step.title?.content"
                  inline
                  class="tutorial-stepper__subtitle"
                />

                <MarkdownContent v-if="step.text" :content="step.text" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="tutorial-stepper__controls">
        <OdsIconButton icon-name="arrow-back" class="tutorial-stepper__navigation tutorial-stepper__navigation--prev" />

        <OdsIconButton
          icon-name="arrow-forward"
          class="tutorial-stepper__navigation tutorial-stepper__navigation--next"
        />
      </div>
    </div>
  </OdsCard>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.tutorial-stepper {
  $block-selector: &;

  display: flex;
  flex-direction: column;
  flex: 1;

  &__wrapper {
    flex: 1;
  }

  &__list {
    counter-reset: step-list;
  }

  &__title {
    @include ods.h4;
  }

  &__subtitle {
    @include ods.h6;
  }

  &__number {
    border-radius: 100%;
    font-weight: bold;
    color: var(--scheme--color-emphasis);
    background-color: var(--scheme--color);
    width: 2rem;
    height: 2rem;
    flex: 0 0 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
      counter-increment: step-list;
      content: counter(step-list);
      display: block;
      line-height: 1;
      font-size: 1.25em;
    }
  }

  &__item {
    align-items: flex-start;
    gap: var(--spacing--s);
    display: flex;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing--s);
  }

  &__navigation {
    background: var(--scheme--background);
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    visibility: hidden;

    #{$block-selector}--ready & {
      visibility: visible;
    }
  }
}
</style>
