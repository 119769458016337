<i18n lang="json" locale="fr-FR">
{
  "default": {
    "title": "Oups, il semblerait que quelque chose ne fonctionne pas. Pas de panique, notre équipe est sur le coup !",
    "pageTitle": "ONLIZ {'|'} {statusCode}"
  },
  "categories": {
    "title": {
      "text": "Onliz, La marketplace de {0}",
      "em": "leasing 100% dédiée aux professionnels"
    }
  }
}
</i18n>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import type { NuxtError } from '#app';
import IllustrationError from '~/assets/images/illustration-error.svg?component';
import ErrorNeedHelp from '~/components/error/ErrorNeedHelp.vue';

const props = defineProps<{
  error: NuxtError;
}>();

const { t } = useI18n();
const { trackPageView } = useTracking();

const statusCode = toRef(() => props.error?.statusCode);

useHead({
  title: t('default.pageTitle', { statusCode: statusCode.value }),
});

trackPageView(t('default.pageTitle', { statusCode: statusCode.value ?? 500 }), { errorType: statusCode.value ?? 500 });
</script>

<template>
  <NuxtLayout name="landing">
    <div class="default-error">
      <div class="default-error__content">
        <div class="default-error__title">
          {{ t('default.title') }}
        </div>

        <IllustrationError class="default-error__image" />
      </div>

      <div class="default-error__categories">
        <i18n-t keypath="categories.title.text" tag="p" class="default-error__categories-title">
          <strong>{{ t('categories.title.em') }}</strong>
        </i18n-t>

        <ErrorCategoryList />
      </div>

      <ErrorNeedHelp />
    </div>
  </NuxtLayout>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.default-error {
  --default-error--padding: var(--spacing--m);

  max-width: calc(1130px + var(--default-error--padding) * 2);
  padding: 0 var(--default-error--padding);
  margin: var(--spacing--m) auto;
  container: default-error-container / inline-size;

  @media screen and (min-width: ods.$breakpoint-s) {
    --default-error--padding: var(--spacing--l);
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    margin: 0 auto;
  }

  &__image {
    max-width: 350px;
    width: 100%;
  }

  &__title {
    @include ods.h2;
  }

  &__categories {
    display: flex;
    gap: var(--spacing--s);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--spacing--l) 0 0 0;

    @container default-error-container (width > 600px) {
      flex-direction: row;
      gap: var(--spacing--m);
    }
  }

  &__categories-title {
    @include ods.h4;

    margin: 0;
    display: block;
    flex: 1;
    text-align: center;

    @container default-error-container (width > 600px) {
      text-align: left;
    }
  }
}
</style>
