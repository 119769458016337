<i18n lang="json" locale="fr-FR">
{
  "default": {
    "title": "La page recherchée n'existe pas",
    "button": "Aller à la page d'accueil",
    "home": "Accueil",
    "pageTitle": "ONLIZ {'|'} 404"
  }
}
</i18n>

<script lang="ts" setup>
import { OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import IllustrationError from '~/assets/images/illustration-looking-magnifying-glass.svg?component';
import {
  CACHE_TTL_S_1_HOUR,
  CACHE_TTL_S_12_HOURS,
  CACHE_TTL_S_15_MIN,
  getProductsTag,
  NOT_FOUND_PAGE_TAG,
} from '~/lib/cache-utils';
import { filterMaybeList, getProductIdListFromCmsZone } from '~/lib/strapi-utils';
import Get404PageGQL from '~/queries/404-page.gql';
import { type FieldLinkFragment, type Get404PageQuery, type Get404PageQueryVariables } from '~/types/graphql';

const { publicationState } = useCmsPreviewMode();
const { trackPageView } = useTracking();
const { t } = useI18n();

const graphqlKey = 'page:page404:ggl';

const queryVariables = computed<Get404PageQueryVariables>(() => ({
  publicationState: publicationState.value,
}));

const { data: page404, error } = await useCachedAsyncQuery<Get404PageQuery>(graphqlKey, Get404PageGQL, queryVariables, {
  deep: false,
  serverCacheTags: (data) => {
    return [
      NOT_FOUND_PAGE_TAG,
      ...getProductsTag(getProductIdListFromCmsZone(data.page404?.data?.attributes?.main as CmsDynamicZoneBlock[])),
    ];
  },
  serverMaxAge: CACHE_TTL_S_12_HOURS,
  clientMaxAge: CACHE_TTL_S_15_MIN,
});
const data = computed(() => {
  return page404.value?.page404?.data?.attributes;
});

const filteredBlocks = computed<CmsDynamicZoneBlock[]>(() => {
  if (data.value?.main) {
    return filterMaybeList(data.value.main) as CmsDynamicZoneBlock[];
  }

  return [];
});

const productIds = getProductIdListFromCmsZone(filteredBlocks.value);
const productTags = getProductsTag(productIds);

if (error.value) {
  throw createError({
    message: `Fail to load page 404 data (${error.value.toString()})`,
    statusCode: 500,
    cause: error.value,
  });
}

const defaultCTA: FieldLinkFragment = {
  label: t('default.button'),
  page: {
    data: {
      attributes: {
        title: t('default.home'),
        slug: '',
      },
    },
  },
};

useHead({
  title: t('default.pageTitle'),
});

trackPageView(t('default.pageTitle'), { errorType: 404 });
</script>

<template>
  <RenderCacheable
    :cache-tags="[NOT_FOUND_PAGE_TAG, ...productTags]"
    :max-age="CACHE_TTL_S_1_HOUR"
    :async-data-keys="[`findProductById:${productIds.join('+')}`]"
  >
    <NuxtLayout name="default">
      <LayoutSection class="not-found-error" scheme="primary">
        <div class="not-found-error__message">
          <IllustrationError class="not-found-error__image" />

          <div class="not-found-error__content">
            <div class="not-found-error__title">
              {{ data?.title ?? t('default.title') }}
            </div>

            <CmsFieldLink class="not-found-error__button" :data="data?.cta ?? defaultCTA">
              <template #append>
                <OdsIcon name="arrow-forward" />
              </template>
            </CmsFieldLink>
          </div>
        </div>
      </LayoutSection>

      <CmsDynamicZone v-if="filteredBlocks.length > 0" :blocks="filteredBlocks" />
    </NuxtLayout>
  </RenderCacheable>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.not-found-error {
  &__message {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--xs);
    padding: var(--spacing--m);
    border: 1px solid var(--scheme--color);
    border-radius: var(--radius--l);

    // Ajouter plus petit gap pour les écrans plus petits
    @media screen and (min-width: ods.$breakpoint-s) {
      gap: var(--spacing--m);
    }

    @media screen and (min-width: ods.$breakpoint-m) {
      flex-direction: row;
      align-items: center;
      padding: 0 var(--spacing--xl);
      gap: var(--spacing--xl);
    }
  }

  &__image {
    max-width: 165px;

    @media screen and (min-width: ods.$breakpoint-m) {
      align-self: flex-end;
    }
  }

  &__content {
    padding-block: var(--spacing--m);
  }

  &__title {
    @include ods.h1;

    margin-bottom: var(--spacing--xs);
  }
}
</style>
