export function getOrSet<T>(storage: Map<string, unknown>, key: string, callback: () => T): T {
  let value = storage.get(key);

  if (value === undefined) {
    value = callback();

    storage.set(key, value);
  }

  return value as T;
}
