export class NumberError extends Error {}

export function toNumber(value: string | number): number {
  const output = typeof value === 'number' ? value : Number.parseInt(value, 10);

  if (Number.isNaN(output)) {
    throw new NumberError(
      `Failed to convert ${typeof value === 'string' ? `"${value}"` : value.toString()} to a valid number`,
    );
  }

  return output;
}
