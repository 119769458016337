<script setup lang="ts" generic="T">
import { OdsIconButton } from '@onliz/design-system-vue';
import { type SwiperOptions } from 'swiper/types';

import { SwiperNavigation, SwiperScrollbar } from '#imports';

const props = withDefaults(
  defineProps<{
    id: string;
    overflowOnMobile?: boolean;
    breakpoints?: SwiperOptions['breakpoints'];
    slides?: T[];
    slideClassName?: string;
  }>(),
  {
    breakpoints: () => {
      return {
        '480': {
          slidesPerView: 2,
        },
        '630': {
          slidesPerView: 3,
        },
        '850': {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        '1024': {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        '1280': {
          slidesPerView: 6,
          spaceBetween: 20,
        },
      };
    },
    slides: () => [],
    slideClassName: undefined,
  },
);

const isSliderReady = ref(false);
const isLocked = ref(false);

const onInit = () => {
  isSliderReady.value = true;
};

const onLock = () => {
  isLocked.value = true;
};

const onUnlock = () => {
  isLocked.value = false;
};

const sliderId = computed<string>(() => {
  return `slider-el-${props.id}`;
});
</script>

<template>
  <div
    class="slider-container"
    :class="{
      'slider-container--overflow-on-mobile': overflowOnMobile,
      'slider-container--ready': isSliderReady,
    }"
  >
    <Swiper
      :modules="[SwiperNavigation, SwiperScrollbar]"
      :space-between="10"
      :slides-per-view="overflowOnMobile ? 1.4 : 1"
      :breakpoints
      :navigation="{
        nextEl: `#${sliderId}-next`,
        prevEl: `#${sliderId}-prev`,
        navigationDisabledClass: 'slider-container--navigation-disabled',
      }"
      :scrollbar="{
        el: `#${sliderId}-bar`,
        dragSize: 'auto',
        draggable: true,
      }"
      class="slider-container__element"
      @lock="onLock"
      @unlock="onUnlock"
      @init="onInit"
    >
      <SwiperSlide
        v-for="(slide, index) in slides"
        :key="index"
        class="slider-container__slide"
        :class="slideClassName"
      >
        <slot :slide="slide" :index="index"></slot>
      </SwiperSlide>
    </Swiper>

    <div v-show="!isLocked" class="slider-container__navigation">
      <OdsIconButton
        :id="`${sliderId}-prev`"
        class="slider-container__nav-button slider-container__nav-button--prev"
        icon-name="arrow-back"
      />

      <div class="slider-container__scrollbar">
        <div :id="`${sliderId}-bar`" class="slider-container__scrollbar-el"></div>
      </div>

      <OdsIconButton
        :id="`${sliderId}-next`"
        class="slider-container__nav-button slider-container__nav-button--next"
        icon-name="arrow-forward"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.slider-container {
  $slider-container: &;

  visibility: hidden;

  &--ready {
    visibility: visible;
  }

  &--overflow-on-mobile {
    @media screen and (width <= 480px) {
      overflow: visible;

      #{$slider-container}__element {
        margin: 0 calc(var(--spacing--s) * -1);
        padding: 0 calc(var(--spacing--s) * 1);
      }
    }
  }

  &--navigation-disabled {
    #{$slider-container}__navigation {
      display: none;
    }
  }

  &__element.swiper {
    overflow-y: visible;
  }

  &__navigation {
    display: flex;
    gap: var(--spacing--m);
    align-items: center;
    margin: var(--spacing--m) 0 0 0;
  }

  &__scrollbar {
    flex: 1;
  }

  &__slide.swiper-slide {
    height: auto;
  }

  &__nav-button {
    background-color: var(--scheme--card-background);
  }

  .ods-color-scheme--default & {
    --scheme--card-background: var(--greyscale--dark-grey-light);
  }

  &__scrollbar-el {
    height: ods.px-to-em(4px);
    border-radius: ods.px-to-em(4px);
    background-color: var(--scheme--card-background);
    overflow: hidden;

    & > .swiper-scrollbar-drag {
      background-color: var(--semantic--success);
      height: 100%;
      border-radius: ods.px-to-em(4px);
    }
  }
}
</style>
