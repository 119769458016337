<script lang="ts" setup>
import { OdsColorScheme, type OdsColorSchemeType, OdsIcon } from '@onliz/design-system-vue';

import BrandMark from '~/assets/images/brand-mark.svg?component';
import type { FieldImageBannerFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldImageBannerFragment;
  width: number;
  height: number;
}>();

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'dark';
});
</script>

<template>
  <OdsColorScheme :scheme class="field-image-banner">
    <CmsFieldImage
      v-if="data.image?.data?.attributes?.hash"
      class="field-image-banner__image"
      :data="data.image"
      :width
      :height
    />

    <div class="field-image-banner__content">
      <div class="field-image-banner__inner">
        <div class="field-image-banner__background">
          <BrandMark />
        </div>

        <div class="field-image-banner__wrapper">
          <CmsFieldTitle v-if="data.title?.content" class="field-image-banner__title" :data="data.title" />

          <p v-if="data.description" class="field-image-banner__description">{{ data.description }}</p>

          <CmsFieldLink v-if="data.link?.label" class="field-image-banner__link" :data="data.link">
            <template #append>
              <OdsIcon name="arrow-forward" />
            </template>
          </CmsFieldLink>
        </div>
      </div>
    </div>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.field-image-banner {
  border-radius: var(--radius--l);
  overflow: hidden;
  display: grid;
  grid-template-areas: 'layer';
  background-color: var(--scheme--color-emphasis);
  container: field-image-banner / inline-size;

  $field-image-banner-corner-height: var(--spacing--l);
  $field-image-banner-breakpoint: ods.$breakpoint-m;

  &__image,
  &__content {
    grid-area: layer;
  }

  &__image {
    align-self: stretch;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    align-self: self-end;
    padding-bottom: 17%;

    @container field-image-banner (width >= #{$field-image-banner-breakpoint}) {
      padding-bottom: 10%;
    }

    &::before {
      display: block;
      content: '';
      width: 100%;
    }
  }

  &__title {
    @include ods.h4;

    margin: 0;
  }

  &__inner {
    display: grid;
    grid-template-areas: 'inner';
  }

  &__description {
    margin: 0 0 var(--spacing--s) 0;
  }

  &__background {
    grid-area: inner;
    width: 100%;
    height: 100%;
    align-self: stretch;
    position: relative;

    svg {
      height: 160%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -62%);
      fill: var(--scheme--background);

      @container field-image-banner (width >= #{$field-image-banner-breakpoint}) {
        height: 250%;
      }
    }
  }

  &__wrapper {
    align-self: center;
    grid-area: inner;
    max-width: 450px;
    margin: 0 auto;
    padding: 0 var(--spacing--m);
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
    align-items: center;
    text-align: center;
    z-index: 1;

    @container field-image-banner (width >= #{$field-image-banner-breakpoint}) {
      max-width: 600px;
    }
  }
}
</style>
