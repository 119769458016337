import { type AzureMediaStorageModifiers, AzureMediaStorageProviderName } from '~/providers/azureMediaStorageProvider';
import type { FieldImageFragment } from '~/types/graphql';
import { scale } from '~/utils/MathUtils';

function getAssetPath(image: NonNullable<FieldImageFragment>): string | undefined {
  if (image.data?.attributes?.hash && image.data.attributes.ext) {
    return `assets/${image.data.attributes.hash}${image.data.attributes.ext}`;
  }

  return undefined;
}

function getAssetSize(
  image: NonNullable<FieldImageFragment>,
  expectedSize: { width?: number; height?: number },
): { width: number; height: number } | undefined {
  if (expectedSize.width && expectedSize.height) {
    return { width: expectedSize.width, height: expectedSize.height };
  }

  if (image.data?.attributes?.height && image.data.attributes.width) {
    const orginalWidth = toNumber(image.data.attributes.width);
    const orginalHeight = toNumber(image.data.attributes.height);

    if (expectedSize.width && !expectedSize.height) {
      return {
        width: expectedSize.width,
        height: scale(orginalHeight, orginalWidth, expectedSize.width, true),
      };
    }

    if (expectedSize.height && !expectedSize.width) {
      return {
        height: expectedSize.height,
        width: scale(orginalWidth, orginalHeight, expectedSize.height, true),
      };
    }
  }

  return;
}

export default function useCmsAsset() {
  const getAssetUrl = (
    image: FieldImageFragment | null | undefined,
    modifiers: Partial<AzureMediaStorageModifiers> = {},
  ): string | undefined => {
    if (image) {
      const relativePath = getAssetPath(image);

      if (relativePath) {
        const img = useImage();
        const size = getAssetSize(image, { width: modifiers.width, height: modifiers.height }) ?? {};

        return img(
          relativePath,
          { ...modifiers, ...size, container: 'cms' },
          {
            provider: AzureMediaStorageProviderName,
          },
        );
      }
    }

    return;
  };

  return {
    getAssetPath,
    getAssetSize,
    getAssetUrl,
  };
}
