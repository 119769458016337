<template>
  <LayoutSection scheme="primary" class="section-landing-hero">
    <LayoutGrid layout="1of2-1of2">
      <div class="section-landing-hero__content">
        <slot name="default"></slot>
      </div>

      <div class="section-landing-hero__image">
        <slot name="image"></slot>
      </div>
    </LayoutGrid>
  </LayoutSection>
</template>
