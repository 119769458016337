import { stripTrailingSlash } from '~/lib/url-utils';

export default defineNuxtRouteMiddleware((to) => {
  // No need to trigger a redirection in the server-side as the reverse proxy on top of Nuxt already handles this for us
  // In local environment, a blank component is rendered before navigating to the targeted page
  if (process.server) {
    return;
  }

  // Navigate to the targeted page with a full page reload (in order to load Nuxt@2)
  if (to.matched.at(0)?.meta.isLegacy) {
    const runtimeConfig = useRuntimeConfig();
    const legacyUrl = stripTrailingSlash(runtimeConfig.public.legacyUrl);

    return navigateTo(`${legacyUrl}${to.fullPath}`, { external: true });
  }
});
