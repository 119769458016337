<script setup lang="ts">
import { isIconName } from '@onliz/design-system-vue';

import type { IconTagProps } from '~/components/IconTag.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockHeroFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockHeroFragment;
}>();

const tagList = computed<IconTagProps[]>(() => {
  if (!props.data.tags?.length) {
    return [];
  }

  return props.data.tags.reduce<IconTagProps[]>((acc, tag) => {
    if (isNotEmpty(tag)) {
      acc.push({
        label: tag.label ?? undefined,
        icon: tag.icon && isIconName(tag.icon) ? tag.icon : undefined,
      });
    }

    return acc;
  }, []);
});
</script>

<template>
  <SectionHero :description="data.description ?? undefined" :tags="tagList">
    <template #title>
      <CmsFieldTitle v-if="data?.title" :data="data.title" />
    </template>

    <template #image>
      <CmsFieldImage v-if="data.image" fit="inside" :data="data.image" :width="335" rounded />
    </template>
  </SectionHero>
</template>
