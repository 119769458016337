<script lang="ts" setup>
import { type OdsButtonScheme, type OdsColorSchemeType, OdsIcon } from '@onliz/design-system-vue';

import type { Block3StepsCardFragment } from '~/types/graphql';

const props = defineProps<{
  data: Block3StepsCardFragment;
}>();

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'secondary';
});

const ctaScheme = computed<OdsButtonScheme>(() => {
  return scheme.value === 'dark' ? 'light' : 'default';
});

const getSlotName = (index: number): string => `step-${index + 1}`;
</script>

<template>
  <LayoutSection>
    <SectionStepsCard :scheme class="cms-block-3-steps-card">
      <template v-if="data.mainTitle" #title>
        <CmsFieldTitle :data="data.mainTitle" class="cms-block-3-steps-card__title" />
      </template>

      <template v-for="(step, i) in data.stepList" :key="step?.id ?? i" #[getSlotName(i)]>
        <CmsFieldStep v-if="step" :data="step" />
      </template>

      <template v-if="data.mainCta?.label" #cta>
        <CmsFieldLink :data="data.mainCta" class="cms-block-3-steps-card__cta" variant="outlined" :scheme="ctaScheme">
          <template #append>
            <OdsIcon name="arrow-forward" />
          </template>
        </CmsFieldLink>
      </template>
    </SectionStepsCard>
  </LayoutSection>
</template>
