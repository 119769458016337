import { type Ref, ref } from 'vue';

import { getAlgoliaKey } from '~/utils/api/catalog/algolia';

export class AlgoliaApiKeyError extends Error {}

export async function useAlgoliaApiKey(): Promise<Ref<string | undefined>> {
  const apiKey = useState('algoliaApiKey', () => ref<string>());

  if (!apiKey.value) {
    const result = await getAlgoliaKey();

    if (!result) {
      throw new AlgoliaApiKeyError('Algolia key is empty');
    }

    apiKey.value = result;
  }

  return apiKey;
}
