<script setup lang="ts">
import { OdsIcon } from '@onliz/design-system-vue';
import { type OdsColorSchemeType } from '@onliz/design-system-vue';

import type { BlockCtaFragment } from '~/types/graphql';

type CmsBlockCtaProps = {
  data: BlockCtaFragment;
};

const props = defineProps<CmsBlockCtaProps>();

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'dark';
});
</script>

<template>
  <LayoutSection class="cms-block-cta">
    <SectionCta :scheme>
      <template v-if="data.title" #title>
        <CmsFieldTitle :data="data.title" class="cms-block-cta__title" />
      </template>

      <template v-if="data.description" #description>
        <MarkdownContent :content="data.description" />
      </template>

      <template v-if="data.cta" #default>
        <CmsFieldLink :data="data.cta">
          <template #append>
            <OdsIcon name="arrow-forward" />
          </template>
        </CmsFieldLink>
      </template>
    </SectionCta>
  </LayoutSection>
</template>
