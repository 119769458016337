import type { Ref } from 'vue';

import type { FieldLinkFragment } from '~/types/graphql';

export default function useCmsLink(data: Ref<FieldLinkFragment | null>) {
  const label = computed<string | undefined>(() => data.value?.label ?? undefined);

  const to = computed<string | undefined>(
    () => data.value?.page?.data?.attributes?.slug ?? data.value?.external ?? data.value?.internal ?? undefined,
  );

  const target = computed<LinkTarget>(() => (data.value?.newtab ? '_blank' : '_self'));

  return { label, to, target };
}
