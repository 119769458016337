export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"charset":"utf-8"},{"key":"theme-color","name":"theme-color","content":"#f5f7fb"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"32x32"},{"rel":"icon","href":"/icon.svg","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"title":"ONLIZ | 1ère Marketplace dédiée au leasing d'équipement professionnel","htmlAttrs":{"lang":"fr-FR"},"viewport":"width=device-width, initial-scale=1, viewport-fit=cover"}

export const appBuildAssetsDir = "_nuxt3"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false