<script setup lang="ts">
import { OdsButton, type OdsButtonScheme, type OdsButtonVariant, OdsClickableElement } from '@onliz/design-system-vue';

import type { FieldLinkFragment } from '~/types/graphql';

const props = withDefaults(
  defineProps<{
    data: FieldLinkFragment;
    scheme?: OdsButtonScheme;
    variant?: OdsButtonVariant;
    rawLink?: boolean;
    fullWidth?: boolean;
  }>(),
  {
    scheme: 'default',
    variant: 'default',
  },
);

const { to, label, target } = useCmsLink(toRef(() => props.data));
</script>

<template>
  <OdsClickableElement v-if="rawLink" v-bind="{ to, target, label }" />

  <OdsButton v-else v-bind="{ label, to, target, scheme, variant, fullWidth }">
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend"></slot>
    </template>

    <template v-if="$slots.append" #append>
      <slot name="append"></slot>
    </template>
  </OdsButton>
</template>
