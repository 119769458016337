<script lang="ts" setup>
import type { BlockAvisVerifiesFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockAvisVerifiesFragment;
}>();

const count = computed<number>(() => {
  return props.data.count ?? 4;
});
</script>

<template>
  <LayoutSection class="cms-block-avis-verifies" scheme="primary">
    <div v-if="data.mainTitle" class="cms-block-avis-verifies__title">
      <CmsFieldTitle :data="data.mainTitle" />
    </div>

    <WidgetAvisVerifies :count />
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-avis-verifies {
  &__title {
    @include ods.h3;

    text-align: center;
  }
}
</style>
