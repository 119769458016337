import validate from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/pages/runtime/validate.js";
import anonymous_45session_45global from "/agent/_work/13/s/app/middleware/anonymous-session.global.ts";
import category_45redirect_45global from "/agent/_work/13/s/app/middleware/category-redirect.global.ts";
import legacy_45routing_45global from "/agent/_work/13/s/app/middleware/legacy-routing.global.ts";
import manifest_45route_45rule from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  anonymous_45session_45global,
  category_45redirect_45global,
  legacy_45routing_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}