<i18n lang="json" locale="fr-FR">
{
  "goToProductPage": "Voir en détail le produit {name}"
}
</i18n>

<script lang="ts" setup>
import { type HtmlTagName, OdsCard, OdsClickableElement } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

import type { Product } from '~/utils/api/catalog/products/utils';

export type ProductCardProps = Product & { tag?: HtmlTagName };

const props = withDefaults(defineProps<ProductCardProps>(), {
  tag: 'span',
  onProductCardClick: undefined,
});

const { t } = useI18n();

const title = computed<string>(() => {
  return t('goToProductPage', { name: props.name });
});

const to = computed(() => {
  return helpers.route({
    name: 'product-slug-sku-productId',
    params: { slug: props.slug, productId: props.id },
  });
});

const { trackEvent } = useTracking();
const onProductClick = () => trackEvent('click_selected_equipment', props.slug);
</script>

<template>
  <OdsCard class="product-card" :class="{ 'product-card--hidden': hidden }" with-border>
    <div class="product-card__top">
      <ProductLabelling class="product-card__labelling" :state :delivery-time :hidden />

      <div class="product-card__image">
        <slot v-if="imageRelativePath" name="image">
          <ImageElement
            :src="imageRelativePath"
            :alt="image?.alt"
            loading="lazy"
            class="product-card__image-el"
            fit="contain"
            :modifiers="{ container: 'product' }"
            :height="ImageSizeMap.productCard.height"
            :width="ImageSizeMap.productCard.width"
          />
        </slot>
      </div>

      <OdsClickableElement :to :title class="product-card__link" @click="onProductClick">
        <component :is="tag" class="product-card__name">
          {{ name }}
        </component>
      </OdsClickableElement>
    </div>

    <div class="product-card__bottom">
      <div v-if="price" class="product-card__pricing">
        <PricingRent :value="price" start-from />
      </div>

      <ProductShippedBy class="product-card__shipped-by" :supplier-name />
    </div>
  </OdsCard>
</template>

<style lang="scss">
@use '~/assets/scss/mixins-utils' as mixins;

.product-card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--s);
  justify-content: space-between;
  background-color: var(--scheme--background);
  border-radius: var(--radius--m);
  position: relative;
  height: 100%;

  &--hidden {
    background-color: var(--semantic--warning-light);
  }

  @include mixins.card-hover-transition;

  &__labelling {
    position: absolute;
    top: var(--spacing--s);
    left: var(--spacing--s);
  }

  &__image {
    aspect-ratio: 1;
  }

  &__image-el {
    width: 100%;
  }

  &__link {
    @include mixins.clickable-area;
  }

  &__name {
    font-weight: 700;
    line-height: normal;
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
  }

  &__shipped-by {
    font-size: var(--extra-small--font-size);

    a {
      position: relative;
      z-index: 2;
    }
  }
}
</style>
