<i18n lang="json" locale="fr-FR">
{
  "rentProduct": "Louer le produit",
  "goToProductPage": "Voir en détails le produit {name}"
}
</i18n>

<script lang="ts" setup>
import { type HtmlTagName, OdsButton, OdsCard, OdsClickableElement } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

import type { FieldImageFragment } from '~/types/graphql';
import type { Product } from '~/utils/api/catalog/products/utils';

export type FeaturedProductCardProps = Product & {
  tag?: HtmlTagName;
  cmsImage?: FieldImageFragment;
  characteristics?: string;
};

const props = withDefaults(defineProps<FeaturedProductCardProps>(), {
  tag: 'span',
  cmsImage: undefined,
  characteristics: undefined,
});

const { t } = useI18n();

const title = computed<string>(() => {
  return t('goToProductPage', { name: props.name });
});

const to = computed(() => {
  return helpers.route({
    name: 'product-slug-sku-productId',
    params: { slug: props.slug, productId: props.id },
  });
});
const { trackEvent } = useTracking();
const onProductClick = () => trackEvent('click_handpicked_products', props.slug);
</script>

<template>
  <OdsCard class="featured-product-card" with-shadow>
    <div class="featured-product-card__container">
      <OdsClickableElement class="featured-product-card__image" :to :title>
        <slot name="image">
          <CmsFieldImage
            v-if="cmsImage?.data?.attributes?.hash"
            class="featured-product-card__image-el"
            :width="ImageSizeMap.featuredProductCard.width"
            :height="ImageSizeMap.featuredProductCard.height"
            loading="lazy"
            fit="contain"
            :data="cmsImage"
          />

          <ImageElement
            v-else-if="imageRelativePath"
            class="featured-product-card__image-el"
            :src="imageRelativePath"
            :alt="image?.alt"
            loading="lazy"
            :modifiers="{ container: 'product' }"
            fit="contain"
            :height="ImageSizeMap.featuredProductCard.height"
            :width="ImageSizeMap.featuredProductCard.width"
          />
        </slot>
      </OdsClickableElement>

      <div class="featured-product-card__wrapper">
        <div class="featured-product-card__content">
          <ProductLabelling class="featured-product-card__labelling" :delivery-time :state :hidden />

          <OdsClickableElement
            v-if="$slots.title || name"
            :to
            :title
            class="featured-product-card__title"
            @click="onProductClick"
          >
            <slot name="title">
              <Component :is="tag">{{ name }}</Component>
            </slot>
          </OdsClickableElement>

          <div v-if="$slots.default || characteristics" class="featured-product-card__description">
            <slot v-if="characteristics" name="default">
              <MarkdownContent :content="characteristics" />
            </slot>
          </div>
        </div>

        <div v-if="to || price" class="featured-product-card__footer">
          <div v-if="price" class="featured-product-card__price">
            <PricingRent :value="price" start-from size="large" />
          </div>

          <div v-if="to" class="featured-product-card__cta">
            <OdsButton :to :label="t('rentProduct')" full-width />
          </div>
        </div>
      </div>
    </div>
  </OdsCard>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/mixins-utils' as mixins;

.featured-product-card {
  container: featured-product-card / inline-size;
  height: 100%;

  $featured-product-card-breakpoint: 450px;

  @include mixins.card-hover-transition;

  &__labelling {
    margin: 0 0 var(--spacing--xs) 0;
  }

  &__container {
    display: flex;
    gap: var(--spacing--m);
    flex-direction: column;
    height: 100%;

    @container featured-product-card (width >= #{$featured-product-card-breakpoint}) {
      flex-direction: row;
    }
  }

  &__image {
    width: 100%;
    flex-shrink: 0;
    aspect-ratio: 1;

    @container featured-product-card (width >= #{$featured-product-card-breakpoint}) {
      width: 35%;
    }

    @container featured-product-card (width >= 500px) {
      width: 45%;
    }
  }

  &__image-el {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
    flex: 1 1 55%;
    justify-content: space-between;
  }

  &__title {
    @include ods.h3;

    display: block;
  }

  &__footer {
    display: flex;
    gap: var(--spacing--m);
    flex-direction: column;
    align-items: flex-end;

    @container featured-product-card (width >= #{$featured-product-card-breakpoint}) {
      flex-direction: row;
    }
  }

  &__price {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
</style>
