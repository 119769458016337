import { toArray } from '@locam/common-services';
import destr from 'destr';

import type { LocationQuery } from '#vue-router';

type MayBeArray<T> = T | T[];

export function flattenSearchParameter(value: MayBeArray<string | null> | undefined): string | undefined;
export function flattenSearchParameter(value: MayBeArray<string | null> | undefined, defaultValue: string): string;
export function flattenSearchParameter(
  value: MayBeArray<string | null> | undefined,
  defaultValue?: string | undefined,
) {
  return (Array.isArray(value) ? value.at(0) : value) ?? defaultValue;
}

export function getLegacyAnSession(query: LocationQuery) {
  try {
    if (typeof query.state === 'string') {
      const decodedState = decodeURIComponent(query.state);
      const parsedState = destr<{ [ANONYMOUS_SESSION_QUERY_PARAM_NAME]?: unknown }>(decodedState);
      const anSession = parsedState[ANONYMOUS_SESSION_QUERY_PARAM_NAME];

      if (anSession && typeof anSession === 'string') {
        return anSession;
      }
    }
  } catch {
    return;
  }
}

export class UrlUtilsError extends Error {}

export function removeQueryParameterFromUrl(
  url: string,
  queryParameterNames: MayBeArray<string>,
  baseUrl?: string,
): string {
  try {
    const parsedUrl = new URL(url, baseUrl);

    for (const name of toArray(queryParameterNames)) {
      parsedUrl.searchParams.delete(name);
    }

    return parsedUrl.toString();
  } catch (error) {
    throw new UrlUtilsError(`Failed to remove query param from URL ${JSON.stringify(url)}`, { cause: error });
  }
}
