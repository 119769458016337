import type { InputMaybe, Maybe } from '~/types/graphql';

export function isNotEmpty<T>(item: Maybe<T> | undefined): item is T {
  return item !== undefined && item !== null;
}

export function filterMaybeList<T>(list: Maybe<Maybe<T>[]> | InputMaybe<InputMaybe<T>[]> | undefined): T[] {
  return list?.filter((item): item is T => isNotEmpty(item)) ?? [];
}

export type GetElementType<T extends unknown[]> = T extends (infer U)[] ? U : never;

export function getProductIdListFromCmsZone(component: CmsDynamicZoneBlock[]) {
  return filterMaybeList(component).flatMap((component) => {
    if (component.__typename === 'ComponentBlockProductList') {
      return component.productIds.split(',');
    } else if (component.__typename === 'ComponentBlockFeaturedProductList') {
      return (component.products?.data.map((product) => product.attributes?.product).filter(Boolean) ?? []) as string[];
    }

    return [];
  });
}
