<script setup lang="ts">
import type { BlockAccordionListFragment, FieldAccordionFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockAccordionListFragment;
}>();

const items = computed<FieldAccordionFragment[]>(() => {
  return (
    props.data.items?.reduce<FieldAccordionFragment[]>((array, accordion) => {
      if (accordion?.content && accordion.title) {
        array.push(accordion);
      }

      return array;
    }, []) ?? []
  );
});
</script>

<template>
  <LayoutSection>
    <CmsFieldTitle v-if="data.title" class="cms-block-accordion-list__title" :data="data.title" />

    <ol v-if="items.length > 0" class="cms-block-accordion-list">
      <li v-for="item in items" :key="item.id" class="cms-block-accordion-list__item">
        <CmsFieldAccordion :data="item" />
      </li>
    </ol>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-accordion-list {
  margin: 0;
  padding: 0;

  &__title {
    @include ods.h2;

    display: block;
    margin: 0 0 var(--spacing--m) 0;
  }

  &__item {
    & + & {
      margin-top: var(--spacing--m);
    }
  }
}
</style>
