<i18n lang="json" locale="fr-FR">
{
  "title": "Le mag’ spécialisé en conseil sur le leasing",
  "ctaTitle": {
    "label": "Pour plus de conseils sur {0}",
    "strong": "comment optimiser votre trésorerie"
  }
}
</i18n>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import type { PostCardProps } from '~/components/PostCard.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockBlogPostsFragment } from '~/types/graphql';

type CmsBlockBlogPosts = {
  data: BlockBlogPostsFragment;
};

const props = defineProps<CmsBlockBlogPosts>();

const { getAssetPath } = useCmsAsset();

const { t } = useI18n();

const MAX_POST_ITEMS = 3;

type CmsBlockBlogPostItem = PostCardProps & { id: string | number };

const posts = computed<CmsBlockBlogPostItem[]>(() => {
  if (props.data.posts?.data.length) {
    return props.data.posts.data.reduce<CmsBlockBlogPostItem[]>((acc, post, index) => {
      if (isNotEmpty(post.attributes) && index + 1 <= MAX_POST_ITEMS) {
        const image = post.attributes.image ? getAssetPath(post.attributes.image) : undefined;

        acc.push({
          id: post.id ?? index,
          title: post.attributes?.title,
          introduction: post.attributes.introduction,
          to: post.attributes.redirectUrl,
          titleTag: 'h3',
          image,
        });
      }

      return acc;
    }, []);
  }

  return [];
});
</script>

<template>
  <LayoutSection scheme="primary">
    <SectionPostCardList class="cms-block-blog-posts">
      <template #default>
        <CmsFieldTitle v-if="data.mainTitle" class="cms-block-blog-posts__title" :data="data.mainTitle" />

        <div v-else class="cms-block-blog-posts__title">{{ t('title') }}</div>
      </template>

      <template #list>
        <li v-for="post in posts" :key="post.id" class="cms-block-blog-posts__item">
          <PostCard v-bind="post" />
        </li>
      </template>

      <template #aside>
        <CmsFieldTitle
          v-if="data.asideCtaTitle?.content && data.asideCtaTitle?.content?.length > 0"
          class="cms-block-blog-posts__aside-title"
          :data="data.asideCtaTitle"
        />

        <div v-else class="cms-block-blog-posts__aside-title">
          <i18n-t keypath="ctaTitle.label">
            <strong>
              {{ t('ctaTitle.strong') }}
            </strong>
          </i18n-t>
        </div>

        <CmsFieldLink
          v-if="data.asideCta"
          class="cms-block-blog-posts__link"
          :data="data.asideCta"
          variant="outlined"
          scheme="light"
        />
      </template>
    </SectionPostCardList>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-blog-posts {
  &__title {
    @include ods.h2;

    margin: 0;
  }

  &__aside-title {
    @include ods.h4;
  }

  &__link {
    width: fit-content;
  }
}
</style>
