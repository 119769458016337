<script setup lang="ts">
import { OdsClickableElement } from '@onliz/design-system-vue';

import { ImageSizeMap, type LinkTarget } from '#imports';

type LogoListItem = {
  src: string;
  to?: string;
  title?: string;
  alt?: string;

  target?: LinkTarget;
};

export type LogoListProps = {
  logos: LogoListItem[];
};

defineProps<LogoListProps>();

const logoSize = ImageSizeMap.logo;
</script>

<template>
  <ul class="logo-list">
    <li v-for="({ to, title, target, alt, src }, i) in logos" :key="i" class="logo-list__item">
      <OdsClickableElement v-if="to" class="logo-list__link" v-bind="{ title, to, target }">
        <ImageElement
          class="logo-list__logo"
          fit="contain"
          :src
          :alt
          :width="logoSize.width"
          :height="logoSize.height"
        />
      </OdsClickableElement>

      <img v-else class="logo-list__logo" v-bind="{ src, alt }" />
    </li>
  </ul>
</template>

<style lang="scss">
@use '~/assets/scss/function-utils' as function;
@use '@onliz/design-system-scss' as ods;

.logo-list {
  --logo-list-gap: var(--spacing--l);

  gap: var(--logo-list-gap);
  flex: 0 0 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  container: logo-list-container / inline-size;
  max-width: ods.px-to-em(900px);
  margin: 0 auto;

  &__item {
    flex: 0 0 function.calc-flex-item-width(2, var(--logo-list-gap));
    max-width: 170px;

    @container logo-list-container (width > 500px) {
      flex-basis: function.calc-flex-item-width(4, var(--logo-list-gap));
    }
  }

  &__link {
    display: block;
  }

  &__logo {
    height: 3em;
    width: 100%;
    object-fit: contain;
  }
}
</style>
