export default defineNuxtRouteMiddleware((to) => {
  const anonymousSessionValue = to.query[ANONYMOUS_SESSION_QUERY_PARAM_NAME];

  if (ANONYMOUS_SESSION_QUERY_PARAM_NAME in to.query) {
    if (anonymousSessionValue) {
      const cookie = useCookie(ANONYMOUS_SESSION_COOKIE_NAME);

      cookie.value = anonymousSessionValue.toString();
    }

    return navigateTo(
      { ...to, query: { ...to.query, [ANONYMOUS_SESSION_QUERY_PARAM_NAME]: undefined } },
      {
        redirectCode: 302,
      },
    );
  }
});
