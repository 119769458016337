import { datadogRum, DefaultPrivacyLevel, type RumInitConfiguration } from '@datadog/browser-rum';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (config.public.datadog.enabled) {
    const datadogConfig: RumInitConfiguration = {
      applicationId: config.public.datadog.applicationId,
      clientToken: config.public.datadog.clientToken,
      site: config.public.datadog.site,
      service: config.public.datadog.service,
      env: config.public.datadog.env,
      version: config.public.datadog.version,
      sessionSampleRate: JSON.parse(config.public.datadog.sessionSampleRate) as number,
      sessionReplaySampleRate: JSON.parse(config.public.datadog.sessionReplaySampleRate) as number,
      trackUserInteractions: JSON.parse(config.public.datadog.trackUserInteractions) as boolean,
      trackResources: JSON.parse(config.public.datadog.trackResources) as boolean,
      trackLongTasks: JSON.parse(config.public.datadog.trackLongTasks) as boolean,
      defaultPrivacyLevel: config.public.datadog.defaultPrivacyLevel as DefaultPrivacyLevel | undefined,
    };

    datadogRum.init(datadogConfig);
    datadogRum.startSessionReplayRecording();
  }
});
