<i18n lang="json" locale="fr-FR">
{
  "phone": "@:{'global.support.phone.local'}",
  "info": {
    "small": {
      "text": "Prix d'un appel local {0}",
      "em": "@:{'global.support.openingHours'}"
    },
    "large": {
      "freeCall": "Prix d'un appel local",
      "openingHours": {
        "text": "Disponible {0}",
        "em": "@:{'global.support.openingHours'}"
      }
    }
  }
}
</i18n>

<script lang="ts" setup>
import { OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { formatPhoneNumber } from '#imports';
import { customerServiceSchedules } from '~/utils/CustomerServiceUtils';

defineProps<{ showInfo?: boolean; large?: boolean }>();

const { t } = useI18n();

const phoneNumberLabel = formatPhoneNumber(t('phone'), ' ');
const { trackEvent } = useTracking();
const { isMobile } = useDevice();
const onPhoneClick = () => trackEvent(isMobile ? 'click_phone' : 'click_contactez_nous');
</script>

<template>
  <span class="sav-phone-number" :class="{ 'sav-phone-number--large': large }">
    <a :href="`tel:${t('phone')}`" class="sav-phone-number__phone" @click="onPhoneClick">
      <OdsIcon class="sav-phone-number__icon" name="call" />

      <span class="sav-phone-number__number">{{ phoneNumberLabel }}</span>
    </a>

    <span v-if="showInfo && large" class="sav-phone-number__info sav-phone-number__info--large">
      <span class="sav-phone-number__info-content">{{ t('info.large.freeCall') }}</span>

      <i18n-t keypath="info.large.openingHours.text" tag="p" class="sav-phone-number__info-content">
        <template #default>
          <span>{{ t('info.large.openingHours.em', customerServiceSchedules) }}</span>
        </template>
      </i18n-t>
    </span>

    <i18n-t v-else-if="showInfo" keypath="info.small.text" tag="p" class="sav-phone-number__info">
      <template #default>
        <span>{{ t('info.small.em', customerServiceSchedules) }}</span>
      </template>
    </i18n-t>
  </span>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.sav-phone-number {
  $block-selector: &;

  display: inline-flex;
  flex-direction: column;
  gap: 0;

  &__phone {
    display: flex;
    align-items: center;
    gap: var(--spacing--xs);
    color: var(--scheme--card-color-emphasis);
    font-size: ods.px-to-rem(18px);

    #{$block-selector}--large & {
      font-size: ods.px-to-rem(28px);
    }
  }

  &__number {
    margin-bottom: 0;
    font-weight: 700;
  }

  &__icon {
    @include ods.h3;

    margin-bottom: 0;
  }

  &__info {
    font-size: ods.px-to-rem(11px);
    flex-shrink: 0;

    &--large {
      font-size: ods.px-to-rem(14px);
      padding: 0 0 0 2.5em;
    }
  }

  &__info-content {
    #{$block-selector}--large & {
      margin: 0;
    }
  }
}
</style>
