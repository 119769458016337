// FIXME Some parts of the following code duplicates @locam/auth but this is intentional (please see code comments below)

// Using individual constants instead of object/enum for better tree shaking
export const ROLE_CRC = 'CRC';
export const ROLE_OPERATOR = 'OPERATOR';
export const ROLE_ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN';
export const ROLE_ORGANIZATION_USER = 'ORGANIZATION_USER';
export const ROLE_SUPPLIER_ADMIN = 'SUPPLIER_ADMIN';
export const ROLE_SUPPLIER_USER = 'SUPPLIER_USER';

export class RoleError extends Error {}

export class OrganizationRoleError extends RoleError {}

export class SupplierRoleError extends RoleError {}

export class CrcRoleError extends RoleError {}

export class OperatorRoleError extends RoleError {}

export const RoleList = [
  ROLE_CRC,
  ROLE_OPERATOR,
  ROLE_SUPPLIER_ADMIN,
  ROLE_SUPPLIER_USER,
  ROLE_ORGANIZATION_ADMIN,
  ROLE_ORGANIZATION_USER,
] as const;

export type Role = (typeof RoleList)[number];

export const OrganizationRoleList = [ROLE_ORGANIZATION_ADMIN, ROLE_ORGANIZATION_USER] as const;

export type OrganizationRole = (typeof OrganizationRoleList)[number];

export const SupplierRoleList = [ROLE_SUPPLIER_ADMIN, ROLE_SUPPLIER_USER] as const;

export type SupplierRole = (typeof SupplierRoleList)[number];

export const CrcRoleList = [ROLE_CRC] as const;

export type CrcRole = (typeof CrcRoleList)[number];

export const OperatorRoleList = [ROLE_OPERATOR] as const;

export type OperatorRole = (typeof OperatorRoleList)[number];

export function isRole(value: string): value is Role {
  return (RoleList as readonly string[]).includes(value);
}

export function assertRole(value: string): asserts value is Role {
  if (!isRole(value)) {
    throw new RoleError(`Invalid role (${JSON.stringify(value)})`);
  }
}

export function isOrganizationRole(value: string): value is OrganizationRole {
  return (OrganizationRoleList as readonly string[]).includes(value);
}

export function assertOrganizationRole(value: string): asserts value is OrganizationRole {
  if (!isOrganizationRole(value)) {
    throw new OrganizationRoleError(`Invalid organization role (${JSON.stringify(value)})`);
  }
}

export function isSupplierRole(value: string): value is SupplierRole {
  return (SupplierRoleList as readonly string[]).includes(value);
}

export function assertSupplierRole(value: string): asserts value is SupplierRole {
  if (!isSupplierRole(value)) {
    throw new SupplierRoleError(`Invalid supplier role (${JSON.stringify(value)})`);
  }
}

export function isCrcRole(value: string): value is CrcRole {
  return value === ROLE_CRC;
}

export function assertCrcRole(value: string): asserts value is CrcRole {
  if (!isCrcRole(value)) {
    throw new CrcRoleError(`Invalid CRC role (${JSON.stringify(value)})`);
  }
}

export function isOperatorRole(value: string): value is OperatorRole {
  return value === ROLE_OPERATOR;
}

export function assertOperatorRole(value: string): asserts value is OperatorRole {
  if (!isOperatorRole(value)) {
    throw new OperatorRoleError(`Invalid operator role (${JSON.stringify(value)})`);
  }
}
