export function isValidFrenchPhoneNumber(number: string) {
  return /^(0\d[\s.-]?)(?:\d{2}[\s.-]?){4}$/.test(number);
}

export function formatPhoneNumber(phoneNumber: string, glue = ''): string {
  // Strip non digit characters from the provided string (except plus sign)
  const strippedPhoneNumber = phoneNumber.replaceAll(/[^\d+]/g, '');

  const matches = strippedPhoneNumber.match(
    /^(?:(?:(\d{2})(\d{2})|(\+\d{2}))(\d)|(\d{2}))(\d{2})(\d{2})(\d{2})(\d{2})$/,
  );

  if (matches) {
    // Remove the whole match (index = 0) and empty groups
    const splitNumber = matches.slice(1).filter((match) => !!match);

    // Avengers, Assemble!
    return splitNumber.join(glue);
  }

  return phoneNumber;
}
