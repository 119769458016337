import { ApolloClient, createHttpLink, from } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { provideApolloClient } from '@vue/apollo-composable';

import { defineNuxtPlugin } from '#app';

/**
 * This plugin aims to override the @nuxtjs/apollo configuration at runtime
 * See https://github.com/nuxt-modules/apollo/issues/442
 */
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const $apollo = nuxtApp.$apollo as { defaultClient: ApolloClient<unknown> };

  // trigger the error hook on an error
  const errorLink = onError((error) => {
    void nuxtApp.callHook('apollo:error', error); // must be called bc `@nuxtjs/apollo` will not do it anymore
  });

  // Default httpLink (main communication for apollo)
  const uri = `${config.public.siteUrl}/api/cms/graphql`;

  const httpLink = createHttpLink({ uri });

  // Set custom links in the apollo client.
  // This is the link chain. Will be walked through from top to bottom. It can only contain 1 terminating
  // Apollo link, see: https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link
  $apollo.defaultClient.setLink(from([errorLink, httpLink]));

  // For using useQuery in `@vue/apollo-composable`
  provideApolloClient($apollo.defaultClient);
});
