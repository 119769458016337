<script setup lang="ts">
import { isColorScheme, type OdsColorSchemeType } from '@onliz/design-system-vue';

import type { BlockCardBoardFragment } from '~/types/graphql';

type CmsBlockCardBoardProps = {
  data: BlockCardBoardFragment;
};

const props = defineProps<CmsBlockCardBoardProps>();

const scheme = computed<OdsColorSchemeType | undefined>(() => {
  if (props.data.scheme && isColorScheme(props.data.scheme)) {
    return props.data.scheme;
  }
});
</script>

<template>
  <LayoutSection>
    <SectionCardBoard v-bind="{ scheme }">
      <template v-if="data.image?.data" #image>
        <CmsFieldImage :data="data.image" :width="200" fit="inside" />
      </template>

      <template v-if="data.title" #title>
        <CmsFieldTitle :data="data.title" />
      </template>

      <template v-if="data.description" #description>
        <MarkdownContent :content="data.description" />
      </template>

      <template v-if="data.cardList" #default>
        <CmsFieldCardList :data="data.cardList" />
      </template>
    </SectionCardBoard>
  </LayoutSection>
</template>
