import type { ImageModifiers, ImageOptions, ResolvedImage } from '@nuxt/image';
import { joinURL, withoutLeadingSlash } from 'ufo';

import type { MediaStorageContainerType, MediaStorageResizeType } from '~/utils/MediaStorageUtils';

export interface AzureMediaStorageModifiers extends Partial<ImageModifiers> {
  container: MediaStorageContainerType;
  fit?: MediaStorageResizeType;
  raw?: boolean;
}

interface MediaContainersUrls {
  mediaUrl: string;
  documentUrl: string;
}

export const AzureMediaStorageProviderName = 'azureMediaStorage';

export interface AzureMediaStorageOptions extends ImageOptions {
  baseURL?: string;
  modifiers: AzureMediaStorageModifiers;
}

type AzureMediaStorageProviderGetImage = (source: string, options: AzureMediaStorageOptions) => ResolvedImage;

export const getImage: AzureMediaStorageProviderGetImage = (source: string, options: AzureMediaStorageOptions) => {
  const config = useRuntimeConfig();

  const mediaContainerMap: Record<MediaStorageContainerType, MediaContainersUrls> = {
    cms: {
      mediaUrl: config.public.cmsMediaStorageUrl,
      documentUrl: config.public.cmsDocumentStorageUrl,
    },
    product: {
      mediaUrl: config.public.productMediaStorageUrl,
      documentUrl: config.public.productDocumentStorageUrl,
    },
  };

  const container = options.modifiers.container;
  const mediaContainersUrls: MediaContainersUrls = mediaContainerMap[container];

  if (options.modifiers.raw ?? (!options.modifiers.width || !options.modifiers.height)) {
    options.baseURL = mediaContainersUrls.documentUrl;

    return {
      url: joinURL(options.baseURL, withoutLeadingSlash(source)),
    };
  } else {
    options.baseURL = mediaContainersUrls.mediaUrl;

    const resizeType: MediaStorageResizeType =
      options.modifiers.fit && mediaStorageResizeList.includes(options.modifiers.fit) ? options.modifiers.fit : 'cover';

    const operations = `/${resizeType}/${options.modifiers.width.toString()}/${options.modifiers.height.toString()}/`;

    return {
      url: joinURL(options.baseURL, operations + withoutLeadingSlash(source)),
    };
  }
};
