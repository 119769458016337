<i18n lang="json" locale="fr-FR">
{
  "sav": {
    "title": "Une question ?",
    "desc": "Nos conseillers restent à votre entière disposition par téléphone : {0}",
    "phone": "@:{'global.support.phone.local'}",
    "openingHours": "@:{'global.support.openingHours'}"
  }
}
</i18n>

<script setup lang="ts">
import { OdsColorScheme } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import IllustrationSav from '~/assets/images/illustration-sav.svg?component';
import { customerServiceSchedules } from '~/utils/CustomerServiceUtils';

const { t } = useI18n();
</script>

<template>
  <OdsColorScheme class="error-need-help" scheme="primary">
    <div class="error-need-help__container">
      <IllustrationSav class="error-need-help__image" />

      <div class="error-need-help__body">
        <span class="error-need-help__title">{{ t('sav.title') }}</span>

        <i18n-t keypath="sav.desc" tag="p" class="error-need-help__desc">
          <a :href="`tel:${t('sav.phone')}`">{{ t('sav.phone') }}</a>
        </i18n-t>

        <span class="error-need-help__opening-hours">{{ t('sav.openingHours', customerServiceSchedules) }}</span>
      </div>
    </div>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.error-need-help {
  border-radius: var(--radius--xl);
  padding: var(--spacing--m);
  margin: var(--spacing--m) 0 0 0;

  &__image {
    width: 160px;
    flex-shrink: 0;
  }

  &__container {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing--m);
    flex-direction: column;

    @container default-error-container (width > 600px) {
      flex-direction: row;
    }
  }

  &__title {
    @include ods.h3;

    color: var(--scheme--color-emphasis);
  }

  &__desc {
    @include ods.h6;

    margin: 0;

    a {
      color: var(--scheme--color-emphasis);
    }
  }

  &__opening-hours {
    font-style: italic;
  }
}
</style>
