<script setup lang="ts">
import type { FieldAccordionFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldAccordionFragment;
}>();

const open = computed<boolean>(() => {
  return props.data.open ?? false;
});
</script>

<template>
  <AccordionItem animated :name="data.id" :open class="cms-field-accordion">
    <template v-if="data.image?.data?.attributes?.hash" #image>
      <CmsFieldImage :data="data.image" :width="120" />
    </template>

    <template v-if="data.title" #title>
      <CmsFieldTitle :data="data.title" class="cms-field-accordion__title" />
    </template>

    <template #default>
      <MarkdownContent :content="data.content" />
    </template>
  </AccordionItem>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-field-accordion {
  &__title {
    @include ods.h2;

    margin: 0;
  }
}
</style>
