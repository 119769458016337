import type { OperationVariables, QueryOptions } from '@apollo/client';

import { type AsyncDataOptions, callWithNuxt } from '#app';

type TQuery<T> = QueryOptions<OperationVariables, T>['query'];
type TVariables<T> = QueryOptions<OperationVariables, T>['variables'] | null;

type ValueOrMethod<T extends number | string[] | undefined, R> = ((v: R) => T) | T;
type CachedAsyncDataOptions<T> = Omit<AsyncDataOptions<T>, 'getCachedData'> & {
  clientMaxAge?: number;
  serverMaxAge?: ValueOrMethod<number, T>;
  serverCacheTags?: ValueOrMethod<string[], T>;
};
type CachedAsyncQueryOptions<T> = CachedAsyncDataOptions<T> & { isCacheDisabled?: boolean };

export function useCachedAsyncQuery<T>(
  key: string,
  query: TQuery<T>,
  variables: TVariables<T>,
  providedOptions?: CachedAsyncQueryOptions<T>,
) {
  const {
    clientMaxAge,
    serverMaxAge,
    serverCacheTags,
    isCacheDisabled,
    ...asyncDataOptions
  }: CachedAsyncQueryOptions<T> = providedOptions && typeof providedOptions === 'object' ? providedOptions : {};

  if (isCacheDisabled) {
    return useAsyncQuery<T>(query, variables, undefined, undefined, asyncDataOptions);
  }

  return useCachedAsyncData(
    key,
    async (nuxtApp) => {
      const {
        data: { value },
      } =
        nuxtApp === undefined
          ? await useAsyncQuery<T>(query, variables, undefined, undefined, asyncDataOptions)
          : await callWithNuxt(nuxtApp, useAsyncQuery<T>, [query, variables, undefined, undefined, asyncDataOptions]);

      return value as T;
    },
    {
      ...asyncDataOptions,
      clientMaxAge,
      serverMaxAge,
      serverCacheTags,
    },
  );
}
