import { getOrSet } from '~/modules/auth/runtime/utils/Cache';

export default defineNuxtPlugin(() => {
  const requestStorage = new Map<string, unknown>();

  return {
    provide: {
      authUtils: {
        getOrSetRequestAuthStorageEntry<T>(key: string, callback: () => T): T {
          return getOrSet(requestStorage, key, callback);
        },
      },
    },
  };
});
