<template>
  <Transition name="slide-up-" mode="out-in">
    <slot></slot>
  </Transition>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.slide-up {
  &--enter-active,
  &--leave-active {
    transition:
      transform 500ms ods.$ease-out-circ,
      opacity 300ms ods.$ease-out-cubic;
  }

  &--enter-from {
    opacity: 0;
    transform: translateY(50px);
  }

  &--leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
}
</style>
