import { getUserOrganizationFromToken } from '~/utils/api/organization/user-organization';
import type { Organization } from '~/utils/api/organization/user-organization/utils';

export default function useAuthenticatedOrganization() {
  return useNuxtApp().$authUtils.getOrSetRequestAuthStorageEntry('useAuthenticatedOrganization', async () => {
    const { isOrganizationUser, jwt } = useAuth();

    return useAsyncData(
      'authenticated-organization',
      (): Promise<Organization | undefined> => {
        if (isOrganizationUser.value && jwt.value) {
          return getUserOrganizationFromToken(jwt.value);
        }

        // eslint-disable-next-line unicorn/no-useless-undefined
        return Promise.resolve(undefined);
      },
      { watch: [isOrganizationUser, jwt] },
    );
  });
}
