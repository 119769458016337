import { OrganizationFieldValidationStatusEnum } from '@locam/common-services';

export interface ValidationFieldApi {
  value: string;
  status?: keyof typeof OrganizationFieldValidationStatusEnum;
  invalidReason?: string;
  validatedAt?: string;
  valueUpdatedAt?: string;
}

export function getValidationFieldApiValue({ value }: ValidationFieldApi): ValidationFieldApi['value'] {
  return value;
}

export interface AddressApi {
  addressId: string;
  label: string;
  title: 'mr' | 'mrs';
  headOffice: boolean;
  name: string;
  firstname: string;
  company: string;
  address: string;
  streetNumber?: string | null;
  streetName: string;
  additionalAddress?: string;
  comment?: string | null;
  city: string;
  zipcode: string;
  default: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface UserApi {
  userId: string;
  isAdmin: boolean;
  isActive: boolean;
  title: 'mr' | 'mrs';
  email: string;
  firstName: string;
  lastName: string;
  birthday: string;
  phoneData: ValidationFieldApi;
  jobTitle: string;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface IbanApi {
  ibanId: string;
  label: string;
  sixFirstIban: string;
  siren: string;
}

export interface FavoriteApi {
  favoriteId: string;
  productId: string;
  createdAt: string;
}

export interface FavoriteGroupApi {
  favoriteGroupId: string;
  label: string;
  items: FavoriteApi[];
}

export interface SignatoryApi {
  signatoryId: string;
  title: string;
  lastName: string;
  firstName: string;
  birthday: string;
  email: string;
  phoneData: ValidationFieldApi;
  jobTitle: string;
}

export interface OrganizationApi {
  organizationId: string;
  companyNameData: ValidationFieldApi;
  sirenData: ValidationFieldApi;
  intracommunityVatNumberData: ValidationFieldApi;
  legalRepresentative: string | null;
  addresses: AddressApi[];
  users: UserApi[];
  iban: IbanApi[];
  favoriteGroups: FavoriteGroupApi[];
  signatories: SignatoryApi[];
  createdAt?: string | null;
  updatedAt?: string | null;
}

export type AddressId = string;

export interface Address {
  id: AddressId;
  label: string;
  title: 'mr' | 'mrs';
  isHeadOffice: boolean;
  lastname: string;
  firstname: string;
  company: string;
  address: string;
  streetNumber?: string;
  streetName: string;
  additionalAddress?: string;
  comment?: string;
  city: string;
  zipcode: string;
  isDefault: boolean;
}

export interface User {
  id: string;
  isAdmin: boolean;
  isActive: boolean;
  title: 'mr' | 'mrs';
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: string;
  jobTitle: string;
}

export interface Iban {
  id: string;
  label: string;
  truncatedValue: string;
}

export interface Favorite {
  id: string;
  productId: string;
}

export interface FavoriteGroup {
  id: string;
  label: string;
  items: Favorite[];
}

export interface Signatory {
  id: string;
  title: string;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  jobTitle: string;
}

export interface Organization {
  id: string;
  companyName: string;
  siren: string;
  vatNumber: string;
  legalRepresentative: string | undefined;
  addressList: Address[];
  userList: User[];
  ibanList: Iban[];
  favoriteGroupList: FavoriteGroup[];
  signatoryList: Signatory[];
}

export class InvalidDateError extends Error {}

export function toDate(value: string): Date {
  const date = new Date(value);

  if (date.toString() === 'Invalid Date') {
    throw new InvalidDateError(`Failed to create Date (${value})`);
  }

  return date;
}

export function mapAddressApiToAddress({
  addressId: id,
  label,
  title,
  headOffice: isHeadOffice,
  name: lastname,
  firstname,
  company,
  address,
  streetNumber,
  streetName,
  additionalAddress,
  comment,
  city,
  zipcode,
  default: isDefault,
}: AddressApi): Address {
  return {
    id,
    label,
    title,
    isHeadOffice,
    firstname,
    lastname,
    company,
    address,
    streetNumber: streetNumber?.length ? streetNumber : undefined,
    streetName,
    additionalAddress: additionalAddress?.length ? additionalAddress : undefined,
    comment: comment?.length ? comment : undefined,
    city,
    zipcode,
    isDefault,
  };
}

export function mapUserApiToUser({
  userId: id,
  isAdmin,
  isActive,
  title,
  email,
  firstName,
  lastName,
  birthday: dateOfBirth,
  phoneData,
  jobTitle,
}: UserApi): User {
  return {
    id,
    isAdmin,
    isActive,
    title,
    email,
    firstName,
    lastName,
    dateOfBirth,
    phone: getValidationFieldApiValue(phoneData),
    jobTitle,
  };
}

export function mapIbanApiToIban({ ibanId: id, label, sixFirstIban: truncatedValue }: IbanApi): Iban {
  return {
    id,
    label,
    truncatedValue,
  };
}

export function mapFavoriteApiToFavorite({ favoriteId: id, productId }: FavoriteApi): Favorite {
  return {
    id,
    productId,
  };
}

export function mapFavoriteGroupApiToFavoriteGroup({
  favoriteGroupId: id,
  label,
  items,
}: FavoriteGroupApi): FavoriteGroup {
  return {
    id,
    label,
    items: items.map((favorite) => mapFavoriteApiToFavorite(favorite)),
  };
}

export function mapSignatoryApiToSignatory({
  signatoryId: id,
  title,
  email,
  firstName,
  lastName,
  jobTitle,
  phoneData,
  birthday: dateOfBirth,
}: SignatoryApi): Signatory {
  return {
    id,
    title,
    email,
    firstName,
    lastName,
    jobTitle,
    phone: getValidationFieldApiValue(phoneData),
    dateOfBirth,
  };
}

export function mapUserOrganizationApiToUserOrganization({
  organizationId,
  companyNameData: { value: companyName },
  sirenData: { value: siren },
  intracommunityVatNumberData: { value: vatNumber },
  legalRepresentative,
  addresses,
  users,
  iban: ibanList,
  favoriteGroups,
  signatories,
}: OrganizationApi): Organization {
  return {
    id: organizationId,
    companyName,
    siren,
    vatNumber,
    legalRepresentative: legalRepresentative ?? undefined,
    addressList: addresses.map((address) => mapAddressApiToAddress(address)),
    userList: users.map((user) => mapUserApiToUser(user)),
    ibanList: ibanList.map((iban) => mapIbanApiToIban(iban)),
    favoriteGroupList: favoriteGroups.map((favoriteGroup) => mapFavoriteGroupApiToFavoriteGroup(favoriteGroup)),
    signatoryList: signatories.map((signatory) => mapSignatoryApiToSignatory(signatory)),
  };
}
