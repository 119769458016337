<script setup lang="ts">
withDefaults(
  defineProps<{
    count?: number;
  }>(),
  {
    count: 4,
  },
);

onMounted(() => {
  useHead({
    script: [
      {
        key: 'avis-verifies',
        src: 'https://widgets.rr.skeepers.io/carousel/75ff43e8-fdfe-15c4-61f5-aa51f084e8ac/564bd36c-5d31-4768-b944-0e2379d056f9.js',
        type: 'text/javascript',
        async: true,
        tagPosition: 'bodyClose',
      },
    ],
  });
});
</script>

<template>
  <ClientOnly>
    <div class="skeepers_carousel_container" :data-slides-count="count"></div>
  </ClientOnly>
</template>
