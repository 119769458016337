export const FIRST_TOUCH_POINT_STORAGE_KEY = 'first_touchpoint';
export const SESSION_TOUCH_POINT_STORAGE_KEY = 'session_touchpoint';

export const TOUCH_POINT_DEFAULT_VALUE = 'direct';

export const TRACKING_KEY_LIST = ['fbclid', 'gclid', 'utm_source', 'utm_medium', 'utm_content'];

export function getOrSetTouchPoint(storage: Storage, storageKey: string): string {
  const { query, fullPath } = useRoute();

  const persistedValue = storage.getItem(storageKey);

  if (persistedValue) {
    return persistedValue;
  }

  const newValue =
    (TRACKING_KEY_LIST.some((key) => Object.hasOwn(query, key)) ? fullPath : window.document.referrer) ||
    TOUCH_POINT_DEFAULT_VALUE;

  storage.setItem(storageKey, newValue);

  return newValue;
}
