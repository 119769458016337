<script setup lang="ts">
import { isIconName } from '@onliz/design-system-vue';

import { ImageSizeMap } from '#imports';
import type { IconTagProps } from '~/components/IconTag.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockLandingHeroFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockLandingHeroFragment;
}>();

const tags = computed<IconTagProps[]>(() => {
  if (!props.data.tags?.length) {
    return [];
  }

  return props.data.tags.reduce<IconTagProps[]>((acc, tag) => {
    if (isNotEmpty(tag)) {
      acc.push({
        label: tag.label ?? undefined,
        icon: tag.icon && isIconName(tag.icon) ? tag.icon : undefined,
      });
    }

    return acc;
  }, []);
});
</script>

<template>
  <SectionLandingHero class="cms-block-landing-hero">
    <template #default>
      <CmsFieldTitle v-if="data?.title" class="cms-block-landing-hero__title" :data="data.title" />

      <div class="cms-block-landing-hero__wrapper">
        <MarkdownContent
          v-if="data.description"
          class="cms-block-landing-hero__description"
          :content="data.description"
        />

        <ul v-if="tags.length > 0" class="cms-block-landing-hero__tags">
          <li v-for="(tag, i) in tags" :key="`hero-tags-tag-${i}`" class="cms-block-landing-hero__tags-item">
            <IconTag v-bind="tag" />
          </li>
        </ul>

        <CmsFieldActionBanner v-if="data.actionBanner" :data="data.actionBanner" />
      </div>
    </template>

    <template #image>
      <CmsFieldImage
        v-if="data.image"
        class="cms-block-landing-hero__image"
        fit="inside"
        :data="data.image"
        priority="high"
        rounded
        :width="ImageSizeMap.landingHero.width"
        :height="ImageSizeMap.landingHero.height"
      />
    </template>
  </SectionLandingHero>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

$cms-block-tutorial-breakpoint-s: 500px;

.cms-block-landing-hero {
  &__title {
    @include ods.h1;

    font-weight: 700;

    strong,
    b {
      font-weight: 800;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--m);
  }

  &__description {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing--s);
  }

  &__image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-width: 670px;
    margin: 0 auto;
  }
}
</style>
