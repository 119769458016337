import { default as _91slug_93SM1Wl7g4H8Meta } from "/agent/_work/13/s/app/pages/[slug].vue?macro=true";
import { default as basketzziXLbCP91Meta } from "/agent/_work/13/s/app/pages/basket.vue?macro=true";
import { default as _91_46_46_46slug_93cXV3FsDt7qMeta } from "/agent/_work/13/s/app/pages/cat/[...slug].vue?macro=true";
import { default as contact0n7QDJrtv8Meta } from "/agent/_work/13/s/app/pages/contact.vue?macro=true";
import { default as indexWcYfxBSJ0EMeta } from "/agent/_work/13/s/app/pages/index.vue?macro=true";
import { default as _91slug_93KExbRKwe70Meta } from "/agent/_work/13/s/app/pages/lp/[slug].vue?macro=true";
import { default as searchtQoWX1YH7mMeta } from "/agent/_work/13/s/app/pages/p/search.vue?macro=true";
import { default as _91productId_93S8vLVv59XPMeta } from "/agent/_work/13/s/app/pages/product/[slug]/sku/[productId].vue?macro=true";
export default [
  {
    name: _91slug_93SM1Wl7g4H8Meta?.name ?? "slug",
    path: _91slug_93SM1Wl7g4H8Meta?.path ?? "/:slug()",
    meta: _91slug_93SM1Wl7g4H8Meta || {},
    alias: _91slug_93SM1Wl7g4H8Meta?.alias || [],
    redirect: _91slug_93SM1Wl7g4H8Meta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: basketzziXLbCP91Meta?.name ?? "basket",
    path: basketzziXLbCP91Meta?.path ?? "/basket",
    meta: basketzziXLbCP91Meta || {},
    alias: basketzziXLbCP91Meta?.alias || [],
    redirect: basketzziXLbCP91Meta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cXV3FsDt7qMeta?.name ?? "cat-slug",
    path: _91_46_46_46slug_93cXV3FsDt7qMeta?.path ?? "/cat/:slug(.*)*",
    meta: _91_46_46_46slug_93cXV3FsDt7qMeta || {},
    alias: _91_46_46_46slug_93cXV3FsDt7qMeta?.alias || [],
    redirect: _91_46_46_46slug_93cXV3FsDt7qMeta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/cat/[...slug].vue").then(m => m.default || m)
  },
  {
    name: contact0n7QDJrtv8Meta?.name ?? "contact",
    path: contact0n7QDJrtv8Meta?.path ?? "/contact",
    meta: contact0n7QDJrtv8Meta || {},
    alias: contact0n7QDJrtv8Meta?.alias || [],
    redirect: contact0n7QDJrtv8Meta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexWcYfxBSJ0EMeta?.name ?? "index",
    path: indexWcYfxBSJ0EMeta?.path ?? "/",
    meta: indexWcYfxBSJ0EMeta || {},
    alias: indexWcYfxBSJ0EMeta?.alias || [],
    redirect: indexWcYfxBSJ0EMeta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KExbRKwe70Meta?.name ?? "lp-slug",
    path: _91slug_93KExbRKwe70Meta?.path ?? "/lp/:slug()",
    meta: _91slug_93KExbRKwe70Meta || {},
    alias: _91slug_93KExbRKwe70Meta?.alias || [],
    redirect: _91slug_93KExbRKwe70Meta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/lp/[slug].vue").then(m => m.default || m)
  },
  {
    name: searchtQoWX1YH7mMeta?.name ?? "p-search",
    path: searchtQoWX1YH7mMeta?.path ?? "/p/search",
    meta: searchtQoWX1YH7mMeta || {},
    alias: searchtQoWX1YH7mMeta?.alias || [],
    redirect: searchtQoWX1YH7mMeta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/p/search.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93S8vLVv59XPMeta?.name ?? "product-slug-sku-productId",
    path: _91productId_93S8vLVv59XPMeta?.path ?? "/product/:slug()/sku/:productId()",
    meta: _91productId_93S8vLVv59XPMeta || {},
    alias: _91productId_93S8vLVv59XPMeta?.alias || [],
    redirect: _91productId_93S8vLVv59XPMeta?.redirect,
    component: () => import("/agent/_work/13/s/app/pages/product/[slug]/sku/[productId].vue").then(m => m.default || m)
  },
  {
    path: "/nos-produits",
    name: "nos-produits",
    meta: {"isLegacy":true}
  },
  {
    path: "/billing/information",
    name: "billing-index-information",
    meta: {"isLegacy":true}
  },
  {
    path: "/billing/signatory",
    name: "billing-index-signatory",
    meta: {"isLegacy":true}
  },
  {
    path: "/billing",
    name: "billing",
    meta: {"isLegacy":true}
  },
  {
    path: "/blog",
    name: "blog",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/addresses",
    name: "customer-index-addresses",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/favorites",
    name: "customer-index-favorites",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/account/organization",
    name: "customer-index-account-organization",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/account/profile",
    name: "customer-index-account-profile",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/account/users",
    name: "customer-index-account-users",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/discussion/archived",
    name: "customer-index-discussion-archived",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/discussion/list",
    name: "customer-index-discussion-list",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/orders/active",
    name: "customer-index-orders-active",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/orders/archived",
    name: "customer-index-orders-archived",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/orders/processing",
    name: "customer-index-orders-processing",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/orders/signing",
    name: "customer-index-orders-signing",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer",
    name: "customer",
    meta: {"isLegacy":true}
  },
  {
    path: "/login",
    name: "login",
    meta: {"isLegacy":true}
  },
  {
    path: "/signup",
    name: "signup",
    meta: {"isLegacy":true}
  },
  {
    path: "/billing/loading-iframe",
    name: "billing-loading-iframe",
    meta: {"isLegacy":true}
  },
  {
    path: "/billing/signing",
    name: "billing-signing",
    meta: {"isLegacy":true}
  },
  {
    path: "/content/informations",
    name: "content-informations",
    meta: {"isLegacy":true}
  },
  {
    path: "/order/confirmation",
    name: "order-confirmation",
    meta: {"isLegacy":true}
  },
  {
    path: "/product/search",
    name: "product-search",
    meta: {"isLegacy":true}
  },
  {
    path: "/blog/article/:article?",
    name: "blog-article-article",
    meta: {"isLegacy":true}
  },
  {
    path: "/blog/cat/:cat?",
    name: "blog-cat-cat",
    meta: {"isLegacy":true}
  },
  {
    path: "/content/faq/:slug?",
    name: "content-faq-slug",
    meta: {"isLegacy":true}
  },
  {
    path: "/content/vendeurs/:slug?",
    name: "content-vendeurs-slug",
    meta: {"isLegacy":true}
  },
  {
    path: "/customer/discussion/:id?",
    name: "customer-discussion-id",
    meta: {"isLegacy":true}
  },
  {
    path: "/order/item/:id?",
    name: "order-item-id",
    meta: {"isLegacy":true}
  },
  {
    path: "/content/:slug?",
    name: "content-slug",
    meta: {"isLegacy":true}
  },
  {
    path: "/content/qui-sommes-nous",
    name: "content-qsm",
    meta: {"isLegacy":true}
  }
]