<script setup lang="ts">
import { filterMaybeList } from '~/lib/strapi-utils';
import type { FieldCardListFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldCardListFragment;
}>();

const filteredCards = computed(() => {
  return filterMaybeList(props.data.cards);
});
</script>

<template>
  <CardList class="cms-card-list" :scroll-on-mobile="data.scrollOnMobile ?? false">
    <template v-for="(card, i) in filteredCards" :key="i">
      <CmsFieldCard v-if="card" :data="card" />
    </template>
  </CardList>
</template>
