<i18n lang="json" locale="fr-FR">
{
  "category": {
    "hightech": "High-tech",
    "impression": "Impression",
    "distribution": "Distribution",
    "visio": "Visio",
    "hygiene": "Hygiène",
    "mobilier": "Mobilier"
  }
}
</i18n>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import IconCategoriesDistribution from '~/assets/icons/categories/icon-distribution.svg?component';
import IconCategoriesHightech from '~/assets/icons/categories/icon-hightech.svg?component';
import IconCategoriesHygiene from '~/assets/icons/categories/icon-hygiene.svg?component';
import IconCategoriesImpression from '~/assets/icons/categories/icon-impression.svg?component';
import IconCategoriesMobilier from '~/assets/icons/categories/icon-mobilier.svg?component';
import IconCategoriesVisio from '~/assets/icons/categories/icon-visio.svg?component';

const { t } = useI18n();

const categoriesIconMap: {
  label: string;
  icon: Component;
  color: string;
}[] = [
  {
    label: t('category.hightech'),
    icon: IconCategoriesHightech,
    color: '#222222',
  },
  {
    label: t('category.impression'),
    icon: IconCategoriesImpression,
    color: '#0E4F5E',
  },
  {
    label: t('category.distribution'),
    icon: IconCategoriesDistribution,
    color: '#00AA9E',
  },
  {
    label: t('category.visio'),
    icon: IconCategoriesVisio,
    color: '#E95131',
  },
  {
    label: t('category.hygiene'),
    icon: IconCategoriesHygiene,
    color: '#E95131',
  },
  {
    label: t('category.mobilier'),
    icon: IconCategoriesMobilier,
    color: '#E37038',
  },
];
</script>

<template>
  <ul class="error-category-list">
    <li v-for="category in Object.values(categoriesIconMap)" :key="category.color" class="error-category-list__item">
      <Component :is="category.icon" class="error-category-list__icon" />

      <span class="error-category-list__label" :style="{ color: category.color }">{{ category.label }}</span>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.error-category-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacing--xs);

  @container default-error-container (width < 900px) {
    width: 400px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
  }

  &__label {
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
