<i18n lang="json" locale="fr-FR">
{
  "startFrom": "dès"
}
</i18n>

<script setup lang="ts">
import type { HtmlTagName } from '@onliz/design-system-vue';
import { OdsCard, OdsClickableElement } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

import { ImageSizeMap } from '#imports';

import type { ImageElementProps } from './ImageElement.vue';

export type CategoryCardProps = {
  slug: string;
  title: string;
  alt?: string;
  tag?: HtmlTagName;
  startFromPrice: number;
  image?: ImageElementProps;
};

const props = withDefaults(defineProps<CategoryCardProps>(), { tag: 'span', alt: undefined, image: undefined });

const { trackEvent } = useTracking();
const { t } = useI18n();

const onCategoryClick = () => trackEvent('click_selected_categories', props.slug);

const to = computed(() => {
  return helpers.route({ name: 'cat-slug', params: { slug: [props.slug] } });
});
</script>

<template>
  <OdsCard with-shadow class="category-card">
    <Component :is="tag" class="category-card__title">
      <OdsClickableElement class="category-card__link" :title :to @click="onCategoryClick"
        >{{ title }}
      </OdsClickableElement>
    </Component>

    <div v-if="startFromPrice" class="category-card__price">
      <span class="category-card__price-label">{{ t('startFrom') }}</span>

      <PricingRent :value="startFromPrice" />
    </div>

    <div v-if="$slots.image || image" class="category-card__image">
      <slot name="image">
        <ImageElement
          v-if="image"
          v-bind="image"
          :width="ImageSizeMap.categoryCard.width"
          :height="ImageSizeMap.categoryCard.height"
          :alt
        />
      </slot>
    </div>
  </OdsCard>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/mixins-utils' as mixins;

.category-card {
  text-align: center;
  max-width: ods.px-to-rem(400px);
  position: relative;

  @include mixins.card-hover-transition;

  &__price {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing--xs);
    justify-content: center;

    &-label {
      line-height: normal;
    }
  }

  &__image {
    position: relative;
    width: 60%;
    margin: var(--spacing--xs) auto 0 auto;
  }

  &__title {
    @include ods.h6;

    white-space: nowrap;
    margin: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__link {
    @include mixins.clickable-area;
  }
}
</style>
