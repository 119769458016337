const categoriesToRedirect: Record<string, string> = {
  /* eslint-disable @typescript-eslint/naming-convention*/
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-tablette-tactile?brand=Apple':
    '/cat/informatique/tablette-tactile/ipad',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-tablette-tactile?brand=Samsung':
    '/cat/informatique/tablette-tactile/tablette-samsung',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-tablette-tactile':
    '/cat/informatique/tablette-tactile',
  '/cat/informatique/informatique-ordinateur': '/cat/informatique/ordinateur',
  '/cat/informatique/informatique-ordinateur/informatique-ordinateur-portable':
    '/cat/informatique/ordinateur/ordinateur-portable',
  '/cat/informatique/informatique-ordinateur/informatique-ordinateur-bureau':
    '/cat/informatique/ordinateur/ordinateur-de-bureau',
  '/cat/informatique/informatique-ordinateur/informatique-ordinateur-ecrans': '/cat/informatique/ordinateur/ecran-pc',
  '/cat/informatique/informatique-ordinateur/informatique-ordinateur-unite-centrale':
    '/cat/informatique/ordinateur/unite-centrale',
  '/cat/informatique/informatique-reseau/informatique-reseau-switch-reseau': '/cat/informatique/equipement-reseau',
  '/cat/informatique/informatique-reseau/informatique-reseau-cybersecurite': '/cat/informatique/solution-cybersecurite',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone':
    '/cat/telephones/smartphones',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?brand=Apple':
    '/cat/telephones/smartphones/iphone',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?brand=Samsung':
    '/cat/telephones/smartphones/samsung',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?brand=Xiaomi':
    '/cat/telephones/smartphones/xiaomi',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?brand=Caterpillar':
    '/cat/telephones/smartphones/caterpillar',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?brand=Crosscall':
    '/cat/telephones/smartphones/crosscall',
  '/cat/telephonie-multimedia/telephonie-multimedia-outils-collaboratifs': '/cat/outil-collaboratif',
  '/cat/telephonie-multimedia/telephonie-multimedia-outils-collaboratifs/telephonie-multimedia-outils-collaboratifs-ecran':
    '/cat/outil-collaboratif/ecrans-collaboratifs',
  '/cat/telephonie-multimedia/telephonie-multimedia-outils-collaboratifs/telephonie-multimedia-outils-collaboratifs-camera':
    '/cat/outil-collaboratif/visioconférence',
  '/cat/telephonie-multimedia/telephonie-multimedia-affichage-dynamique':
    '/cat/outil-collaboratif/ecrans-affichage-dynamique',
  '/cat/mobilier': '/cat/meuble-de-bureau',
  '/cat/mobilier/mobilier-meuble/mobilier-meuble-fauteuil': '/cat/meuble-de-bureau/fauteuils',
  '/cat/mobilier/mobilier-meuble/mobilier-meuble-chaise': '/cat/meuble-de-bureau/chaises',
  '/cat/mobilier/mobilier-meuble/mobilier-meuble-table': '/cat/meuble-de-bureau/tables',
  '/cat/mobilier/mobilier-meuble/mobilier-meuble-bureau': '/cat/meuble-de-bureau/bureaux-d-accueil',
  '/cat/mobilier/mobilier-rangement': '/cat/meuble-de-bureau/rangements',
  '/cat/mobilier/mobilier-rangement/mobilier-rangement-armoire-et-caisson': '/cat/meuble-de-bureau/rangement/armoires',
  '/cat/distributeur-automatique': '/cat/distributeurs-automatiques',
  '/cat/distributeur-automatique/distributeur-automatique-boisson':
    '/cat/distributeurs-automatiques/distributeurs-de-boisson',
  '/cat/distributeur-automatique/distributeur-automatique-boisson/distributeur-automatique-boisson-machine-a-cafe':
    '/cat/distributeurs-automatiques/distributeurs-de-boisson/machines-a-cafe',
  '/cat/distributeur-automatique/distributeur-automatique-boisson/distributeur-automatique-boisson-machine-a-jus':
    '/cat/distributeurs-automatiques/distributeurs-de-boisson/machines-a-jus',
  '/cat/distributeur-automatique/distributeur-automatique-boisson/distributeur-automatique-boisson-fontaine-a-eau':
    '/cat/distributeurs-automatiques/distributeurs-de-boisson/fontaines-a-eau',
  '/cat/encaissement/encaissement-equipement/encaissement-equipement-caisse': '/cat/encaissement/caisses',
  '/cat/encaissement/encaissement-terminal-de-paiement/encaissement-terminal-de-paiement-mobile':
    '/cat/encaissement/tpe/tpe-mobiles',
  '/cat/hygiene/hygiene-locaux/hygiene-purificateur-air': '/cat/hygiene/purificateurs-d-air',
  '/cat/hygiene/hygiene-locaux/hygiene-aspirateur': '/cat/hygiene/aspirateurs',
  '/cat/hygiene/hygiene-locaux/hygiene-seche-mains': '/cat/hygiene/seches-mains',
  '/cat/securite': '/cat/securite-professionnelle',
  '/cat/securite/securite-locaux': '/cat/securite-professionnelle/securite-des-locaux',
  '/cat/securite/securite-locaux/securite-locaux-videosurveillance':
    '/cat/securite-professionnelle/securite-des-locaux/videosurveillance',
  '/cat/securite/securite-locaux/securite-locaux-alarme': '/cat/securite-professionnelle/securite-des-locaux/alarmes',
  '/cat/securite/securite-personnes': '/cat/securite-professionnelle/securite-des-personnes',
  '/cat/securite/securite-personnes/securite-personnes-defibrillateur':
    '/cat/securite-professionnelle/securite-des-personnes/defibrillateurs',
  '/cat/impression/impression-imprimante-specifique/impression-imprimante-etiquette':
    '/cat/impression/imprimantes-d-etiquettes',
  '/cat/impression/impression-imprimante-laser': '/cat/impression/imprimantes-laser',
  '/cat/informatique/informatique-ordinateur?state=state_used': '/cat/reconditionne/ordinateurs-reconditionnes',
  '/cat/informatique/informatique-ordinateur?state=state_used&brand=Apple': '/cat/reconditionne/macbook-reconditionnes',
  '/cat/informatique/informatique-ordinateur?state=state_used&brand=HP&brand=Dell':
    '/cat/reconditionne/pc-portables-reconditionnes',
  '/cat/telephonie-multimedia/telephonie-multimedia-mobile-tablette/telephonie-multimedia-mobile-tablette-smartphone?state=state_used':
    '/cat/reconditionne/smartphones-reconditionnes',
  /* eslint-enable @typescript-eslint/naming-convention*/
};

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    return;
  }

  const redirectTo = categoriesToRedirect[to.fullPath] ?? categoriesToRedirect[to.path];

  if (redirectTo) {
    return navigateTo(redirectTo, {
      redirectCode: 301,
    });
  }
});
