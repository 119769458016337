<i18n lang="json" locale="fr-FR">
{
  "shipping": "Loué et expédié par {0}"
}
</i18n>

<script lang="ts" setup>
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  supplierName: string;
}>();

useI18n();

const to = computed(() => {
  return helpers.route({
    name: 'p-search',
    query: { 'supplier.name': props.supplierName },
  });
});
</script>

<template>
  <div class="product-shipped-by">
    <i18n-t keypath="shipping">
      <NuxtLink class="product-shipped-by__link" :to>{{ supplierName }}</NuxtLink>
    </i18n-t>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.product-shipped-by {
  &__link {
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
