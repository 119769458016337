<script setup lang="ts">
import type { HtmlTagName } from '@onliz/design-system-vue';
import { marked } from 'marked';
import { computed, h } from 'vue';

const props = withDefaults(
  defineProps<{
    content: string;
    inline?: boolean;
    tag?: HtmlTagName;
  }>(),
  {
    tag: 'div',
  },
);

const htmlContent = computed<string>(() => {
  if (props.content.length > 0) {
    return props.inline ? (marked.parseInline(props.content) as string) : (marked.parse(props.content) as string);
  }

  return '';
});

const Render = () => h(props.tag, { innerHTML: htmlContent.value });
</script>

<template>
  <Render />
</template>
