<script setup lang="ts">
import { ImageSizeMap } from '#imports';
import type { CategoryCardProps } from '~/components/CategoryCard.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockCategoryListFragment, FieldImageFragment } from '~/types/graphql';

const props = defineProps<{
  id: string;
  data: BlockCategoryListFragment;
}>();

type CmsCategoryListItem = {
  fieldImage?: FieldImageFragment;
} & CategoryCardProps;

type CmsCategoryList = NonNullable<CmsCategoryListItem[]>;

const categories = computed<CmsCategoryList>(() => {
  if (props.data.categories?.data.length) {
    return props.data.categories.data.reduce<CmsCategoryList>((acc, category) => {
      if (isNotEmpty(category.attributes)) {
        acc.push({
          title: category.attributes.title,
          slug: category.attributes.slug,
          startFromPrice: category.attributes.startFromPrice,
          fieldImage: category.attributes.image ?? undefined,
        });
      }

      return acc;
    }, []);
  }

  return [];
});
</script>

<template>
  <LayoutSection class="cms-block-category-list" scheme="primary">
    <div class="cms-block-category-list__header">
      <CmsFieldTitle v-if="data.mainTitle" class="cms-block-category-list__title" :data="data.mainTitle" />

      <CmsFieldLink v-if="data.mainCta" :data="data.mainCta" class="cms-block-category-list__cta" variant="outlined" />
    </div>

    <SliderContainer
      v-if="categories.length > 0"
      :id
      class="cms-block-category-list__slider"
      overflow-on-mobile
      :slides="categories"
      slide-class-name="cms-block-category-list__slide"
    >
      <template #default="{ slide: category }">
        <CategoryCard class="cms-block-category-list__card" v-bind="category">
          <template v-if="category.fieldImage" #image>
            <CmsFieldImage
              :data="category.fieldImage"
              :width="ImageSizeMap.categoryCard.width"
              :height="ImageSizeMap.categoryCard.height"
              fit="contain"
            />
          </template>
        </CategoryCard>
      </template>
    </SliderContainer>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-category-list {
  &__card {
    height: 100%;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
    justify-content: space-between;
    margin: 0 0 var(--spacing--m);

    @media screen and (min-width: ods.$breakpoint-xs) {
      gap: var(--spacing--m);
      flex-direction: row;
    }
  }

  &__title {
    @include ods.h3;

    margin: 0;
  }
}
</style>
