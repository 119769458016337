<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps<{
  error: NuxtError;
}>();

const isPageNotFoundError = computed<boolean>(() => props.error?.statusCode === 404);
</script>

<template>
  <ErrorNotFound v-if="isPageNotFoundError" />

  <ErrorDefault v-else :error />
</template>
