<script setup lang="ts">
import type { OdsColorSchemeType } from '@onliz/design-system-vue';

import type { BlockTwoColumnsTextFragment } from '~/types/graphql';

const props = defineProps<{
  data: BlockTwoColumnsTextFragment;
}>();

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'dark';
});
</script>

<template>
  <LayoutSection>
    <SectionTwoColumnsText :scheme>
      <template v-if="data.title" #title>
        <CmsFieldTitle :data="data.title" />
      </template>

      <template v-if="data.left" #left>
        <MarkdownContent :content="data.left" />
      </template>

      <template v-if="data.right" #right>
        <MarkdownContent :content="data.right" />
      </template>

      <template v-if="data.image" #image>
        <CmsFieldImage lazy :data="data.image" :width="225" />
      </template>
    </SectionTwoColumnsText>
  </LayoutSection>
</template>
