import type { RouteLocationNormalized } from '#vue-router';

export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'uncomplete-registration',
    async (from: RouteLocationNormalized) => {
      const { isOrganizationUser } = useAuth();
      const {
        public: {
          auth: {
            route: { signUp: signUpRoute },
          },
        },
      } = useRuntimeConfig();

      if (!isOrganizationUser.value || from.path === signUpRoute) {
        return;
      }

      const { data: organization } = await useAuthenticatedOrganization();

      if (organization.value) {
        return;
      }

      return navigateTo(signUpRoute);
    },
    { global: true },
  );
});
