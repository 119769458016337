import { isCrcRole, isOperatorRole, isOrganizationRole, isSupplierRole } from '~/modules/auth/runtime/utils/role-utils';
import { type AuthTokenClaims, parseJwt, TOKEN_COOKIE_NAME } from '~/modules/auth/runtime/utils/Token';

export default function useAuth() {
  return useNuxtApp().$authUtils.getOrSetRequestAuthStorageEntry('useAuth', () => {
    const jwt = useCookie<string | undefined>(TOKEN_COOKIE_NAME);

    const isAuthenticated = computed<boolean>(() => !!jwt.value);

    const parsedJwt = computed<AuthTokenClaims | undefined>(() =>
      jwt.value ? parseJwt<AuthTokenClaims>(jwt.value) : undefined,
    );

    const userRole = computed<string | undefined>(() => parsedJwt.value?.extension_role);

    const isOrganizationUser = computed<boolean>(
      () => userRole.value !== undefined && isOrganizationRole(userRole.value),
    );

    const isSupplierUser = computed<boolean>(() => userRole.value !== undefined && isSupplierRole(userRole.value));

    const isCrcUser = computed<boolean>(() => userRole.value !== undefined && isCrcRole(userRole.value));

    const isOperatorUser = computed<boolean>(() => userRole.value !== undefined && isOperatorRole(userRole.value));

    const isSupplierOrCrcOrOperatorUser = computed<boolean>(
      () =>
        userRole.value !== undefined &&
        (isSupplierRole(userRole.value) || isCrcRole(userRole.value) || isOperatorRole(userRole.value)),
    );

    return {
      jwt,
      parsedJwt,
      userRole,
      isAuthenticated,
      isOrganizationUser,
      isSupplierUser,
      isCrcUser,
      isOperatorUser,
      isSupplierOrCrcOrOperatorUser,
    };
  });
}
