<script lang="ts" setup>
import type { FeaturedProductCardProps } from '~/components/FeaturedProductCard.vue';
import { getProductTag } from '~/lib/cache-utils';
import type { BlockFeaturedProductListFragment } from '~/types/graphql';
import { findProductById } from '~/utils/api/catalog/products';
import type { ProductId } from '~/utils/api/catalog/products/utils';

const props = defineProps<{
  data: BlockFeaturedProductListFragment;
}>();

const ids = computed<ProductId[]>(() => {
  return (
    props.data.products?.data.reduce<ProductId[]>((array, product) => {
      const productId = product.attributes?.product as ProductId;

      if (productId?.length > 0) {
        array.push(productId);
      }

      return array;
    }, []) ?? []
  );
});

useRouteCache((helper) => {
  helper.addTags(ids.value.map((productId) => getProductTag(productId)));
});

const { data: catalogProducts } = await useAsyncData(`findProductById:${ids.value.join('+')}`, () =>
  findProductById(...ids.value),
);

const products = computed<FeaturedProductCardProps[]>(() => {
  return (
    props.data.products?.data.reduce<FeaturedProductCardProps[]>((array, cmsProduct) => {
      const catalogProduct =
        catalogProducts.value?.find((catalogProduct) => catalogProduct.id === cmsProduct.attributes?.product) ??
        undefined;

      if (catalogProduct) {
        array.push({
          id: catalogProduct.id,
          brand: catalogProduct.brand,
          slug: catalogProduct.slug,
          price: catalogProduct.price,
          cmsImage: cmsProduct.attributes?.image ?? undefined,
          image: catalogProduct.image,
          deliveryTime: catalogProduct.deliveryTime,
          state: catalogProduct.state,
          imageRelativePath: catalogProduct.imageRelativePath,
          supplierName: catalogProduct.supplierName,
          name: cmsProduct.attributes?.title ?? catalogProduct.name,
          characteristics: cmsProduct?.attributes?.characteristics ?? undefined,
          hidden: catalogProduct.hidden,
          mainCategory: catalogProduct.mainCategory,
        });
      }

      return array;
    }, []) ?? []
  );
});
</script>

<template>
  <LayoutSection class="cms-block-featured-product-list">
    <SectionCardBoard>
      <template v-if="data.mainTitle" #title>
        <CmsFieldTitle :data="data.mainTitle" class="cms-block-featured-product-list__title" />
      </template>

      <template v-if="products?.length > 0" #default>
        <FeaturedProductList :products />
      </template>
    </SectionCardBoard>
  </LayoutSection>
</template>
