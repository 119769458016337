import revive_payload_client_X8X4RM3maW from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VvbWj1jDw9 from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ii6pnPMrkZ from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_lS1yMjINvu from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.13.0_vite@5.2.0_@types+node@22.9.0_sass@1.72.0_terser@5.36.0_4ldecmhnozqfnxol3g324krze4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_5TQED23jV4 from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ySrvLHVK6D from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/agent/_work/13/s/app/.nuxt/components.plugin.mjs";
import prefetch_client_16sAa7ztnZ from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KmAoHj6F97 from "/agent/_work/13/s/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.13.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_vnO3ezl36V from "/agent/_work/13/s/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@typ_tzckwp3req53uxi6gokxfqtwhi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_4D4skCsaCa from "/agent/_work/13/s/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.13.0_typescript@5.3.3_vue@3.4.21_typescript@5.3.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_5PWYBIg3tO from "/agent/_work/13/s/app/modules/didomi/runtime/plugin.ts";
import uncomplete_registration_global_5xJWB02MAC from "/agent/_work/13/s/app/modules/auth/runtime/middlewares/uncomplete-registration.global.ts";
import getOrSetRequestStorageEntry_1STey2oNZx from "/agent/_work/13/s/app/modules/auth/runtime/plugins/getOrSetRequestStorageEntry.ts";
import chunk_reload_client_hpEsvNIp0K from "/agent/_work/13/s/app/node_modules/.pnpm/nuxt@3.11.1_@azure+identity@4.4.1_@parcel+watcher@2.5.0_@types+node@22.9.0_@unocss+reset@0.64_vz6mwc2nwonc6m4z7ssslieteu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import authenticatedFetch_DCFoXWafdw from "/agent/_work/13/s/app/modules/auth/runtime/plugins/authenticatedFetch.ts";
import algoliaSearchKey_iuVd2gtkfp from "/agent/_work/13/s/app/plugins/algoliaSearchKey.ts";
import apollo_1ILFkzpYSr from "/agent/_work/13/s/app/plugins/apollo.ts";
import datadog_client_cfAAU11B0P from "/agent/_work/13/s/app/plugins/datadog.client.ts";
import i18n_VfGcjrvSkj from "/agent/_work/13/s/app/plugins/i18n.ts";
import ods_B694AYI5d4 from "/agent/_work/13/s/app/plugins/ods.ts";
export default [
  revive_payload_client_X8X4RM3maW,
  unhead_VvbWj1jDw9,
  router_ii6pnPMrkZ,
  _0_siteConfig_lS1yMjINvu,
  payload_client_5TQED23jV4,
  check_outdated_build_client_ySrvLHVK6D,
  components_plugin_KR1HBZs4kY,
  prefetch_client_16sAa7ztnZ,
  plugin_KmAoHj6F97,
  plugin_vnO3ezl36V,
  plugin_4D4skCsaCa,
  plugin_5PWYBIg3tO,
  uncomplete_registration_global_5xJWB02MAC,
  getOrSetRequestStorageEntry_1STey2oNZx,
  chunk_reload_client_hpEsvNIp0K,
  authenticatedFetch_DCFoXWafdw,
  algoliaSearchKey_iuVd2gtkfp,
  apollo_1ILFkzpYSr,
  datadog_client_cfAAU11B0P,
  i18n_VfGcjrvSkj,
  ods_B694AYI5d4
]