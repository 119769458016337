<i18n lang="json" locale="fr-FR">
{
  "startFrom": "À partir de",
  "durationUnit": "/mois",
  "upperPart": "{currency}{fraction} HT",
  "additional": "+"
}
</i18n>

<script setup lang="ts">
import { OdsSpinner } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { groupCurrencyParts } from '~/lib/currency-utils';

export type ProductRentSize = 'large' | 'medium' | 'small';

export type PricingRentProps = {
  block?: boolean;
  loading?: boolean;
  size?: ProductRentSize;
  value: number;
  originalValue?: number;
  additional?: boolean;
  startFrom?: boolean;
};

const props = withDefaults(defineProps<PricingRentProps>(), {
  size: 'medium',
  originalValue: undefined,
});

const { t } = useI18n();

const formattedOriginalValue = computed<string | undefined>(() => {
  if (props.originalValue !== undefined) {
    const { decimal, fraction, currency } = groupCurrencyParts(props.originalValue);

    return `${decimal}${currency}${fraction}`;
  }

  return;
});

const formattedValue = computed(() => groupCurrencyParts(props.value));
</script>

<template>
  <span
    class="pricing-rent"
    :class="[
      `pricing-rent--${size}`,
      {
        'pricing-rent--block': block,
        'pricing-rent--has-discount': originalValue !== undefined,
        'pricing-rent--loading': loading,
      },
    ]"
  >
    <span class="pricing-rent__layer">
      <span v-if="$slots.top || startFrom || formattedValue" class="pricing-rent__before">
        <slot name="top">
          <span v-if="startFrom" class="pricing-rent__start-from">{{ t('startFrom') }}</span>

          <span v-if="formattedOriginalValue" class="pricing-rent__initial-value">{{ formattedOriginalValue }}</span>
        </slot>
      </span>

      <span class="pricing-rent__body">
        <span class="pricing-rent__decimals">
          <!-- Keep formatting to prevent space between + sign and decimal part -->
          <template v-if="additional">{{ t('additional') }}</template
          >{{ formattedValue.decimal }}
        </span>

        <span class="pricing-rent__fraction">{{
          t('upperPart', {
            currency: formattedValue.currency,
            fraction: formattedValue.fraction,
          })
        }}</span>

        <span class="pricing-rent__duration-unit">{{ t('durationUnit') }}</span>
      </span>
    </span>

    <Transition name="pricing-rent__layer-loading-">
      <span v-if="loading" class="pricing-rent__layer pricing-rent__layer--loading pricing-rent__layer-loading">
        <OdsSpinner />
      </span>
    </Transition>
  </span>
</template>

<style lang="scss">
.pricing-rent {
  $block-selector: &;

  --pricing-rent--price-color: var(--scheme--color-emphasis);

  display: inline-grid;
  grid: 'layer';

  &--block {
    display: grid;
  }

  &,
  &--medium {
    --pricing-rent--decimals-font-size: 1.5rem;
    --pricing-rent--fraction-font-size: var(--extra-small--font-size);
    --pricing-rent--duration-unit-font-size: var(--extra-small--font-size);
  }

  &--large {
    --pricing-rent--decimals-font-size: 2rem;
    --pricing-rent--fraction-font-size: 1.2rem;
    --pricing-rent--duration-unit-font-size: var(--extra-small--font-size);
  }

  &--small {
    --pricing-rent--decimals-font-size: 1.125rem;
    --pricing-rent--fraction-font-size: 0.625rem;
    --pricing-rent--duration-unit-font-size: 0.625rem;
  }

  &--has-discount {
    --pricing-rent--price-color: var(--semantic--warning);
  }

  &__layer {
    grid-area: layer;

    #{$block-selector}--loading & {
      &:not(#{$block-selector}__layer--loading) {
        opacity: 0.25;
        transition: opacity var(--default--transition-duration);
      }
    }

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

  &__layer-loading {
    &--enter-active,
    &--leave-active {
      opacity: var(--default--transition-duration);
    }

    &--enter-from,
    &--leave-to {
      opacity: 0;
    }
  }

  &__before {
    grid-area: 1 / 1 / 2 / 3;
    white-space: nowrap;
    font-size: var(--extra-small--font-size);
    display: block;
  }

  &__body {
    display: grid;
    grid:
      'decimal fraction'
      'decimal duration-unit';
    line-height: 0.8;
    margin-left: auto;
    margin-right: auto;
  }

  &__decimals {
    grid-area: decimal;
    color: var(--pricing-rent--price-color);
    font-weight: var(--strong--font-weight);
    place-self: center end;
    font-size: var(--pricing-rent--decimals-font-size);
  }

  &__decimals,
  &__fraction {
    color: var(--pricing-rent--price-color);
    font-weight: var(--strong--font-weight);
  }

  &__fraction {
    grid-area: fraction;
    font-size: var(--pricing-rent--fraction-font-size);
    white-space: nowrap;
    line-height: 0.8;
  }

  &__duration-unit {
    grid-area: duration-unit;
    place-self: end;
    font-size: var(--pricing-rent--duration-unit-font-size);
    white-space: nowrap;
  }

  &__start-from {
    color: var(--default--color-disabled);
  }

  &__initial-value {
    text-decoration: line-through;

    #{$block-selector}__start-from + & {
      margin-left: 1ch;
    }
  }
}
</style>
