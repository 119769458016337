<script lang="ts" setup>
import type { HtmlTagName } from '@onliz/design-system-vue';
import { OdsCard, OdsClickableElement } from '@onliz/design-system-vue';

export type PostCardProps = {
  title: string;
  to: string;
  introduction: string;
  target?: LinkTarget;
  titleTag?: HtmlTagName;
  image?: string;
};

const props = withDefaults(defineProps<PostCardProps>(), {
  target: '_blank',
  titleTag: 'h3',
  image: undefined,
});

const postCardImageSize = ImageSizeMap.postCardImage;

const { trackEvent } = useTracking();
const onPostClick = () => trackEvent('click_selected_article', props.title);
</script>

<template>
  <OdsCard with-shadow tag="article" class="post-card">
    <div v-if="$slots.image || image" class="post-card__image">
      <slot name="image">
        <ImageElement
          v-if="image"
          class="post-card__image"
          :src="image"
          :width="postCardImageSize.width"
          :height="postCardImageSize.height"
        />
      </slot>
    </div>

    <div class="post-card__excerpt">
      <slot name="default">
        <OdsClickableElement class="post-card__link" :to :target @click="onPostClick">
          <Component :is="titleTag" class="post-card__title">{{ title }}</Component>
        </OdsClickableElement>

        <p class="post-card__introduction">{{ introduction }}</p>
      </slot>
    </div>
  </OdsCard>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/mixins-utils' as mixins;

.post-card {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: row;
  gap: var(--spacing--s);

  @include mixins.card-hover-transition;

  @media screen and (min-width: ods.$breakpoint-l) {
    flex-direction: column;
    gap: var(--spacing--m);
  }

  &__link {
    @include mixins.clickable-area;
  }

  &__title {
    @include ods.h6;
  }

  &__introduction {
    @include mixins.line-clamp(2);
  }

  &__image {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: var(--radius--m);

    @media screen and (min-width: ods.$breakpoint-l) {
      width: 100%;
      height: auto;
    }
  }
}
</style>
