<i18n lang="json" locale="fr-FR">
{
  "title": "Ils se sont équipés sur @:{'global.brandName'}"
}
</i18n>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import type { ReviewCardProps } from '~/components/ReviewCard.vue';
import { isNotEmpty } from '~/lib/strapi-utils';
import type { BlockReviewListFragment, Enum_Review_Stars } from '~/types/graphql';

const props = defineProps<{
  id: string;
  data: BlockReviewListFragment;
}>();

type CmsBlockReviewList = NonNullable<ReviewCardProps[]>;

const reviews = computed<CmsBlockReviewList>(() => {
  const CmsRatingValuesMap: Record<Enum_Review_Stars, number> = {
    One: 1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5,
  };

  if (props.data.reviews?.data.length) {
    return props.data.reviews.data.reduce<CmsBlockReviewList>((acc, review) => {
      if (isNotEmpty(review.attributes)) {
        acc.push({
          quote: review.attributes.text,
          name: review.attributes.name ?? undefined,
          position: review.attributes.position ?? undefined,
          rating: review.attributes.stars ? CmsRatingValuesMap[review.attributes.stars] : undefined,
        });
      }

      return acc;
    }, []);
  }

  return [];
});

const { t } = useI18n();

const breakpoints = {
  '480': {
    slidesPerView: 2,
    spaceBetween: 20,
  },
};
</script>

<template>
  <LayoutSection class="cms-block-review-list">
    <LayoutGrid layout="1of3-2of3">
      <div class="cms-block-review-list__aside">
        <div class="cms-block-review-list__title">
          <CmsFieldTitle v-if="data.mainTitle" :data="data.mainTitle" />

          <span v-else>{{ t('title') }}</span>
        </div>
      </div>

      <div class="cms-block-review-list__slider">
        <SliderContainer v-if="reviews.length > 0" :id :slides="reviews" :breakpoints>
          <template #default="{ slide: review }">
            <ReviewCard class="cms-block-review-list__card" v-bind="review" />
          </template>
        </SliderContainer>
      </div>
    </LayoutGrid>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-review-list {
  &__aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include ods.h2;
  }

  &__card {
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
