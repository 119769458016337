export const formatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

export function formatCurrency(value: number): string {
  return formatter.format(value);
}

export function getCurrencyParts(value: number): Intl.NumberFormatPart[] {
  return formatter.formatToParts(value);
}

export function groupCurrencyParts(input: number): {
  decimal: string;
  fraction: string;
  currency: string;
} {
  let decimal = '';
  let fraction = '';
  let currency = '';

  for (const { type, value } of getCurrencyParts(input)) {
    switch (type) {
      case 'integer':
      case 'group': {
        decimal += value;

        break;
      }
      case 'currency': {
        currency = value;

        break;
      }
      case 'fraction': {
        fraction = value;

        break;
      }
      // No default
    }
  }

  return { decimal, fraction, currency };
}
