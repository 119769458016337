<script lang="ts" setup>
import { type OdsColorSchemeType } from '@onliz/design-system-vue';

import type { FieldActionBannerFragment } from '~/types/graphql';

const props = defineProps<{
  data: FieldActionBannerFragment;
}>();

const scheme = computed<OdsColorSchemeType>(() => {
  return (props.data.scheme as OdsColorSchemeType) ?? 'dark';
});
</script>

<template>
  <ActionBanner :scheme :title="data.title ?? undefined" :description="data.description ?? undefined">
    <template v-if="data.link" #cta>
      <CmsFieldLink :data="data.link" variant="default" full-width />
    </template>
  </ActionBanner>
</template>
