export const imageSizeList = [
  'default',
  'logo',
  'metaImage',
  'cardImage',
  'postCardImage',
  'categoryCard',
  'productThumbnail',
  'productXsThumbnail',
  'productCard',
  'productGalleryViewBox',
  'productGalleryLightbox',
  'featuredProductCard',
  'squaredHero',
  'categoryHero',
  'optionSelectCard',
  'basketProductThumbnail',
  'basketProductThumbnailSummary',
  'landingHero',
] as const;
export type ImageSizeName = (typeof imageSizeList)[number];

export const ImageSizeMap: Record<ImageSizeName, { width: number; height: number }> = {
  default: {
    width: 670,
    height: 420,
  },
  logo: {
    width: 170,
    height: 55,
  },
  metaImage: {
    width: 1200,
    height: 600,
  },
  cardImage: {
    width: 200,
    height: 200,
  },
  postCardImage: {
    width: 285,
    height: 185,
  },
  categoryCard: {
    width: 102,
    height: 102,
  },
  productCard: {
    width: 170,
    height: 170,
  },
  productThumbnail: {
    width: 60,
    height: 60,
  },
  productGalleryViewBox: {
    width: 357,
    height: 357,
  },
  productGalleryLightbox: {
    width: 1920,
    height: 1920,
  },
  productXsThumbnail: {
    width: 40,
    height: 40,
  },
  featuredProductCard: {
    width: 260,
    height: 260,
  },
  squaredHero: {
    width: 555,
    height: 555,
  },
  categoryHero: {
    width: 670,
    height: 290,
  },
  optionSelectCard: {
    width: 43,
    height: 43,
  },
  basketProductThumbnail: {
    width: 130,
    height: 130,
  },
  basketProductThumbnailSummary: {
    width: 65,
    height: 65,
  },
  landingHero: {
    width: 670,
    height: 339,
  },
} as const;
