// Constants
/**
 * - 0 = Sunday
 * - 1 = Monday
 * - 2 = Tuesday
 * - 3 = Wednesday
 * - 4 = Thursday
 * - 5 = Friday
 * - 6 = Saturday
 */
type WeekDayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const customerServiceOpeningDays: WeekDayNumber[] = [1, 2, 3, 4, 5] as const;

export const customerServiceOpeningTime = '09:00' as const;

export const customerServiceClosingTime = '18:00' as const;

// Functions
function formatTime(time: string) {
  const [hour, minute] = time.split(':');

  // If minute is "00", return only the hour
  if (minute === '00') {
    return Number.parseInt(hour, 10).toString(); // Remove leading zero from hour
  }

  // Otherwise, return hour:minute with leading zero removed from hour
  return `${Number.parseInt(hour, 10).toString()}:${minute}`;
}

export function isCustomerServiceOpen(time: Date): boolean {
  const currentDay = time.getDay();

  if (!customerServiceOpeningDays.includes(currentDay as WeekDayNumber)) {
    return false;
  }

  // Extract current hour and minute
  const currentHour = time.getHours();
  const currentMinute = time.getMinutes();

  // Convert opening and closing times to hours and minutes
  const [openingHour, openingMinute] = customerServiceOpeningTime.split(':').map(Number);
  const [closingHour, closingMinute] = customerServiceClosingTime.split(':').map(Number);

  // Create Date objects for comparison
  const currentTime = new Date();

  currentTime.setHours(currentHour, currentMinute);

  const openingDate = new Date();

  openingDate.setHours(openingHour, openingMinute);

  const closingDate = new Date();

  closingDate.setHours(closingHour, closingMinute);

  // Check if the current time is within the opening hours
  return currentTime >= openingDate && currentTime < closingDate;
}

// Computed values
export const customerServiceOpeningDayCount = customerServiceOpeningDays.length;

export const customerServiceOpeningTimeFormatted = formatTime(customerServiceOpeningTime);

export const customerServiceClosingTimeFormatted = formatTime(customerServiceClosingTime);

export const customerServiceSchedules = {
  openingDays: customerServiceOpeningDayCount,
  openingTime: customerServiceOpeningTimeFormatted,
  closingTime: customerServiceClosingTimeFormatted,
} as const;
