<script lang="ts" setup>
import { OdsColorScheme, type OdsColorSchemeType } from '@onliz/design-system-vue';

withDefaults(
  defineProps<{
    title?: string;
    scheme?: OdsColorSchemeType;
  }>(),
  {
    title: undefined,
    scheme: 'default',
  },
);

const stepsNumber = 3;
</script>

<template>
  <OdsColorScheme :scheme class="section-steps-card">
    <div class="section-steps-card__wrapper">
      <div v-if="title || $slots.title" class="section-steps-card__aside">
        <div class="section-steps-card__title">
          <slot name="title">{{ title }}</slot>
        </div>
      </div>

      <ol class="section-steps-card__list">
        <template v-for="i in stepsNumber" :key="i">
          <li v-if="$slots[`step-${i}`]" class="section-steps-card__step">
            <slot :name="`step-${i}`"></slot>
          </li>
        </template>
      </ol>

      <div v-if="$slots.cta" class="section-steps-card__cta">
        <slot name="cta">{{ title }}</slot>
      </div>
    </div>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.section-steps-card {
  container: section-steps-card-container / inline-size;
  border-radius: var(--radius--l);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing--m);
    padding: var(--spacing--m);

    @container section-steps-card-container (width >= #{ods.$breakpoint-l}) {
      flex-direction: row;
      gap: var(--spacing--m);
      padding: var(--spacing--m) var(--spacing--l);
    }
  }

  &__aside {
    display: flex;
    align-items: center;

    @container section-steps-card-container (width >= #{ods.$breakpoint-l}) {
      min-width: 180px;
      max-width: 240px;
    }
  }

  &__title {
    @include ods.h4;

    text-align: center;
    margin: 0;

    @container section-steps-card-container (width >= #{ods.$breakpoint-l}) {
      text-align: left;
    }
  }

  &__list {
    display: flex;
    width: 100%;
    flex: 1;
    gap: var(--spacing--m);
    flex-direction: column;
    margin: 0;
    padding: 0;

    @container section-steps-card-container (width >= 500px) {
      flex-direction: row;
    }
  }

  &__step {
    flex: 1;
  }
}
</style>
