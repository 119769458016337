import { SessionClientIdHeaderKey } from '@locam/common-services';
import type { RoutesNamesList } from '@typed-router';

import { useGtm } from '#imports';
import type { RentalConfiguration } from '~/utils/api/catalog/rent';
import { FIRST_TOUCH_POINT_STORAGE_KEY, getOrSetTouchPoint, SESSION_TOUCH_POINT_STORAGE_KEY } from '~/utils/GtmUtils';

export type RouteCategoryMap = {
  [key in RoutesNamesList]:
    | 'homepage'
    | 'page'
    | 'contact'
    | 'cart'
    | 'checkout'
    | 'blog'
    | 'account'
    | 'landing_page'
    | 'purchase'
    | 'search_results'
    | 'product_page'
    | 'product_list'
    | undefined;
};

export const routeCategoryMap: RouteCategoryMap = {
  /* eslint-disable @typescript-eslint/naming-convention */
  slug: 'page',
  index: 'homepage',
  basket: 'cart',
  billing: 'checkout',
  blog: 'blog',
  customer: 'account',
  login: undefined,
  signup: undefined,
  contact: 'contact',
  'lp-slug': 'landing_page',
  'cat-slug': 'product_list',
  'nos-produits': undefined,
  'billing-index-information': 'checkout',
  'billing-index-signatory': 'checkout',
  'customer-index-addresses': 'account',
  'customer-index-favorites': 'account',
  'customer-index-account-organization': 'account',
  'customer-index-account-profile': 'account',
  'customer-index-account-users': 'account',
  'customer-index-discussion-archived': 'account',
  'customer-index-discussion-list': 'account',
  'customer-index-orders-active': 'account',
  'customer-index-orders-archived': 'account',
  'customer-index-orders-processing': 'account',
  'customer-index-orders-signing': 'account',
  'billing-loading-iframe': 'checkout',
  'billing-signing': 'checkout',
  'content-informations': 'blog',
  'order-confirmation': 'purchase',
  'product-search': 'search_results',
  'blog-article-article': 'blog',
  'blog-cat-cat': 'blog',
  'content-faq-slug': 'blog',
  'content-vendeurs-slug': 'blog',
  'customer-discussion-id': 'account',
  'order-item-id': 'purchase',
  'content-slug': 'blog',
  'product-slug-sku-productId': 'product_page',
  'p-search': 'search_results',
  'content-qsm': 'blog',
  /* eslint-enable @typescript-eslint/naming-convention */
};

type GTMEvent = Parameters<Exclude<ReturnType<typeof useGtm>, undefined>['trackEvent']>[0];

function gtmTrackEvent(getEvent: () => GTMEvent) {
  if (import.meta.client) {
    const gtm = useGtm();

    if (gtm) {
      const event = getEvent();

      gtm.trackEvent(event);
    }
  }
}

export default function useTracking() {
  return {
    trackPageView: (pageTitle: string, { errorType }: { errorType?: number } = {}) => {
      gtmTrackEvent(() => {
        const route = useRoute();
        const oClidCookie = useCookie(SessionClientIdHeaderKey);
        const url = useRequestURL();
        const { userRole } = useAuth();

        const {
          public: { appEnv, buildBuildNumber },
        } = useRuntimeConfig();

        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'page_view',
          routeName: route.name,
          environment_name: appEnv,
          page_category: errorType?.toString() ?? routeCategoryMap[route.name] ?? '404',
          page_url: route.fullPath,
          page_title: pageTitle,
          session_context: userRole.value ?? 'ANONYMOUS',
          first_touchpoint: getOrSetTouchPoint(window.localStorage, FIRST_TOUCH_POINT_STORAGE_KEY),
          session_touchpoint: getOrSetTouchPoint(window.sessionStorage, SESSION_TOUCH_POINT_STORAGE_KEY),
          version_app: buildBuildNumber,
          website_platform: url.hostname,
          session_client_id: oClidCookie.value,
          error_type: errorType,
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackEvent: (event: string, eventElement?: string) => {
      gtmTrackEvent(() => {
        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event,
          event_elem: eventElement,
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackSortEvent: (sortName: string) => {
      gtmTrackEvent(() => {
        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'sort',
          sort_name: sortName,
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackFilterEvent: (filterName: string, filterValue: string) => {
      gtmTrackEvent(() => {
        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'filter',
          filter_name: filterName,
          filter_value: filterValue,
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackSearchEvent: (
      event: 'search' | 'view_search_results' | 'click_search_results',
      query: string,
      resultsCount: number,
      position?: number,
      eventElement?: string,
    ) => {
      gtmTrackEvent(() => {
        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event,
          search_terms: query,
          search_results_number: resultsCount,
          search_results_position: position,
          event_elem: eventElement,
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackViewItem: (itemId: string, itemName: string, itemCategory?: string) => {
      gtmTrackEvent(() => {
        return {
          // eslint-disable-next-line unicorn/no-null
          ecommerce: null,
        };
      });
      gtmTrackEvent(() => {
        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'view_item',
          ecommerce: {
            items: [
              {
                item_id: itemId,
                item_name: itemName,
                item_category: itemCategory,
                quantity: 1,
              },
            ],
          },
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackProductConfiguration: (
      event: 'config_item' | 'add_to_cart',
      configuration: RentalConfiguration,
      itemName: string,
      itemCategory: string,
      rentalAmount: number,
    ) => {
      gtmTrackEvent(() => {
        return {
          // eslint-disable-next-line unicorn/no-null
          ecommerce: null,
        };
      });

      gtmTrackEvent(() => {
        function getConsumablesIds() {
          return configuration.consumables.length > 0
            ? configuration.consumables.map((consumable) => consumable.consumableId).join('/')
            : 'none';
        }

        return {
          /* eslint-disable @typescript-eslint/naming-convention */
          event,
          event_products_configuration: `sku=${configuration.productId},s=${configuration.service ?? 'none'},d=${configuration.rentalDurationInMonths.toLocaleString()},options=${configuration.options?.join('/') ?? 'none'},consumables=${getConsumablesIds()}`,
          ecommerce: {
            items: [
              {
                item_id: configuration.productId,
                item_name: itemName,
                item_category: itemCategory,
                price: rentalAmount,
                quantity: configuration.quantity,
                rental_period: configuration.rentalDurationInMonths,
                rental_price: rentalAmount * configuration.quantity * configuration.rentalDurationInMonths,
              },
            ],
          },
          /* eslint-enable @typescript-eslint/naming-convention */
        };
      });
    },

    trackContactForm: (objectId: string, objectTranslation: string) => {
      gtmTrackEvent(() => {
        return {
          event: 'submit_callback_response',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_elem: `${objectId}|${objectTranslation}`,
        };
      });
    },
  };
}
