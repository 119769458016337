import type { LocationQuery } from 'vue-router';

import { PublicationState } from '~/types/graphql';

export class PreviewError extends Error {}

export class EmptyPreviewTokenError extends PreviewError {}

export const PREVIEW_PARAM_NAME = 'preview';

export function isPreviewMode(query: LocationQuery): boolean {
  const { [PREVIEW_PARAM_NAME]: preview } = query;

  const { cmsPreviewToken } = useRuntimeConfig();

  if (import.meta.server && !cmsPreviewToken) {
    throw new EmptyPreviewTokenError(`Preview token is empty`);
  }

  return preview === cmsPreviewToken;
}

export default function useCmsPreviewMode() {
  const { query } = useRoute();

  const { enabled } = usePreviewMode({
    shouldEnable: () => {
      return process.server ? isPreviewMode(query) : false;
    },
  });

  const publicationState = computed<PublicationState>(() =>
    enabled.value ? PublicationState.Preview : PublicationState.Live,
  );

  return {
    enabled,
    publicationState,
  };
}
