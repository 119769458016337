import { FetchError } from 'ofetch';

import { ApiOrganizationError, getOrganizationBaseUrl } from '~/utils/api/organization';
import {
  mapUserOrganizationApiToUserOrganization,
  type Organization,
  type OrganizationApi,
} from '~/utils/api/organization/user-organization/utils';

export class ApiOrganizationUserOrganizationError extends ApiOrganizationError {}

export class ApiOrganizationUserOrganizationNotFoundError extends ApiOrganizationUserOrganizationError {}

export async function getUserOrganizationFromToken(jwtToken: string): Promise<Organization> {
  if (!jwtToken) {
    throw new ApiOrganizationUserOrganizationError('Invalid token');
  }

  try {
    const userOrganization = await $fetch<OrganizationApi>('/user/organization', {
      baseURL: getOrganizationBaseUrl(),
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return mapUserOrganizationApiToUserOrganization(userOrganization);
  } catch (error) {
    if (error instanceof FetchError && error.status === 404) {
      throw new ApiOrganizationUserOrganizationNotFoundError('User organization not found', { cause: error });
    }

    throw new ApiOrganizationUserOrganizationError('Failed to get user organization', { cause: error });
  }
}
