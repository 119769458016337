import { flattenSearchParameter, getLegacyAnSession } from '~/utils/UrlUtils';

export const ANONYMOUS_SESSION_COOKIE_NAME = 'ansession';
export const ANONYMOUS_SESSION_QUERY_PARAM_NAME = ANONYMOUS_SESSION_COOKIE_NAME;

export const useAnonymousSessionId = () => {
  return computed<string | undefined>(() => {
    const { query } = useRoute();

    return (
      flattenSearchParameter(query[ANONYMOUS_SESSION_QUERY_PARAM_NAME]) ??
      useCookie(ANONYMOUS_SESSION_COOKIE_NAME).value ??
      getLegacyAnSession(query) ??
      undefined
    );
  });
};
