<i18n lang="json" locale="fr-FR">
{
  "title": "Contactez-nous !",
  "description": "Nos conseillers sont disponibles pour répondre à toutes vos questions sur le leasing et vous guider dans le choix de vos équipements professionnels.",
  "cta": {
    "title": "Ou, laissez-nous faire le premier pas",
    "label": "Être rappelé"
  }
}
</i18n>

<script setup lang="ts">
import { OdsButton, OdsCard, OdsColorScheme, OdsIcon } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

import type { BlockContactUsFragment } from '~/types/graphql';

defineProps<{
  data: BlockContactUsFragment;
}>();

const { t } = useI18n();

const to = helpers.route({ name: 'contact' });
</script>

<template>
  <LayoutSection class="cms-block-contact-us" :class="{ 'cms-block-contact-us--with-cta': data.withCTA }">
    <OdsColorScheme scheme="secondary" class="cms-block-contact-us__card" highlighted>
      <div class="cms-block-contact-us__image">
        <CmsFieldImage
          v-if="data.image?.data?.attributes?.hash"
          class="cms-block-contact-us__image-el"
          :data="data.image"
          :width="390"
          :height="250"
          rounded
        />

        <img
          v-else
          src="~/assets/images/block-contact-us.svg?url"
          class="cms-block-contact-us__illustration"
          width="156"
          height="123"
          alt=""
        />
      </div>

      <div class="cms-block-contact-us__content">
        <div class="cms-block-contact-us__text">
          <CmsFieldTitle v-if="data.title" class="cms-block-contact-us__title" :data="data.title" />

          <span v-else class="cms-block-contact-us__title">{{ t('title') }}</span>

          <MarkdownContent
            v-if="data.description"
            class="cms-block-contact-us__description"
            :content="data.description"
          />

          <p v-else class="cms-block-contact-us__description">{{ t('description') }}</p>
        </div>
      </div>

      <OdsCard class="cms-block-contact-us__aside">
        <div class="cms-block-contact-us__aside-wrapper">
          <SavPhoneNumber class="cms-block-contact-us__number" show-info large />

          <div v-if="data.withCTA" class="cms-block-contact-us__cta">
            <div class="cms-block-contact-us__cta-title">{{ data.ctaTitle ?? t('cta.title') }}</div>

            <OdsButton :to :label="data.ctaLabel ?? t('cta.label')">
              <template #append>
                <OdsIcon name="arrow-forward" />
              </template>
            </OdsButton>
          </div>
        </div>
      </OdsCard>
    </OdsColorScheme>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.cms-block-contact-us {
  $blockSelector: &;
  $cms-block-contact-us-breakpoint: 500px;

  &__card {
    display: grid;
    gap: var(--spacing--m);
    padding: var(--spacing--m);
    border-radius: var(--radius--l);
    grid-template:
      'image' auto
      'content' auto
      'aside' auto
      / 1fr;

    @media screen and (min-width: ods.$breakpoint-xs) {
      gap: var(--spacing--s) var(--spacing--l);
      padding: var(--spacing--l);
      grid-template:
        'image content' auto
        'aside aside' auto
        / auto 1fr;
    }

    @media screen and (min-width: ods.$breakpoint-l) {
      grid-template:
        'image content aside' auto
        / auto 1fr 1fr;
    }

    #{$blockSelector}--with-cta & {
      grid-template:
        'image' auto
        'content' auto
        'aside' auto
        / 1fr;

      @media screen and (min-width: ods.$breakpoint-l) {
        grid-template:
          'image content' auto
          'image aside' auto
          / auto 1fr;
      }
    }
  }

  &__content {
    grid-area: content;
    display: flex;
    gap: var(--spacing--m);
    flex-direction: column;

    @media screen and (min-width: ods.$breakpoint-xs) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__image {
    grid-area: image;
    width: ods.px-to-em(150px);

    #{$blockSelector}--with-cta & {
      width: 100%;

      @media screen and (min-width: ods.$breakpoint-l) {
        max-width: ods.px-to-em(390px);
      }
    }

    &-el {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__illustration {
    width: ods.px-to-em(150px);
  }

  &__text {
    max-width: ods.px-to-em(670px);
  }

  &__description {
    p:last-child {
      margin: 0;
    }
  }

  &__title {
    @include ods.h4;
  }

  &__aside {
    grid-area: aside;
    container: cms-block-contact-us-aside-container / inline-size;
  }

  &__aside-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    #{$blockSelector}--with-cta & {
      gap: var(--spacing--s);
      align-items: center;

      @container cms-block-contact-us-aside-container (width > #{$cms-block-contact-us-breakpoint}) {
        min-width: ods.px-to-em(400px);
        flex-direction: row;
        align-items: center;
        gap: var(--spacing--m);
      }
    }
  }

  &__number {
    flex: 1;
  }

  &__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-top: solid var(--ods-card--border-width) var(--greyscale--black-light);
    padding: var(--spacing--s);
    width: 100%;

    @container cms-block-contact-us-aside-container (width > #{$cms-block-contact-us-breakpoint}) {
      padding: 0 var(--spacing--m);
      border-top: none;
      border-left: solid var(--ods-card--border-width) var(--greyscale--black-light);
    }
  }

  &__cta-title {
    @include ods.h5;

    max-width: 250px;
    text-align: center;
  }
}
</style>
